import React, { useContext } from "react";
import service from "../../utils/service";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "yup-phone";
import "./NewUser.css";
import { Mongo } from "../../auth/mongo-provider";
import Select from "react-select";

export default function NewUser({ setOnBoardingStep1, setOnBoardingStep2 }) {
  const { userDB } = useContext(Mongo);

  const timeZoneOptions = [
    { label: "US Eastern-(EST UTC-5)", value: 5 },
    { label: "US Central-(CST UTC-6)", value: 6 },
    { label: "US Mountain-(MST UTC-7)", value: 7 },
    { label: "US Pacific-(PST UTC-8)", value: 8 },
    { label: "UK Longon-(GMT UTC)", value: 0 },
  ];

  // const notificationOptions = [
  //   { label: "5:00 am", value: 5 },
  //   { label: "6:00 am", value: 6 },
  //   { label: "7:00 am", value: 7 },
  //   { label: "8:00 am", value: 8 },
  //   { label: "9:00 am", value: 9 },
  //   { label: "10:00 am", value: 10 },
  //   { label: "11:00 am", value: 11 },
  //   { label: "12:00 pm", value: 12 },
  //   { label: "1:00 pm", value: 13 },
  //   { label: "2:00 pm", value: 14 },
  //   { label: "3:00 pm", value: 15 },
  //   { label: "4:00 pm", value: 16 },
  //   { label: "5:00 pm", value: 17 },
  //   { label: "6:00 pm", value: 18 },
  //   { label: "7:00 pm", value: 19 },
  //   { label: "8:00 pm", value: 20 },
  //   { label: "9:00 pm", value: 21 },
  //   { label: "10:00 pm", value: 22 },
  //   { label: "11:00 pm", value: 23 },
  //   { label: "12:00 am", value: 24 },
  //   { label: "1:00 am", value: 1},
  //   { label: "2:00 am", value: 2},
  //   { label: "3:00 am", value: 3},
  //   { label: "4:00 am", value: 4},
  // ];

  const customStyles = {
    menu: () => ({
      backgroundColor: "#ffffff",
      border: "1px solid #dbdbdb91",
      height: "46",
      // color: "#373737af",
      fontFamily: "Proxima Nova",
      fontSize: "18px",
    }),
  };

  return (
    <>
      <div className={"onboarding-form-main-container"}>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            phone: "",
            city: "",
            state: "",
            timeZone: 5,
            timeOfNotification: 7,
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().required("Required"),
            lastName: Yup.string().required("Required"),
            phone: Yup.string().phone("US").required("Required"),
            city: Yup.string().required("Required"),
            state: Yup.string().required("Required"),
            timeZone: Yup.number().required("Required"),
            // timeOfNotification: Yup.number().required("Required"),
          })}
          onSubmit={async (values, { resetForm }) => {
            // service call to update user
            await service.updateUserDB(values, userDB._id).then((response) => {
              setOnBoardingStep1(false);
            });
            resetForm();
            setOnBoardingStep2(true);
          }}
        >
          {({ errors, touched, setFieldValue }) => (
            <div className={"onboarding-team-form-container"}>
              <Form>
                <div className={"onboarding-form-input-field"}>
                  <div className={"onboarding-field-header-container"}>
                    <h2>First Name</h2>
                    {errors.firstName && touched.firstName && (
                      <h3 className={"onboarding-form-error-message"}>
                        * {errors.firstName}
                      </h3>
                    )}
                  </div>
                  <Field
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                    className={"form-input-field"}
                  />
                </div>

                <div className={"onboarding-form-input-field"}>
                  <div className={"onboarding-field-header-container"}>
                    <h2>Last Name</h2>
                    {errors.lastName && touched.lastName && (
                      <h3 className={"onboarding-form-error-message"}>
                        * {errors.lastName}
                      </h3>
                    )}
                  </div>
                  <Field
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                    className={"form-input-field"}
                  />
                </div>

                <div className={"onboarding-form-input-field"}>
                  <div className={"onboarding-field-header-container"}>
                    <h2>Phone</h2>
                    {errors.phone && touched.phone && (
                      <h3 className={"onboarding-form-error-message"}>
                        * {errors.phone}
                      </h3>
                    )}
                  </div>
                  <Field
                    name="phone"
                    type="text"
                    placeholder="Phone"
                    className={"form-input-field"}
                  />
                </div>

                <div className={"onboarding-form-input-field"}>
                  <div className={"onboarding-field-header-container"}>
                    <h2>City</h2>
                    {errors.city && touched.city && (
                      <h3 className={"onboarding-form-error-message"}>
                        * {errors.city}
                      </h3>
                    )}
                  </div>
                  <Field
                    name="city"
                    type="text"
                    placeholder="City"
                    className={"form-input-field"}
                  />
                </div>

                <div className={"onboarding-form-input-field"}>
                  <div className={"onboarding-field-header-container"}>
                    <h2>State</h2>
                    {errors.state && touched.state && (
                      <h3 className={"onboarding-form-error-message"}>
                        * {errors.state}
                      </h3>
                    )}
                  </div>
                  <Field
                    name="state"
                    type="text"
                    placeholder="State"
                    className={"form-input-field"}
                  />
                </div>
                <div className={"onboarding-form-input-field"}>
                  <div className={"onboarding-field-header-container"}>
                    <h2>Select Time Zone</h2>
                    {errors.timeZone && touched.timeZone && (
                      <h3 className={"onboarding-form-error-message"}>
                        * {errors.timeZone}
                      </h3>
                    )}
                  </div>
                  <Select
                    as="select"
                    name="timeZone"
                    className={"wellness-form-input-field"}
                    onChange={(e) => {
                      setFieldValue("timeZone", parseInt(e.value));
                    }}
                    styles={customStyles}
                    options={timeZoneOptions}
                  />
                </div>

                {/* <div
                      className={"wellness-report-wellness-form-input-field"}
                    >
                      <div className={"wellness-report-field-header-container"}>
                        <h2>Select Notification Time</h2>
                        {errors.timeOfNotification && touched.timeOfNotification && (
                          <h3 className={"wellness-report-form-error-message"}>
                            * {errors.timeOfNotification}
                          </h3>
                        )}
                      </div>
                      <Select
                        as="select"
                        name="timeOfNotification"
                        className={"wellness-form-input-field"}
                        onChange={(e) => {
                          setFieldValue("timeOfNotification", parseInt(e.value));
                        }}
                        styles={customStyles}
                        options={notificationOptions}
                      />
                    </div> */}

                <button type="submit" className={"update-form-submit-button"}>
                  Next
                </button>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </>
  );
}
