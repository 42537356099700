import React from 'react'
import "./StripeSuccess.css"

export default function StripeSuccess() {
    return (
        <div className={"stripe-success-container"}>
            <div className={"stripe-success-content-block"}>
                <h2>Success!</h2>
            </div>
            
        </div>
    )
}
