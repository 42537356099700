import Check from "../../images/b.png";
import Divider from "@mui/material/Divider";
import { useAuth0 } from "@auth0/auth0-react";

export default function PricingCard({
  monthly,
  annually,
  image,
  title,
  price,
  caption,
  prices,
  handleSelectPlan,
  userDB,
}) {
  const { loginWithRedirect } = useAuth0();
  return (
    <div className={"pricing-card-container"}>
      {monthly && (
        <div
          style={{
            width: "100%",
            height: "5px",
            backgroundColor: "#ffd301",
          }}
        />
      )}
      {annually && (
        <div
          style={{
            width: "100%",
            height: "5px",
            backgroundColor: "#fb4021",
          }}
        />
      )}
      <div className={"pricing-card-container-inner"}>
        <img
          className={"pricing-card-image"}
          src={image}
          alt={"pricing card"}
        />
        <div className={"pricing-card-title"}>{title}</div>
        <div className={"pricing-card-price"}>{price}</div>
        <div className={"pricing-card-caption"}>{caption}</div>
        <div>
          {prices.map((line) => (
            <div key={line} className={"pricing-card-p"}>
              <img style={{ marginRight: "10px" }} src={Check} alt={"check"} />
              {line}
            </div>
          ))}
        </div>
        <Divider style={{ margin: "25px", width: "100%" }} />
        <div className={"pricing-row-container"}>
        </div>
        <div className={"pricing-row-container"}>
          <div className={"pricing-card-p"}>
            Change or cancel your subsciption at any time.
          </div>
        </div>
        {!userDB ? (
          <button
            className={"pricing-card-button"}
            onClick={() =>
              loginWithRedirect({
                screen_hint: "signup",
              })
            }
          >
            Sign Up
          </button>
        ) : (
          <button className={"pricing-card-button"} onClick={handleSelectPlan}>
            Start Plan
          </button>
        )}
      </div>
    </div>
  );
}
