import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import "./StaffWellnessTable.css";

const columns = [
  { id: "date", label: "Date", width: 60 },
  { id: "sleep", label: "Sleep", minWidth: 30 },
  {
    id: "fatigue",
    label: "Fatigue",
    minWidth: 30,
  },
  {
    id: "soreness",
    label: "Soreness",
    minWidth: 30,
  },
  {
    id: "stress",
    label: "Stress",
    minWidth: 30,
  },
];

const handleStyleBackgroundOnCondition = (value) => {
  if (value <= 5 && value > 0) {
    return {
      backgroundColor: "#2bd697",
    };
  }
  if (value > 5 && value <= 7) {
    return {
      backgroundColor: "#fcb757",
    };
  }
  if (value > 7) {
    return {
      backgroundColor: "#e45741",
    };
  } else {
    return;
  }
};

const handleStyleBackgroundOnConditionSleepOnly = (value) => {
  if (value <= 5 && value > 0) {
    return {
      backgroundColor: "#e45741",
    };
  }
  if (value > 5 && value <= 7) {
    return {
      backgroundColor: "#fcb757",
    };
  }
  if (value > 7) {
    return {
      backgroundColor: "#2bd697",
    };
  } else {
    return;
  }
};

const handleStyleFontOnCondition = (value) => {
  if (value <= 2 && value > 0) {
    return {
      color: "white",
    };
  }
  if (value > 2 && value <= 5) {
    return {
      color: "white",
    };
  }
  if (value > 5) {
    return {
      color: "white",
    };
  } else {
    return;
  }
};

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

export default function StaffWellnessTable({ data }) {
  const classes = useStyles();

  const sortedData = data && [...data].reverse();

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={"wellness-table-header"}
                >
                  <p> {column.label}</p>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData &&
              sortedData.map((row, i) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                    {columns.map((column) => {
                      console.log("column", column.label);
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={"wellness-table-cell-container"}
                        >
                          {column.format && typeof value === "number" ? (
                            column.format(value)
                          ) : (
                            <div className={"cell-container"}>
                              <div
                                className={"cell-background-color"}
                                style={
                                  column.label === "Sleep"
                                    ? handleStyleBackgroundOnConditionSleepOnly(value)
                                    : handleStyleBackgroundOnCondition(value)
                                }
                              >
                                <p style={handleStyleFontOnCondition(value)}>
                                  {value}
                                </p>
                              </div>
                            </div>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
