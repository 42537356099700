import React, { createContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import service from "../utils/service";

// context provider for user from MongoDB
export const Mongo = createContext();

const MongoProvider = (props) => {
  const { user } = useAuth0(); // get user from auth0
  const [userDB, setUserDB] = useState(); // get/set user from MongoDB
  const [profileImageId, setProfileImageId] = useState();
  const [onBoarded, setOnBoarded] = useState(false);
  const value = {
    userDB,
    setUserDB,
    profileImageId,
    setProfileImageId,
    onBoarded,
    setOnBoarded,
  };

  useEffect(() => {
    user &&
      service.getUserFromDB(user.email).then((foundUser) => {
        // check to see if auth0 user exists in mongoDB
        if (foundUser.data.userDB) {
          setUserDB(foundUser.data.userDB);
          setProfileImageId(foundUser.data.userDB.imageId);
          if (foundUser.data.userDB.onboardingComplete) {
            setOnBoarded(true);
          } else {
            setOnBoarded(false);
          }
        } else {
          // create new user in mongoDB
          service.createUserFromAuth0(user).then((newUser) => {
            setUserDB(newUser.data.userDB);
            setProfileImageId(newUser.data.userDB.imageId);
            setOnBoarded(false);
          });
        }
      });
    // eslint-disable-next-line
  }, [user, setUserDB, onBoarded, setOnBoarded]);

  return <Mongo.Provider value={value}>{props.children}</Mongo.Provider>;
};

export default MongoProvider;
