import React, { useContext, useEffect, useState } from "react";
import { Mongo } from "../../auth/mongo-provider";
import { useHistory } from "react-router-dom";
import "./StaffHome.css";
import service from "../../utils/service";
import SideMenu from "../sideMenu/SideMenu";
import { MediaSize } from "../../styles/MediaSizeProvider";
import PlusIcon from "../../images/plus-sign.png";
import AdminTeamsCard from "../myTeamsCard/AdminTeamsCard";
import Pagination from "../pagination/Pagination";
import Search from "../searchAthletesAdmin/SearchAthletesAdmin";

export default function AdminTeamsHome() {
  const { Desktop } = useContext(MediaSize);
  const { userDB } = useContext(Mongo);
  const [myTeams, setMyTeams] = useState();
  // const stripeStatus = location && location.state && location.state.status;
  const [visibleTeams, setVisibleTeams] = useState();

  let history = useHistory();

  useEffect(() => {
    userDB &&
      userDB.admin &&
      service.getTeams(userDB._id).then((allTeams) => {
        if (allTeams.data.teams) {
          setMyTeams(allTeams.data.teams);
          setVisibleTeams(allTeams.data.teams);
        }
      });

    // eslint-disable-next-line
  }, [userDB, setMyTeams]);

  const filterResults = (searchTerm) => {
    const copy = [...myTeams];
    const filteredResults = copy.filter((team) => {
      return Object.keys(team).some((key) => {
        return team[key]
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
    });
    setVisibleTeams([...filteredResults]);
  };

  return (
    <>
      <div className={"team-home-main-container"}>
        {/* {!loading ? ( */}
        <>
          <Desktop>
            <div className={"home-side-menu-container"}>
              <SideMenu />
            </div>
          </Desktop>
          {userDB && userDB.admin ? (
            <>
              {/* {myTeams && myTeams.length > 0 ? ( */}
              <>
                <div className={"team-main-content-container-teams"}>
                  <div className={"team-main-content-header-container-teams"}>
                    <h2>My Teams</h2>

                    <div
                      className={"home-main-content-header-navigation-button"}
                      onClick={() => {
                        history.push("/create-team");
                      }}
                    >
                      <img src={PlusIcon} alt="create-team" />
                      <p>New Team</p>
                    </div>
                  </div>
                  <div className={"serch-box-container"}>
                    <Search executeSearch={filterResults} />
                  </div>

                  <div className={"team-main-reports-container"}>
                    <div className={"team-card-colum-header-teams"}>
                      <p>Team</p>

                      {/* <Desktop>
                          <p>Activity</p>
                          <p>City</p>
                        </Desktop> */}
                      <p>Members</p>
                      <p>Staff</p>
                      <p>Action</p>
                    </div>
                    <div className={"team-card-colum-header-line"}></div>

                    {visibleTeams &&
                      visibleTeams.map((team, i) => {
                        return (
                          <>
                            <AdminTeamsCard team={team} i={i} />
                            <div
                              className={"team-card-colum-header-line"}
                            ></div>
                          </>
                        );
                      })}
                  </div>
                  <Pagination />
                </div>
              </>
            </>
          ) : (
            <>
              <div className={"staff-home-no-subscription-container"}>
                <div className={"staff-home-no-subscription-content"}>
                  <h2>Whoops, You dont have an Admin Account</h2>
                </div>
              </div>
            </>
          )}
        </>
        {/* ) : (
          <Loading />
        )} */}
      </div>
    </>
  );
}
