import React, { useContext } from "react";
import { Bar } from "react-chartjs-2";
import "./WellnessChart.css";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { MediaSize } from "../../styles/MediaSizeProvider";

export default function WellnessChart({ date, variable, label }) {
  const { Desktop, Mobile, Tablet } = useContext(MediaSize);

  console.log("label",label === "hours of sleep")

  const handleStyleOnCondition = (value, label) => {
    console.log("variable", variable && variable.slice(-1));
    if (value && value.slice(-1) <= 5 && value.slice(-1) > 0) {
      return "#0cc38a";
    }
    if (value && value.slice(-1) > 5 && value.slice(-1) <= 7) {
      return "#fcb757";
    }
    if (value && value.slice(-1) > 7) {
      return "#e45741";
    } else {
      return;
    }
  };

  const handleStyleOnConditionSleepOnly = (value, label) => {
    if (value && value.slice(-1) <= 5 && value.slice(-1) > 0) {
      
      return "#e45741";
    }
    if (value && value.slice(-1) > 6 && value.slice(-1) <= 8) {
      return "#fcb757";
    }
    if (value && value.slice(-1) > 8) {
      return "#0cc38a";
    } else {
      return;
    }
  };

  const data = {
    labels: date,
    datasets: [
      {
        label: label,
        data: variable,
        backgroundColor: label === "hours of sleep" ? handleStyleOnConditionSleepOnly(variable) : handleStyleOnCondition(variable),
        borderColor: label === "hours of sleep" ? handleStyleOnConditionSleepOnly(variable) : handleStyleOnCondition(variable),
        borderWidth: 1,
        borderRadius: 12,
      },
    ],
  };

  // Register the plugin to all charts:
  Chart.register(ChartDataLabels);

  const options = {
    plugins: {
      datalabels: {
        display: true,
        color: "white",
        font: { size: "20", fontFamily: "Proxima Nova" },
      },
    },
  };

  const VerticalBar = () => (
    <>
      <Desktop>
        <Bar data={data} height="100" options={options} />
      </Desktop>

      <Tablet>
        <Bar data={data} height="150" options={options} />
      </Tablet>

      <Mobile>
        <Bar data={data} height="200" options={options} />
      </Mobile>
    </>
  );
  return (
    <>
      <div>{VerticalBar()}</div>
    </>
  );
}
