import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Faqs({ faqArray }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {faqArray.map((faq, i) => (
        <Accordion
          key={faq.question}
          style={{
            boxShadow: "none",
            backgroundColor: "#FCFCFC",
          }}
          expanded={expanded === `panel${i}`}
          onChange={handleChange(`panel${i}`)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div
              style={{
                color: "#161c2d",
                fontFamily: "Proxima Nova",
                fontSize: "18px",
                fontWeight: "700",
                fontStyle: "normal",
                letterSpacing: "normal",
                textAlign: "left",
              }}
            >
              {faq.question}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                color: "#606060",
                fontFamily: "Proxima Nova",
                fontSize: "17px",
                fontWeight: "400",
                fontStyle: "normal",
                letterSpacing: "normal",
                lineHeight: "30px",
                textAlign: "left",
              }}
            >
              {faq.details}
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
