import React, { useState } from "react";
import service from "../../utils/service";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "yup-phone";
import "./UpdateTeamInfo.css";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { DateTime } from "luxon";

export default function UpdateTeamInfo({
  teamDetails,
  setTeamDetails,
  userId,
}) {
  const [confirmationMsg, setConfirmationMsg] = useState(null);
  let history = useHistory();

  const timeZoneOptions = [
    { label: "US Eastern-(EST UTC-5)", value: 5 },
    { label: "US Central-(CST UTC-6)", value: 6 },
    { label: "US Mountain-(MST UTC-7)", value: 7 },
    { label: "US Pacific-(PST UTC-8)", value: 8 },
    { label: "UK Longon-(GMT UTC)", value: 0 },
  ];

  const notificationOptions = [
    { label: "5:00 am", value: 5 },
    { label: "6:00 am", value: 6 },
    { label: "7:00 am", value: 7 },
    { label: "8:00 am", value: 8 },
    { label: "9:00 am", value: 9 },
    { label: "10:00 am", value: 10 },
    { label: "11:00 am", value: 11 },
    { label: "12:00 pm", value: 12 },
    { label: "1:00 pm", value: 13 },
    { label: "2:00 pm", value: 14 },
    { label: "3:00 pm", value: 15 },
    { label: "4:00 pm", value: 16 },
    { label: "5:00 pm", value: 17 },
    { label: "6:00 pm", value: 18 },
    { label: "7:00 pm", value: 19 },
    { label: "8:00 pm", value: 20 },
    { label: "9:00 pm", value: 21 },
    { label: "10:00 pm", value: 22 },
    { label: "11:00 pm", value: 23 },
    { label: "12:00 am", value: 24 },
    { label: "1:00 am", value: 1 },
    { label: "2:00 am", value: 2 },
    { label: "3:00 am", value: 3 },
    { label: "4:00 am", value: 4 },
  ];

  const handleDeleteTeam = (teamId) => {
    service
      .deleteTeam({
        teamId: teamId,
        userId,
      })
      .then(() => {
        history.push(`/teams`);
      });
  };

  const displayTimeZone = (zone) => {
    switch (zone) {
      case "0":
        return <p>UK Longon-(GMT UTC)</p>;

      case "5":
        return <p>US Eastern-(EST UTC-5)</p>;

      case "6":
        return <p>US Central-(CST UTC-6)</p>;

      case "7":
        return <p>US Mountain-(MST UTC-7)</p>;

      case "8":
        return <p>US Pacific-(PST UTC-8)</p>;

      default:
        break;
    }
  };

  const customStyles = {
    menu: () => ({
      backgroundColor: "#ffffff",
      border: "1px solid #dbdbdb91",
      height: "46",
      // color: "#373737af",
      fontFamily: "Proxima Nova",
      fontSize: "18px",
    }),
  };

  return (
    <>
      <div className={"update-team-form-main-container"}>
        {teamDetails && (
          <Formik
            initialValues={{
              name: teamDetails.name,
              division: teamDetails.division,
              sport: teamDetails.sport,
              city: teamDetails.city,
              state: teamDetails.state,
              notifications_Email_recieveDailyReport:
                teamDetails.notifications_Email_recieveDailyReport,
              stat_sports: false,
              beyond_pulse: false,
              playertech: false,
              titan: false,
              hrv_sensor: false,
              force_frame: false,
              nordboard: false,
              sparta_science: false,
            }}
            validationSchema={Yup.object({
              name: Yup.string().required("Required"),
              city: Yup.string().required("Required"),
              state: Yup.string().required("Required"),
            })}
            onSubmit={async (values, { resetForm }) => {
              await service
                .updateTeamInfo({ ...values, teamId: teamDetails._id })
                .then((response) => {
                  if (response.data.teamDetails) {
                    setTeamDetails(response.data.teamDetails);
                    setConfirmationMsg("Your team profile udated succesfully");
                  }
                });
            }}
          >
            {({ errors, touched, setFieldValue }) => (
              <div className={"update-team-form-container"}>
                <Form>
                  <div className={"update-team-form-input-field"}>
                    <div className={"update-team-field-header-container"}>
                      <h2>Team Name</h2>
                      {errors.name && touched.name && (
                        <h3 className={"update-team-form-error-message"}>
                          * {errors.name}
                        </h3>
                      )}
                    </div>
                    <Field
                      name="name"
                      type="text"
                      placeholder="Team Name"
                      className={"form-input-field"}
                    />
                  </div>
                  <div className={"update-team-form-input-field"}>
                    <div className={"update-team-field-header-container"}>
                      <h2>City</h2>
                      {errors.city && touched.city && (
                        <h3 className={"update-team-form-error-message"}>
                          * {errors.city}
                        </h3>
                      )}
                    </div>
                    <Field
                      label="City"
                      name="city"
                      type="text"
                      placeholder="City"
                      className={"form-input-field"}
                    />
                  </div>

                  <div className={"update-team-form-input-field"}>
                    <div className={"update-team-field-header-container"}>
                      <h2>State</h2>
                      {errors.state && touched.state && (
                        <h3 className={"update-team-form-error-message"}>
                          * {errors.state}
                        </h3>
                      )}
                    </div>
                    <Field
                      name="state"
                      type="text"
                      placeholder="State"
                      className={"form-input-field"}
                    />
                  </div>

                  <div className={"update-team-form-input-field"}>
                    <div className={"update-team-field-header-container"}>
                      <h2>Team Activity</h2>
                      {errors.sport && touched.sport && (
                        <h3 className={"update-team-form-error-message"}>
                          * {errors.sport}
                        </h3>
                      )}
                    </div>
                    <Field
                      name="sport"
                      type="text"
                      placeholder="Team Activity"
                      className={"form-input-field"}
                    />
                  </div>

                  <div className={"update-team-form-input-field"}>
                    <div className={"update-team-field-header-container"}>
                      <p>Get Daily Reports</p>
                    </div>
                    <Field
                      name="notifications_Email_recieveDailyReport"
                      type="checkbox"
                      className={"form-input-field"}
                    />
                  </div>

                  <div className={"user-update-form-input-container"}>
                    <div className={"update-team-field-header-container"}>
                      <h2>Select Time Zone</h2>
                      {errors.timeZone && touched.timeZone && (
                        <h3 className={"wellness-report-form-error-message"}>
                          * {errors.timeZone}
                        </h3>
                      )}
                    </div>
                    <p>{displayTimeZone(teamDetails.timeZone)}</p>
                    <Select
                      as="select"
                      name="timeZone"
                      className={"wellness-form-input-field"}
                      onChange={(e) => {
                        setFieldValue("timeZone", parseInt(e.value));
                      }}
                      styles={customStyles}
                      options={timeZoneOptions}
                    />
                  </div>

                  <div className={"user-update-form-input-container"}>
                    <div className={"update-team-field-header-container"}>
                      <h2>Select Notification Time </h2>
                      {errors.timeOfNotification &&
                        touched.timeOfNotification && (
                          <h3 className={"wellness-report-form-error-message"}>
                            * {errors.timeOfNotification}
                          </h3>
                        )}
                    </div>
                    <p>
                      {teamDetails.timeOfNotification &&
                        DateTime.fromObject({
                          hour: teamDetails.timeOfNotification,
                        }).toLocaleString({
                          hour: "numeric",
                          minute: "numeric",
                        })}
                    </p>
                    <Select
                      as="select"
                      name="timeOfNotification"
                      className={"wellness-form-input-field"}
                      onChange={(e) => {
                        setFieldValue("timeOfNotification", parseInt(e.value));
                      }}
                      styles={customStyles}
                      options={notificationOptions}
                    />
                  </div>

                  {!confirmationMsg && <h3>{confirmationMsg}</h3>}
                  <button type="submit" className={"update-form-submit-button"}>
                    Update Team
                  </button>
                </Form>
              </div>
            )}
          </Formik>
        )}
      </div>
      <h2>Danger Zone</h2>
      <div className={"update-team-form-main-container"}>
        {/* <div className={"update-team-form-container"}> */}
        <h3>Delete Team</h3>
        <button
          className={"update-edit-form-submit-button"}
          onClick={() => handleDeleteTeam(teamDetails._id)}
        >
          Delete
        </button>
        {/* </div> */}
      </div>
    </>
  );
}
