import React from "react";
import "./MyTeamMembershipsCard.css"

export default function MyTeamMembershipsCard({team, i, handleRemoveFromTeam}) {
  return (
      <div className={"team-memberships-details-main-card-container"} key={i}>
    <div className={"team-memberships-details-team-card-outer-container"} key={i}>
        <div className={"team-memberships-details-team-card-text"}>
          <h2>Team Member</h2>
          <h3>Team: {team.name}</h3>
          <p>{team.email}</p>
          <p>Activity: {team.sport}</p>
          <p>City: {team.city}</p>
        </div>
        
      <div className={"accept-team-membershipss-button-container"}>
        <button
          className={"team-memberships-remove-button"}
          onClick={() => handleRemoveFromTeam(team)}
        >
          Remove
        </button>
      </div>
      </div>
    </div>
  );
}
