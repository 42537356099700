import "./HeroSection.css";
import ArrowRight from "../../images/contact-hero-arrow.png";

export default function HeroSection({ prevPage, currentPage }) {
  return (
    <div className={"hero-container"}>
      <div className={"hero-title"}>{currentPage}</div>
      <div className={"hero-links"}>
        <div className={"hero-text"}>{prevPage}</div>
        <img src={ArrowRight} alt={"arrow right"} />
        <div className={"hero-text"}>{currentPage}</div>
      </div>
    </div>
  );
}
