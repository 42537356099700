import React, { useContext, useEffect, useState } from "react";
import service from "../../utils/service";
import { DateTime, Interval } from "luxon";
// import { useLocation } from "react-router-dom";
import "./StaffWellnessChartContainer.css";
import DateRangeSliderUserWellness from "../sliderUserWellness/DateRangeSliderUserWellness";
import StaffWellnessTable from "../staffWellnessTable/StaffWellnessTable";
import StaffFlagReports from "../staffFlagReports/StaffFlagReports";
// import Loading from "../loading/Loading";
import { MediaSize } from "../../styles/MediaSizeProvider";
import SideMenu from "../sideMenu/SideMenu";

export default function StaffWellnessChartContainer({ match }) {
  // const location = useLocation();
  const { Desktop } = useContext(MediaSize);
  const [teamDetails, setTeamDetails] = useState();
  const [targetChartRange, setTargetChartRange] = useState(5);
  const [preSessionData, setPreSessionData] = useState();
  // const [loading, setLoading] = useState(true);
  const [now] = useState(
    DateTime.now().set({ hour: 0, minutes: 0, seconds: 0, milliseconds: 0 })
  );
  // const teamId = location && location.state && location.state.teamId;
  const teamId = match && match.params.teamId;
  window.scroll({ top: 0, behavior: "smooth" });

  const calculateMetricSum = (filteredArray, metric) => {
    return filteredArray
      .map((report) => report[metric])
      .reduce((prev, curr) => prev + curr, 0);
  };

  useEffect(() => {
    // build shell of object for pre session chart
    const tgtObj = [];
    for (let i = 0; i <= targetChartRange - 1; i++) {
      const obj = {};
      obj["date"] = now.minus({ days: i });
      tgtObj.push(obj);
    }

    // get updated data from DB
    teamId &&
      service.getTeamDetails(teamId).then((team) => {
        if (team.data.teamDetails) {
          setTeamDetails(team.data.teamDetails);

          // consolidate all members' pre-session reports
          const preSessionReportsArray = [];
          team.data.teamDetails.athletes.forEach((athlete) => {
            athlete.preSessionWellness.forEach((report) => {
              preSessionReportsArray.push(report);
            });
          });

          // build pre session data for chart
          let data = [];
          tgtObj.forEach((target) => {
            let hash = {};
            const daysSinceTarget = Math.floor(
              Interval.fromDateTimes(DateTime.fromISO(target.date), now).length(
                "days"
              )
            );

            // group reports by days since now
            const filteredPreSessionsReports = preSessionReportsArray.filter(
              (report) => {
                const submitted = report.createdAt;
                const daysSinceReport = Math.round(
                  Interval.fromDateTimes(
                    DateTime.fromISO(submitted).set({
                      hour: 0,
                      minutes: 0,
                      seconds: 0,
                      milliseconds: 0,
                    }),
                    now
                  ).length("days")
                );
                return daysSinceReport === daysSinceTarget;
              }
            );

            // build data object
            hash["date"] = target.date.toLocaleString({
              day: "numeric",
              month: "short",
            });
            hash["sleep"] = calculateMetricSum(
              filteredPreSessionsReports,
              "sleep"
            )
              ? Math.round(
                  ((calculateMetricSum(filteredPreSessionsReports, "sleep") /
                    filteredPreSessionsReports.length) *
                    100) /
                    100
                )
              : 0;
            hash["fatigue"] = calculateMetricSum(
              filteredPreSessionsReports,
              "fatigue"
            )
              ? Math.round(
                  ((calculateMetricSum(filteredPreSessionsReports, "fatigue") /
                    filteredPreSessionsReports.length) *
                    100) /
                    100
                )
              : 0;
            hash["soreness"] = calculateMetricSum(
              filteredPreSessionsReports,
              "soreness"
            )
              ? Math.round(
                  ((calculateMetricSum(filteredPreSessionsReports, "soreness") /
                    filteredPreSessionsReports.length) *
                    100) /
                    100
                )
              : 0;
            hash["stress"] = calculateMetricSum(
              filteredPreSessionsReports,
              "stress"
            )
              ? Math.round(
                  ((calculateMetricSum(filteredPreSessionsReports, "stress") /
                    filteredPreSessionsReports.length) *
                    100) /
                    100
                )
              : 0;
            hash["reports"] = filteredPreSessionsReports.length;

            data.push(hash);
            hash = {};
          });
          setPreSessionData(data.reverse());
          // setLoading(false);
        }
      });

    // eslint-disable-next-line
  }, [targetChartRange, teamId]);

  return (
    <div className={"staff-wellness-main-container"}>
      {/* {!loading ? ( */}
      <>
        <Desktop>
          <div className={"staff-wellness-side-menu-container"}>
            <SideMenu />
          </div>
        </Desktop>
        <div className={"staff-wellness-main-content-container"}>
          <>
            <div className={"staff-wellness-main-content-header-container"}>
              <h2>Team Wellness</h2>

              <div className={"wellness-report-slider"}>
                <p>Select Range</p>
                <DateRangeSliderUserWellness
                  setTargetChartRange={setTargetChartRange}
                />
              </div>
            </div>

            <div className={"staff-wellness-main-reports-container"}>
              <div className={"staff-wellness-table-main-container"}>
                <StaffWellnessTable data={preSessionData} />
              </div>
              <div className={"staff-flag-reports-main-container"}>
                <StaffFlagReports teamDetails={teamDetails} teamId={teamId} />
              </div>
            </div>
          </>
        </div>
      </>
      {/* ) : (
        <Loading />
      )} */}
    </div>
  );
}
