import React from "react";
import "./App.css";
import "./fonts/Raleway-Regular.ttf";
import { Route, Switch } from "react-router-dom";
import NavBar from "./components/navbar/NavBar";
import Landing from "./components/landing/Landing";
// import NewAthlete from "./components/newAthlete/NewAthlete";
// import AdminAthletes from "./components/adminAthletes/AdminAthletes";
// import AdminAthleteDetails from "./components/adminAthleteDetails/AdminAthleteDetails";
import UserProfile from "./components/userProfile/UserProfile";
// import DocumentUpload from "./components/documentUpload/DocumentUpload";
// import DocumentUploadContainer from "./components/documentUpload/DocumentUploadContainer";
// import AdminTeams from "./components/adminTeams/AdminTeams";
import NewTeam from "./components/newTeam/NewTeam";
// import TeamDetails from "./components/adminTeamDetails/AdminTeamDetails";
import ProtectedRoute from "./components/auth0/protectedRoute";
import Welcome from "./components/welcome/Welcome";
import { useAuth0 } from "@auth0/auth0-react";
import WellnessLanding from "./components/wellnessLanding/WellnessLanding";
import UserAccount from "./components/userAccount/UserAccount";
// import UserWellnessChartContainer from "./components/UserWellnessChart/UserWellnessChartContainer";
import StaffHome from "./components/staffHome/StaffHome";
import TeamMemberDetails from "./components/teamMemberDetails/TeamMemberDetails";
import ManageGroups from "./components/manageGroups/ManageGroups";
import UserWellnessChartContainer from "./components/userWellnessChart/UserWellnessChartContainer";
import StaffWellnessChartContainer from "./components/staffWellnessTableContainer/StaffWellnessChartContainer";
import StripeSuccess from "./components/stripeSuccess/StripeSuccess";
import Checkout from "./components/stripeCheckout/Checkout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Footer from "./components/footer/Footer";
import TieredPricingModels from "./components/stripeTieredPricingModels/TieredPricingModels";
// import About from "./components/about/About";
import Contact from "./components/contact/Contact";
// import Loading from "./components/loading/Loading";
import Quote from "./components/stripeQuote/Quote";
import InviteUser from "./components/inviteUser/InviteUser";
import EditTeam from "./components/editTeam/EditTeam";
import NewWellnessReportContainer from "./components/newWellnessReportContainer/NewWellnessReportContainer";
import InviteStaff from "./components/inviteStaff/InviteStaff";
import TeamStaffDetails from "./components/teamStaffDetails/TeamStaffDetails";
import UserWellnessReports from "./components/userWellnessReports/UserWellnessReports";
import Home from "./components/home/Home";
import NewExertionReportContainer from "./components/newExertionReportContainer/NewExertionReportContainer";
import UserExertionChartContainer from "./components/userExertionChart/UserExertionChartContainer";
import UserExertionReports from "./components/userExertionReports/UserExertionReports";
import AdminUsersHome from "./components/staffHome/AdminUsersHome";
import AdminTeamsHome from "./components/staffHome/AdminTeamsHome";

export default function App() {
  const { isLoading } = useAuth0();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_TEST);
  if (isLoading) {
    return (
      <div className={"app-main-container"}>
        {/* //       <div className={"app-loading-block"}> */}
        {/* //         <Loading />; */}
        {/* //       </div> */}
      </div>
    );
  }

  return (
    <div className="App">
      {<NavBar />}

      <Elements stripe={stripePromise}>
        <Switch>
          {/* landing pages */}
          <Route exact path="/" component={Landing} />
          <Route exact path="/wellness" component={WellnessLanding} />

          {/* onboarding pages */}
          <Route exact path="/welcome" component={Welcome} />
          {/* stripe */}
          <Route exact path="/pricing" component={TieredPricingModels} />
          <Route exact path="/checkout" component={Checkout} />
          <Route exact path="/success" component={StripeSuccess} />

          {/* all users */}
          <Route exact path="/quote" component={Quote} />
          {/* <Route exact path="/about" component={About} /> */}
          <Route exact path="/contact" component={Contact} />
          <ProtectedRoute
            exact
            path="/wellness-reports/:userId"
            component={UserWellnessReports}
          />
          <ProtectedRoute exact path="/home" component={Home} />
          <ProtectedRoute
            exact
            path="/memberships/:userId"
            component={ManageGroups}
          />
          <ProtectedRoute
            exact
            path="/wellness-chart/:userId"
            component={UserWellnessChartContainer}
          />
          <ProtectedRoute
            exact
            path="/profile/:userId"
            component={UserProfile}
          />
          <ProtectedRoute
            exact
            path="/account/:userId"
            component={UserAccount}
          />
          <ProtectedRoute
            exact
            path="/new/:userId"
            component={NewWellnessReportContainer}
          />

          {/* exertion reports */}
          <ProtectedRoute
            exact
            path="/new-exertion/:userId"
            component={NewExertionReportContainer}
          />
          <ProtectedRoute
            exact
            path="/exertion-reports/:userId"
            component={UserExertionReports}
          />
          <ProtectedRoute
            exact
            path="/exertion-chart/:userId"
            component={UserExertionChartContainer}
          />

          {/* staff */}
          <ProtectedRoute exact path="/teams" component={StaffHome} />
          <ProtectedRoute exact path="/create-team" component={NewTeam} />
          <ProtectedRoute
            exact
            path="/team-wellness/:teamId"
            component={StaffWellnessChartContainer}
          />
          <ProtectedRoute
            exact
            path="/staff/:teamId"
            component={StaffWellnessChartContainer}
          />
          <ProtectedRoute
            exact
            path="/manage/:teamId"
            component={TeamMemberDetails}
          />

          <ProtectedRoute
            exact
            path="/manage-staff/:teamId"
            component={TeamStaffDetails}
          />

          <ProtectedRoute exact path="/edit/:teamId" component={EditTeam} />

          <ProtectedRoute
            exact
            path="/team-invite/:teamId"
            component={InviteUser}
          />

          <ProtectedRoute
            exact
            path="/staff-invite/:teamId"
            component={InviteStaff}
          />

          {/* admin */}
          <ProtectedRoute
            exact
            path="/admin-teams/:userId"
            component={AdminTeamsHome}
          />

          <ProtectedRoute
            exact
            path="/admin-users/:userId"
            component={AdminUsersHome}
          />
          {/* <ProtectedRoute
            exact
            path="/admin/athletes"
            component={AdminAthletes}
          /> */}

          {/* --------------------------- */}
          {/* protect these routes from being used by non-admin */}

          {/* <ProtectedRoute
            exact
            path="/admin/create-athlete"
            component={NewAthlete}
          />
          <ProtectedRoute exact path="/admin/create-team" component={NewTeam} />
          <Route
            exact
            path="/admin/document-upload"
            render={(props) => <DocumentUploadContainer {...props} />}
          />
          <ProtectedRoute
            exact
            path="/admin/document-upload/stat-sports"
            component={DocumentUpload}
          />
          <ProtectedRoute
            exact
            path="/admin/document-upload/titan"
            component={DocumentUpload}
          />
          <ProtectedRoute
            exact
            path="/admin/document-upload/beyond-pulse"
            component={DocumentUpload}
          />
          <ProtectedRoute
            exact
            path="/admin/document-upload/player-tech"
            component={DocumentUpload}
          />
          <ProtectedRoute
            exact
            path="/admin/document-upload/hrv"
            component={DocumentUpload}
          />
          <ProtectedRoute
            exact
            path="/admin/document-upload/force-frame"
            component={DocumentUpload}
          />
          <ProtectedRoute
            exact
            path="/admin/document-upload/sparta-science"
            component={DocumentUpload}
          />
          <ProtectedRoute
            exact
            path="/admin/document-upload/nordboard"
            component={DocumentUpload}
          />
          <ProtectedRoute exact path="/admin/teams" component={AdminTeams} />
          <ProtectedRoute
            exact
            path="/admin/teams/:teamId"
            component={TeamDetails}
          />
          <ProtectedRoute
            exact
            path="/admin/athlete/:athleteId"
            component={AdminAthleteDetails}
          /> */}

          {/* --------------------------------     */}
        </Switch>
        <Footer />
      </Elements>
    </div>
  );
}
