import React, { useContext, useState } from "react";
import { DateTime, Interval } from "luxon";
import "./AllUsersDetailsCard.css";
import { Image, Transformation, CloudinaryContext } from "cloudinary-react";
import service from "../../utils/service";
import DeleteMemberIcon from "../../images/teams-delete-team.png";
import { MediaSize } from "../../styles/MediaSizeProvider";
import ChartIcon from "../../images/responsive-chart-icon.png";
import ReportsIcon from "../../images/responsive-report-icon.png";
import { useHistory } from "react-router-dom";
import AvatarIcon from "../../images/responsive-avatar-icon.png";
import MemberIcon from "../../images/account-details-total-teams.png";


export default function AllUsersDetailsCard({
  athlete,
  teamDetails,
  setTeamDetails,
  i,
}) {
  let history = useHistory();
  const { Desktop } = useContext(MediaSize);
  const [now] = useState(
    DateTime.now().set({ hour: 0, minutes: 0, seconds: 0, milliseconds: 0 })
  );

  const handleClickMemberChart = (userId) => {
    history.push({
      pathname: `/wellness-chart/${userId}`,
      state: {
        userId: userId,
        update: true,
      },
    });
  };

  const handleClickMemberReports = (userId) => {
    history.push({
      pathname: `/wellness-reports/${userId}`,
      state: {
        userId: userId,
        update: true,
      },
    });
  };

  const handleClickMemberProfile = (userId) => {
    history.push({
      pathname: `/profile/${userId}`,
      state: {
        userId: userId,
        update: true,
      },
    });
  };


  const handleClickMemberTeamMemberships = (userId) => {
    history.push({
      pathname: `/memberships/${userId}`,
      state: {
        userId: userId,
        update: true,
      },
    });
  };


  const handleRemoveFromTeam = (athlete) => {
    teamDetails &&
      service
        .removeMemberFromTeam({
          userId: athlete._id,
          teamId: teamDetails._id,
        })
        .then((response) => {
          setTeamDetails(response.data.teamDetails);
        });
  };


  const lastReport =
    athlete.preSessionWellness.length > 0
      ? athlete.preSessionWellness.slice(-1)[0].createdAt
      : athlete.preSessionWellness.createdAt;

  const daysSinceReport =
    athlete.preSessionWellness.length > 0
      ? Math.round(
          Interval.fromDateTimes(
            DateTime.fromISO(lastReport).set({
              hour: 0,
              minutes: 0,
              seconds: 0,
              milliseconds: 0,
            }),
            now
          ).length("hours")
        )
      : 25;

  return (
    <>
      <div className={"user-details-member-card-container"} key={i}>
        <Desktop>
        <div className={"user-details-member-card-profile-container"}>
          <CloudinaryContext
            cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
          >
            <Image publicId={athlete.imageId} format="png">
              <Transformation
                gravity="face"
                width="48"
                height="48"
                radius="100"
                zoom="0.7"
                crop="fill"
              />
            </Image>
          </CloudinaryContext>
          <p>{athlete.firstName}</p>
          <p>{athlete.lastName}</p>
        </div>

        </Desktop>
          <p>{athlete.email}</p>
        <div className={"user-details-member-card-action-icons-container"}>
          <Desktop>
            <div className={"user-details-member-card-invite-status-container"}>
              {daysSinceReport && daysSinceReport > 12 ? (
                <>
                  <div className={"user-details-member-card-pending-container"}>
                    <div
                      className={
                        "user-details-members-card-action-icon-pending"
                      }
                      title="Wellness report not sumbitted."
                    >
                      <p>Pending</p>
                    </div>
                  </div>
                </>
              ) : (
                <div
                  className={"user-details-member-card-action-icon-submitted"}
                  title="Wellness report sumbitted."
                >
                  <p>Submitted</p>
                </div>
              )}
            </div>
          </Desktop>
          <div className={"my-teams-card-action-icon"}>
            <img
              src={MemberIcon}
              alt="chart"
              className={"view-member-chart-icon"}
              onClick={() => {
                handleClickMemberTeamMemberships(athlete._id);
              }}
              title="View team memberships."
            />
          </div>
          <div className={"my-teams-card-action-icon"}>
            <img
              src={AvatarIcon}
              alt="chart"
              className={"view-member-chart-icon"}
              onClick={() => {
                handleClickMemberProfile(athlete._id);
              }}
              title="View profile."
            />
          </div>
          <div className={"my-teams-card-action-icon"}>
            <img
              src={ChartIcon}
              alt="chart"
              className={"view-member-chart-icon"}
              onClick={() => {
                handleClickMemberChart(athlete._id);
              }}
              title="View wellness chart."
            />
          </div>
          <div className={"my-teams-card-action-icon"}>
            <img
              src={ReportsIcon}
              alt="chart"
              className={"view-member-chart-icon"}
              onClick={() => {
                handleClickMemberReports(athlete._id);
              }}
              title="View wellness reports."
            />
          </div>
          <div
            className={"my-teams-card-action-icon"}
            id="delete-button"
            onClick={() => {
              handleRemoveFromTeam(athlete);
            }}
          >
            <img
              src={DeleteMemberIcon}
              alt="edit team"
              title="Delete member from team."
            />
          </div>
        </div>
      </div>
      <div className={"user-card-colum-header-line"}></div>
    </>
  );
}
