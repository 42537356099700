import "./GetStartedSection.css";
import { useAuth0 } from "@auth0/auth0-react";

export default function GetStartedSection() {
  const { loginWithRedirect } = useAuth0();
  return (
    <div className={"about-performance-container"}>
      <div className={"about-performance-title"}>
        Improve your team performance
      </div>
      <div className={"about-performance-caption"}>
        Upfield Analytics (UA) is the premier performance monitoring service for
        any organization that depends upon human performance to succeed.
      </div>
      <button
        className={"about-performance-button"}
        onClick={() =>
          loginWithRedirect({
            screen_hint: "signup",
          })
        }
      >
        Get Started
      </button>
    </div>
  );
}
