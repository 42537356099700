export default function TestimonialCard({ pic, name, title, message }) {
  return (
    <div className={"wellness-card"}>
      <div className={"wellness-card-inner-container"}>
        <div className={"wellness-card-top"}>
          <img className={"wellness-card-pic"} src={pic} alt={name} />
          <div className={"wellness-card-details"}>
            <div className={"wellness-card-name"}>{name}</div>
            <div className={"wellness-card-title"}>{title}</div>
          </div>
        </div>
        <div className={"wellness-card-bottom"}>{message}</div>
      </div>
    </div>
  );
}
