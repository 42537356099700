import React, { useContext, useEffect, useState } from "react";
import { Mongo } from "../../auth/mongo-provider";
import "./ManageGroups.css";
import service from "../../utils/service";
import AcceptTeamInvite from "../acceptTeamInvite/AcceptTeamInvite";
import MyTeamMembershipsCard from "../myTeamMembershipsCard/MyTeamMembershipsCard";
import { MediaSize } from "../../styles/MediaSizeProvider";
import SideMenu from "../sideMenu/SideMenu";
import AcceptStaffInvite from "../acceptStaffInvite/AcceptStaffInvite";
import MyStaffMembershipsCard from "../myStaffMembershipsCard/MyStaffMembershipsCard";

export default function ManageGroups({ location }) {
  const { userDB } = useContext(Mongo);
  const [myTeams_athlete, setMyTeams_athlete] = useState();
  const [teamInvitesRecieved, setTeamInvitesRecieved] = useState();
  const [myTeams_staff, setMyTeams_staff] = useState();
  const [staffInvitesRecieved, setStaffInvitesRecieved] = useState();
  const [user, setUser] = useState();
  const { Desktop } = useContext(MediaSize);
  const userId =
    location.state.userId !== undefined ? location.state.userId : null;

  useEffect(() => {
    if (!userId) {
      setUser(userDB);
      userDB &&
        service.getUserFromDB(userDB.email).then((foundUser) => {
          setTeamInvitesRecieved(foundUser.data.userDB.teamInvitesRecieved);
          setStaffInvitesRecieved(foundUser.data.userDB.staffInvitesRecieved);
          setMyTeams_athlete(foundUser.data.userDB.myTeams_athlete);
          setMyTeams_staff(foundUser.data.userDB.myTeams_staff);
        });
    } else {
      service.getUserFromDBWithId(userId).then((foundUser) => {
        setUser(foundUser.data.userDB);
        setTeamInvitesRecieved(foundUser.data.userDB.teamInvitesRecieved);
        setStaffInvitesRecieved(foundUser.data.userDB.staffInvitesRecieved);
        setMyTeams_athlete(foundUser.data.userDB.myTeams_athlete);
        setMyTeams_staff(foundUser.data.userDB.myTeams_staff);
      });
    }

    // eslint-disable-next-line
  }, [
    setTeamInvitesRecieved,
    setMyTeams_athlete,
    setStaffInvitesRecieved,
    setMyTeams_staff,
    userDB,
  ]);

  const handleRemoveFromTeam = (team) => {
    user &&
      service
        .removeMyselfFromTeam({
          email: team.email,
          userId: user._id,
          teamId: team._id,
        })
        .then((response) => {
          setTeamInvitesRecieved(response.data.teamInvitesRecieved);
          setMyTeams_athlete(response.data.myTeams_athlete);
        });
  };

  return (
    <div className={"manage-groups-main-container"}>
      <>
        <Desktop>
          <div className={"manage-groups-side-menu-container"}>
            <SideMenu />
          </div>
        </Desktop>
        <div className={"manage-groups-main-content-container"}>
          <div className={"manage-groups-main-content-header-container"}>
            <h2>My Team Invites</h2>
          </div>
          <div className={"manage-groups-recieved-invites"}>
            <AcceptTeamInvite
              teamInvitesRecieved={teamInvitesRecieved}
              setMyTeams_athlete={setMyTeams_athlete}
              setTeamInvitesRecieved={setTeamInvitesRecieved}
              user={user}
            />

            <AcceptStaffInvite
              staffInvitesRecieved={staffInvitesRecieved}
              setMyTeams_staff={setMyTeams_staff}
              setStaffInvitesRecieved={setStaffInvitesRecieved}
              user={user}
            />
          </div>

          <h2>My Team Memberships</h2>
          {myTeams_athlete && myTeams_athlete.length > 0 && (
            <>
              <div className={"manage-groups-main-reports-container"}>
                {myTeams_athlete &&
                  myTeams_athlete.map((team, i) => {
                    return (
                      <MyTeamMembershipsCard
                        i={i}
                        team={team}
                        handleRemoveFromTeam={handleRemoveFromTeam}
                      />
                    );
                  })}
              </div>
            </>
          )}

          {myTeams_staff && myTeams_staff.length > 0 && (
            <>
              <div className={"manage-groups-main-reports-container"}>
                {myTeams_staff &&
                  myTeams_staff.map((team, i) => {
                    return (
                      <MyStaffMembershipsCard
                        i={i}
                        team={team}
                        handleRemoveFromTeam={handleRemoveFromTeam}
                      />
                    );
                  })}
              </div>
            </>
          )}
        </div>
      </>
    </div>
  );
}
