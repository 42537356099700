export default function TeamWellnessSection({
  button,
  buttonText,
  buttonFunction,
  textRight,
  image,
  smallText,
  title,
  caption,
  description,
}) {
  return (
    <div className={"landing-hiw-section"}>
      {textRight ? (
        <img className={"landing-hiw-image"} src={image} alt={"invite"} />
      ) : null}

      <div className={"landing-text-container"}>
        <div className={"landing-hiw-small-text"}>{smallText}</div>
        <div className={"landing-hiw-title"}>{title}</div>
        <div className={"landing-hiw-caption"}>{caption}</div>
        <div className={"landing-hiw-p"}>{description}</div>
        {button && buttonText && buttonFunction && (
          <button
            style={{ marginBottom: "50px" }}
            className={"landing-button"}
            onClick={buttonFunction}
          >
            {buttonText}
          </button>
        )}
      </div>
      {!textRight ? (
        <img className={"landing-hiw-image"} src={image} alt={"invite"} />
      ) : null}
    </div>
  );
}
