import React, { useState } from "react";
import CallImage from "../../images/contact-call-icon.png";
import LocationImage from "../../images/contact-location-icon.png";
import EmailImage from "../../images/contact-email-icon.png";
import ArrowRight from "../../images/contact-hero-arrow.png";
import "./Contact.css";
import LearnMoreAboutWellness from "../learnMoreAboutWellness/LearnMoreAboutWellness";

export default function Contact() {
  const [learnMoreVisible, setLearnMoreVisible] = useState(true);

  return (
    <div className={"contact-main-container"}>
      <div className={"contact-hero-container"}>
        <div className={"contact-hero-title"}>Contact</div>
        <div className={"contact-hero-links"}>
          <div className={"contact-hero-text"}>Home</div>
          <img src={ArrowRight} alt={"arrow right"} />
          <div className={"contact-hero-text"}>Contact</div>
        </div>
      </div>
      <div className={"contact-details-container"}>
        <div className={"contact-icon-container"}>
          <div className={"contact-icon-circle"}>
            <img src={LocationImage} alt={"location"}/>
          </div>
          <div className={"contact-icon-title"}>Address</div>
          <div className={"contact-icon-text"}>4773 Jarvisville Road 48912</div>
        </div>
        <div className={"contact-icon-container"}>
          <div className={"contact-icon-circle"}>
            <img src={CallImage} alt={"call"}/>
          </div>
          <div className={"contact-icon-title"}>Phone</div>
          <div className={"contact-icon-text"}>517-219-9741</div>
        </div>
        <div className={"contact-icon-container"}>
          <div className={"contact-icon-circle"}>
            <img src={EmailImage} alt={"email"}/>
          </div>
          <div className={"contact-icon-title"}>Email</div>
          <div className={"contact-icon-text"}>help@upfield.com</div>
        </div>
      </div>
      <div className={"contact-form-container"}>
        <div className={"contact-form-title-container"}>
          <div className={"contact-form-title"}>Contact Form</div>
          <div className={"contact-form-caption"}>
            Upfield Analytics (UA) is the premier performance monitoring service
            for any organization that depends upon human performance to succeed.
          </div>
        </div>
        <div className={"contact-auth-container"}>
          <div className={"contact-auth-block"}>
            {learnMoreVisible ? (
              <>
                <LearnMoreAboutWellness
                  setLearnMoreVisible={setLearnMoreVisible}
                />
              </>
            ) : (
              <>
                <div className={"learn-more-sent-message"}>
                  <h2>Thanks for reach out! We'll be in touch soon.</h2>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
