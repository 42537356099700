import React, { useContext, useEffect, useState } from "react";
import "./UserProfile.css";
import { MediaSize } from "../../styles/MediaSizeProvider";
import Loading from "../loading/Loading";
import SideMenu from "../sideMenu/SideMenu";
import ProfileImageEdit from "../profileImageEdit/ProfileImageEdit";
import UpdateUser from "../updateUser/UpdateUser";
import { Mongo } from "../../auth/mongo-provider";
import service from "../../utils/service";

export default function EditTeam({ location }) {
  const { Desktop } = useContext(MediaSize);
  const [loading] = useState(null);
  const { userDB } = useContext(Mongo);
  const [user, setUser] = useState();
  const userId =
    location.state.userId !== undefined
      ? location.state.userId
      : null;

  useEffect(() => {
    if (!userId) {
      setUser(userDB);
    } else {
      service.getUserFromDBWithId(userId).then((foundUser) => {
        setUser(foundUser.data.userDB);
      });
    }
    // eslint-disable-next-line
  }, [userId]);

  return (
    <>
      <div className={"edit-team-main-container"}>
        {!loading ? (
          <>
            <Desktop>
              <div className={"home-side-menu-container"}>
                <SideMenu />
              </div>
            </Desktop>
            <div className={"edit-team-main-content-container"}>
              <h2>Edit Profile</h2>
              <div className={"profile-main-reports-container"}>
                <ProfileImageEdit user={user && user} />
                <UpdateUser user={user && user} />
              </div>
            </div>
          </>
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
}
