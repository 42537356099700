import React, { useState } from "react";
import service from "../../utils/service";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "yup-phone";
import "./NewWellnessReport.css";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useLocation } from "react-router-dom";

export default function NewWellnessReport({ userId }) {
  const [wellnessVisible, setWellnessVisible] = useState(true);
  const [injuryVisible, setInjuryVisible] = useState(false);
  const [wellnessMessage, setWellnessMessage] = useState(false);
  const [injuryMessage, setInjuryMessage] = useState(false);
  const [initialInjurySubmitted] = useState(false);
  const location = useLocation();
  // const userId = location && location.state && location.state.userId;

  const userFirstName =
    location && location.state && location.state.userFirstName;

  const customStyles = {
    menu: () => ({
      backgroundColor: "#ffffff",
      border: "1px solid #dbdbdb91",
      height: "46",
      // color: "#373737af",
      fontFamily: "Proxima Nova",
      fontSize: "18px",
    }),
  };

  const fatigueOptions = [
    { label: "Choose level of fatigue..", value: 0 },
    { label: "1. Very fresh", value: 1 },
    { label: "2. Really fresh", value: 2 },
    { label: "3. Fresh", value: 3 },
    { label: "4. Fresher than normal", value: 4 },
    { label: "5. Normal", value: 5 },
    { label: "6. A little more tired", value: 6 },
    { label: "7. More tired than normal", value: 7 },
    { label: "8. Significantly tired", value: 8 },
    { label: "9. Always tired", value: 9 },
    { label: "10. Sick", value: 10 },
  ];

  const stressOptions = [
    { label: "Choose level of stress..", value: 0 },
    { label: "1. Very Relaxed", value: 1 },
    { label: "2. Really relaxed", value: 2 },
    { label: "3. Relaxed", value: 3 },
    { label: "4. More relaxed than normal", value: 4 },
    { label: "5. Normal", value: 5 },
    { label: "6. A little stressed", value: 6 },
    { label: "7. Feeling stressed", value: 7 },
    { label: "8. Significantly stressed", value: 8 },
    { label: "9. Highly stressed", value: 9 },
    { label: "10. Very stressed", value: 10 },
  ];

  const sorenessOptions = [
    { label: "Choose level of soreness..", value: 0 },
    { label: "1. Feeling great", value: 1 },
    { label: "2. Feeling really good", value: 2 },
    {
      label: "3. Feeling good",
      value: 3,
    },
    {
      label: "4. A little better than normal",
      value: 4,
    },
    {
      label: "5.  Normal",
      value: 5,
    },
    { label: "6. Increased soreness global area", value: 6 },
    {
      label: "7. Increased soreness local area",
      value: 7,
    },
    { label: "8. Some sharp pain and soreness", value: 8 },
    { label: "9. Increased sharp pain", value: 9 },
    { label: "10. Hurt, can`t participate", value: 10 },
  ];

  const sleepOptions = [
    { label: "Choose hours of sleep..", value: 0 },
    { label: "1 hour", value: 1 },
    { label: "1.5 hours", value: 1.5 },
    { label: "2 hours", value: 2 },
    { label: "2.5 hours", value: 2.5 },
    { label: "3 hours", value: 3 },
    { label: "3.5 hours", value: 3.5 },
    { label: "4 hours", value: 4 },
    { label: "4.5 hours", value: 4.5 },
    { label: "5 hours", value: 5 },
    { label: "5.5 hours", value: 5.5 },
    { label: "6 hours", value: 6 },
    { label: "6.5 hours", value: 6.5 },
    { label: "7 hours", value: 7 },
    { label: "7.5 hours", value: 7.5 },
    { label: "8 hours", value: 8 },
    { label: "8.5 hours", value: 8.5 },
    { label: "9 hours", value: 9 },
    { label: "9.5 hours", value: 9.5 },
    { label: "10 hours", value: 10 },
    { label: "10.5 hours", value: 10.5 },
    { label: "11 hours", value: 11 },
    { label: "11.5 hours", value: 11.5 },
    { label: "12 hours", value: 12 },
    { label: "12.5 hours", value: 12.5 },
  ];

  const sideOptions = [
    { label: "Choose which side of body..", value: 0 },
    { label: "left", value: "left" },
    { label: "right", value: "right" },
    { label: "both", value: "both" },
  ];

  const regionOptions = [
    { label: "Choose region of the body..", value: 0 },
    { label: "Foot/Ankle", value: "Foot/Ankle" },
    { label: "Calf/Achilles", value: "Calf/Achilles" },
    { label: "Lower-leg/Shin", value: "Lower-leg/Shin" },
    { label: "Knee", value: "Knee" },
    {
      label: "Upper-leg/Front of Thigh",
      value: "Upper-leg/Front of Thigh",
    },
    {
      label: "Upper-leg/Back of Thigh",
      value: "Upper-leg/Back of Thigh",
    },
    { label: "Hip/Glute", value: "Hip/Glute" },
    { label: "Lower-back", value: "Lower-back" },
    { label: "Upper-back", value: "Upper-back" },
    { label: "Neck/Head", value: "Neck/Head" },
    { label: "Shoulder", value: "Shoulder" },
    { label: "Upper-arm", value: "Upper-arm" },
    { label: "Lower-arm", value: "Lower-arm" },
    { label: "Hand", value: "Hand" },
  ];

  // const painOptions = [
  //   { label: "Choose level of pain..", value: 0 },
  //   { label: "1 No Pain", value: 1 },
  //   { label: "2", value: 2 },
  //   { label: "3 Stiffness", value: 3 },
  //   { label: "4", value: 4 },
  //   { label: "5", value: 5 },
  //   { label: "6", value: 6 },
  //   { label: "7", value: 7 },
  //   { label: "8", value: 8 },
  //   { label: "9", value: 9 },
  //   { label: "10 Extreme Pain", value: 10 },
  // ];


  const painOptions = [
    { label: "Choose level of pain..", value: 0 },
    {
      label: "1. I just need to warm up to feel better.",
      value: "1. I just need to warm up to feel better.",
    },
    {
      label: "2. Movement eliminates pain.",
      value: "2. Certain movements make pain worse.",
    },
    {
      label: "3. Movement decreases pain level.",
      value: "3. Movement decreases pain level.",
    },
    {
      label: "4. Certain movements make pain worse.",
      value: "4. Certain movements make pain worse.",
    },
    {
      label: "5. Significantly limits movement; cannot perform.",
      value: "5. Significantly limits movement; cannot perform.",
    },
  ];



  return (
    <>
      <div className={"wellness-report-form-main-container"}>
        {wellnessVisible ? (
          <>
            {userFirstName && <h3>{userFirstName}'s Wellness</h3>}
            <Formik
              initialValues={{
                stress: 0,
                fatigue: 0,
                soreness: 0,
                sleep: 0,
              }}
              validationSchema={Yup.object().shape({
                fatigue: Yup.number().min(1, "Required").required("Required"),
                stress: Yup.number().min(1, "Required").required("Required"),
                soreness: Yup.number().min(1, "Required").required("Required"),
                sleep: Yup.number().min(1, "Required").required("Required"),
                hrv: Yup.number(),
                rhr: Yup.number(),
              })}
              onSubmit={async (values, { resetForm }) => {
                // service call to update user
                await service
                  .newPreSessionReport({ ...values, user: userId })
                  .then((response) => {});
                if (values.soreness > 5) {
                  setInjuryVisible(true);
                  window.scroll({ top: 0, behavior: "smooth" });
                } else {
                  setWellnessMessage(true);
                  resetForm();
                  window.scroll({ top: 0, behavior: "smooth" });
                }
                setWellnessVisible(false);
              }}
            >
              {({ errors, touched, setFieldValue }) => (
                <div className={"wellness-report-form-container"}>
                  <Form>
                    <div
                      className={"wellness-report-wellness-form-input-field"}
                    >
                      <div className={"wellness-report-field-header-container"}>
                        <h2>1. Scroll to your feeling of fatigue right now.</h2>
                        {errors.fatigue && touched.fatigue && (
                          <h3 className={"wellness-report-form-error-message"}>
                            * {errors.fatigue}
                          </h3>
                        )}
                      </div>
                      <Select
                        as="select"
                        name="fatigue"
                        className={"wellness-form-input-field"}
                        onChange={(e) => {
                          setFieldValue("fatigue", parseInt(e.value));
                        }}
                        styles={customStyles}
                        options={fatigueOptions}
                      />
                    </div>

                    <div
                      className={"wellness-report-wellness-form-input-field"}
                    >
                      <div className={"wellness-report-field-header-container"}>
                        <h2>2. Scroll to your feeling of stress right now.</h2>
                        {errors.stress && touched.stress && (
                          <h3 className={"wellness-report-form-error-message"}>
                            * {errors.stress}
                          </h3>
                        )}
                      </div>
                      <Select
                        as="select"
                        name="stress"
                        className={"wellness-form-input-field"}
                        onChange={(e) => {
                          setFieldValue("stress", parseInt(e.value));
                        }}
                        styles={customStyles}
                        options={stressOptions}
                      />
                    </div>

                    <div
                      className={"wellness-report-wellness-form-input-field"}
                    >
                      <div className={"wellness-report-field-header-container"}>
                        <h2>
                          3. Scroll to your feeling of muscle soreness right
                          now.
                        </h2>
                        {errors.soreness && touched.soreness && (
                          <h3 className={"wellness-report-form-error-message"}>
                            * {errors.soreness}
                          </h3>
                        )}
                      </div>
                      <Select
                        as="select"
                        name="soreness"
                        className={"wellness-form-input-field"}
                        onChange={(e) => {
                          setFieldValue("soreness", parseInt(e.value));
                        }}
                        styles={customStyles}
                        options={sorenessOptions}
                      />
                    </div>

                    <div
                      className={"wellness-report-wellness-form-input-field"}
                    >
                      <div className={"wellness-report-field-header-container"}>
                        <h2>
                          4. How many hours of sleep did you get last night?
                        </h2>
                        {errors.sleep && touched.sleep && (
                          <h3 className={"wellness-report-form-error-message"}>
                            * {errors.sleep}
                          </h3>
                        )}
                      </div>
                      <Select
                        as="select"
                        name="sleep"
                        className={"wellness-form-input-field"}
                        onChange={(e) => {
                          setFieldValue("sleep", parseInt(e.value));
                        }}
                        styles={customStyles}
                        options={sleepOptions}
                      />
                    </div>

                    <div
                      className={"wellness-report-wellness-form-input-field"}
                    >
                      <div className={"wellness-report-field-header-container"}>
                        <h2>5. What is your current resting heart rate?</h2>
                        {errors.rhr && touched.rhr && (
                          <h3 className={"wellness-report-form-error-message"}>
                            * {errors.rhr}
                          </h3>
                        )}
                      </div>
                      <Field
                        name="rhr"
                        type="number"
                        placeholder="rhr"
                        className={"wellness-form-input-field-number"}
                        onChange={(e) => {
                          setFieldValue("rhr", e.target.value);
                        }}
                      />
                    </div>

                    <div
                      className={"wellness-report-wellness-form-input-field"}
                    >
                      <div className={"wellness-report-field-header-container"}>
                        <h2>6. What is your current HRV?</h2>
                        {errors.hrv && touched.hrv && (
                          <h3 className={"wellness-report-form-error-message"}>
                            * {errors.hrv}
                          </h3>
                        )}
                      </div>
                      <Field
                        name="hrv"
                        placeholder="hrv"
                        className={"wellness-form-input-field-number"}
                        onChange={(e) => {
                          setFieldValue("hrv", e.target.value);
                        }}
                      />
                    </div>

                    <button
                      type="submit"
                      className={"update-form-submit-button"}
                    >
                      Submit
                    </button>
                  </Form>
                </div>
              )}
            </Formik>
          </>
        ) : (
          <>
            {wellnessMessage && (
              <>
                <h2>Wellness Submitted!</h2>
                <Link
                  to={{
                    pathname: `/wellness-reports/${userId}`,
                    state: {
                      userId: userId,
                      update: true,
                    },
                  }}
                >
                  See Reports
                </Link>
              </>
            )}
          </>
        )}

        {/* --------------- Injury Report ------------------ */}

        {injuryVisible ? (
          <Formik
            initialValues={{
              side: 0,
              region: 0,
              pain: 0,
              notes: "",
            }}
            validationSchema={Yup.object().shape({
              side: Yup.string().required("Required"),
              region: Yup.string().required("Required"),
              // pain: Yup.number()
              //   .min(1, "Please select your level of pain")
              //   .required("Please select hours of pain"),
              pain: Yup.string().required("Required"),
            })}
            onSubmit={async (values, { resetForm }) => {
              // service call to update user
              await service.newInjuryReport({ ...values, user: userId });
              setInjuryVisible(false);
              setInjuryMessage(true);
              resetForm();

              // setInitialInjurySubmitted(true);
              window.scroll({ top: 0, behavior: "smooth" });
            }}
          >
            {({ errors, touched, setFieldValue }) => (
              <div className={"wellness-report-form-container"}>
                <Form>
                  <div className={"wellness-report-wellness-form-input-field"}>
                    <div className={"wellness-report-field-header-container"}>
                      <h2>
                        {" "}
                        1. What side of your body are you experienceing pain or
                        soreness?
                      </h2>
                      {errors.side && touched.side && (
                        <h3 className={"wellness-report-form-error-message"}>
                          * {errors.side}
                        </h3>
                      )}
                    </div>
                    <Select
                      as="select"
                      name="side"
                      className={"wellness-form-input-field"}
                      styles={customStyles}
                      options={sideOptions}
                      onChange={(e) => {
                        setFieldValue("side", e.value);
                      }}
                    />
                  </div>

                  <div className={"wellness-report-wellness-form-input-field"}>
                    <div className={"wellness-report-field-header-container"}>
                      <h2>
                        2. Choose what best describes the region of the body
                        where you are experiencing pain or soreness.
                      </h2>
                      {errors.region && touched.region && (
                        <h3 className={"wellness-report-form-error-message"}>
                          * {errors.region}
                        </h3>
                      )}
                    </div>
                    <Select
                      as="select"
                      name="region"
                      className={"wellness-form-input-field"}
                      styles={customStyles}
                      options={regionOptions}
                      onChange={(e) => {
                        setFieldValue("region", e.value);
                      }}
                    />
                  </div>

                  <div className={"wellness-report-wellness-form-input-field"}>
                    <div className={"wellness-report-field-header-container"}>
                      <h2>
                        3. What is your current level of pain associated with
                        this region?
                      </h2>
                      {errors.pain && touched.pain && (
                        <h3 className={"wellness-report-form-error-message"}>
                          * {errors.pain}
                        </h3>
                      )}
                    </div>
                    <Select
                      as="select"
                      name="pain"
                      className={"wellness-form-input-field"}
                      // onChange={(e) => {
                      //   setFieldValue("pain", parseInt(e.value));
                      // }}
                      onChange={(e) => {
                        setFieldValue("pain", e.value);
                      }}
                      styles={customStyles}
                      options={painOptions}
                    />
                  </div>

                  <div className={"wellness-report-wellness-form-input-field"}>
                    <div className={"wellness-report-field-header-container"}>
                      <h2>
                        4. Please provide any details specific to your pain or
                        sorness.
                      </h2>
                    </div>
                    <Field
                      name="notes"
                      type="text"
                      placeholder="notes"
                      className={"wellness-form-input-field-notes"}
                    />
                  </div>

                  {!initialInjurySubmitted ? (
                    <button
                      type="submit"
                      className={"update-form-submit-button"}
                    >
                      Submit
                    </button>
                  ) : (
                    <>
                      <button
                        type="submit"
                        className={"update-form-submit-button-next"}
                      >
                        Submit
                      </button>
                      <button
                        className={"update-form-submit-button-done"}
                        onClick={() => {
                          setInjuryVisible(true);
                          setInjuryVisible(false);
                          setInjuryMessage(true);
                        }}
                      >
                        Done
                      </button>
                    </>
                  )}
                </Form>
              </div>
            )}
          </Formik>
        ) : (
          <>
            {injuryMessage && (
              <>
                {window.scroll({ top: 0, behavior: "smooth" })}
                <h2>Wellness Submitted!</h2>
                <Link
                  to={{
                    pathname: `/wellness-reports/${userId}`,
                    state: {
                      userId: userId,
                      update: true,
                    },
                  }}
                >
                  See Reports
                </Link>
              </>
            )}
          </>
        )}
      </div>
      {/* </div> */}
    </>
  );
}
