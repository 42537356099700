import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./UserExertionChartContainer.css";
import DateRangeSliderUserWellness from "../sliderUserWellness/DateRangeSliderUserWellness";
import { MediaSize } from "../../styles/MediaSizeProvider";
import SideMenu from "../sideMenu/SideMenu";
import PlusIcon from "../../images/plus-sign.png";
import WellnessChart from "../wellnessChart/WellnessChart";
// import { Mongo } from "../../auth/mongo-provider";
import service from "../../utils/service";
import { DateTime } from "luxon";
import Loading from "../loading/Loading";
import { useLocation } from "react-router-dom";

export default function UserExertionChartContainer() {
  const [targetChartRange, setTargetChartRange] = useState(5);
  const [userFirstName, setUserFirstName] = useState();
  const { Desktop } = useContext(MediaSize);
  let history = useHistory();

  // const { userDB } = useContext(Mongo);  // we want to pass userDB instead of getting from context
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState();

  const location = useLocation();
  const userId = location && location.state && location.state.userId;

  window.scroll({ top: 0, behavior: "smooth" });

  useEffect(() => {
    userId &&
      service.getUserFromDBWithId(userId).then((foundUser) => {
        console.log("found user", foundUser.data);

        setChartData(foundUser.data.userDB.exertion);
        setUserFirstName(foundUser.data.userDB.firstName);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [userId]);

  // modify date format
  const newArray =
    chartData &&
    chartData.map((obj) => ({
      ...obj,
      date: DateTime.fromISO(obj.createdAt).toLocaleString({
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      }),
    }));

  // get data in arrays for chart
  const date = newArray && newArray.map((obs) => obs.date).reverse();
  const overall = newArray && newArray.map((obs) => obs.overall).reverse();
  const legs = newArray && newArray.map((obs) => obs.legs).reverse();
  const lungs = newArray && newArray.map((obs) => obs.lungs).reverse();
  const cognitive = newArray && newArray.map((obs) => obs.cognitive).reverse();

  return (
    <div className={"wellness-chart-main-container"}>
      {!loading ? (
        <>
          <Desktop>
            <div className={"wellness-chart-side-menu-container"}>
              <SideMenu />
            </div>
          </Desktop>
          <div className={"wellness-chart-main-content-container"}>
            <div className={"wellness-chart-main-content-header"}>
              <h2>{userFirstName && userFirstName}'s Exertion Chart</h2>

              <div
                className={"home-main-content-header-navigation-button"}
                onClick={() => {
                  history.push({
                    pathname: `/new-exertion/${userId}`,
                    state: {
                      userId: userId,
                      userFirstName: userFirstName,
                      update: true,
                    },
                  });
                }}
              >
                <img src={PlusIcon} alt="new" />
                <p>New Report</p>
              </div>
            </div>
            <div className={"wellness-report-slider"}>
              <p>Select Range</p>
              <DateRangeSliderUserWellness
                setTargetChartRange={setTargetChartRange}
              />
            </div>

            <div className={"wellness-chart-container"}>
              <div className={"wellness-chart-block"}>
                <h2>Overall</h2>
                <WellnessChart
                  date={date && date.slice(0, targetChartRange).reverse()}
                  variable={overall && overall.slice(0, targetChartRange).reverse()}
                  label={"overall exertion"}
                />
              </div>
              <div className={"wellness-chart-block"}>
                <h2>Legs</h2>
                <WellnessChart
                  date={date && date.slice(0, targetChartRange).reverse()}
                  variable={
                    legs && legs.slice(0, targetChartRange).reverse()
                  }
                  label={"percieved exertion on legs"}
                />
              </div>

              <div className={"wellness-chart-block"}>
                <h2>Lungs</h2>
                <WellnessChart
                  date={date && date.slice(0, targetChartRange).reverse()}
                  variable={
                    lungs && lungs.slice(0, targetChartRange).reverse()
                  }
                  label={"percieved exertion on lungs"}
                />
              </div>

              <div className={"wellness-chart-block"}>
                <h2>Cognitive</h2>
                <WellnessChart
                  date={date && date.slice(0, targetChartRange).reverse()}
                  variable={
                    cognitive && cognitive.slice(0, targetChartRange).reverse()
                  }
                  label={"percieved cognitive exertion"}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
}
