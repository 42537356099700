import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import "./NavBarAccountMenu.css";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Mongo } from "../../auth/mongo-provider";
import Arrow from "../../images/down-arrow.png";
import Logout from "../auth0/logoutButton";

export default function NavBarAccountMenu() {
  const { userDB } = useContext(Mongo);
  const { isAuthenticated } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {isAuthenticated && userDB && userDB.onboardingComplete && (
        <div>
          <div className={"menu-title"}>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              className={"navbar-user-menu"}
              style={{ textTransform: "none" }}
            >
              <p id={"navbar-first-name"}>{userDB.firstName}</p>
              <img src={Arrow} alt="Upfield Analytics" />
            </Button>
          </div>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>
              <Link
                to={`/profile/${userDB._id}`}
                className={"navbar-user-menu-item"}
              >
                Profile
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link
                to={`/account/${userDB._id}`}
                className={"navbar-user-menu-item"}
              >
                My Account
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link
                to={`/memberships/${userDB._id}`}
                className={"navbar-user-menu-item"}
              >
                Create/Mange Teams
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link
                to={{
                  pathname:
                    "https://upfield-analytics.atlassian.net/servicedesk/customer/portals",
                }}
                className={"navbar-user-menu-item"}
                target="_blank"
              >
                Support
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Logout />
            </MenuItem>
          </Menu>
        </div>
      )}
    </>
  );
}
