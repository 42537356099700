import axios from "axios";

// create a new instance of axios for which all the routes are pointing to the baseURL
// withCredentials allows us to convey cookie information from and to the server
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL || "http://localhost:5050",
  withCredentials: true,
});

const service = {
  // learn more
  learnMoreAboutWellness: async (form) =>
    await axiosInstance.post("/learn-more/wellness", form),
  // auth CRUD operations
  // signup: async (form) => await axiosInstance.post("/auth/signup", form),
  // login: async (form) => await axiosInstance.post("/auth/login", form),
  // logout: async () => await axiosInstance.get("/auth/logout"),
  // isAuthenticated: async () => await axiosInstance.get("/auth/isAuthenticated"),

  // manage user
  createUserFromAuth0: async (form) =>
    await axiosInstance.post("/auth/create", form),
  getUserFromDB: async (email) => await axiosInstance.get(`/auth/${email}`),
  getUserFromDBWithId: async (userId) =>
    await axiosInstance.get(`/auth/id/${userId}`),
  updateUserDB: async (form, userId) =>
    await axiosInstance.post(`/auth/update/${userId}`, form),
  updateUserProfileImage: async (form, userId) =>
    await axiosInstance.post(`/auth/update/profile-image/${userId}`, form),
  removeUserProfileImage: async (userId) =>
    await axiosInstance.post(`/auth/remove-profile-image/${userId}`),

  // wellness
  newPreSessionReport: async (form) =>
    await axiosInstance.post("/reports/pre-session/new", form),
  newInjuryReport: async (form) =>
    await axiosInstance.post("/reports/injury/new", form),

  // exersion reports
  newExertionReport: async (form) =>
    await axiosInstance.post("/reports/exertion/new", form),

  // member email invites and team membership
  inviteUserToTeam: async (form) =>
    await axiosInstance.post("/email/member-invite", form),
  removeFromTeamInvite: async (form) =>
    await axiosInstance.post("/email/remove-member-invite", form),
  resendTeamInvite: async (form) =>
    await axiosInstance.post("/email/resend-member-invite", form),
  acceptTeamInvite: async (form) =>
    await axiosInstance.post("/email/accept-member-invite", form),
  rejectTeamInvite: async (form) =>
    await axiosInstance.post("/email/reject-member-invite", form),
  removeMyselfFromTeam: async (form) =>
    await axiosInstance.post("/email/remove-myself-from-team", form),
  removeMemberFromTeam: async (form) =>
    await axiosInstance.post("/email/remove-member-from-team", form),
  sendWellnessReminder: async (form) =>
    await axiosInstance.post("/email/reminder", form),

  // staff email invites and team membership
  inviteStaffToTeam: async (form) =>
    await axiosInstance.post("/email/staff-invite", form),
  removeStaffInvite: async (form) =>
    await axiosInstance.post("/email/remove-staff-invite", form),
  resendStaffInvite: async (form) =>
    await axiosInstance.post("/email/resend-staff-invite", form),
  acceptStaffInvite: async (form) =>
    await axiosInstance.post("/email/accept-staff-invite", form),
  rejectStaffInvite: async (form) =>
    await axiosInstance.post("/email/reject-staff-invite", form),
  removeStaffFromTeam: async (form) =>
    await axiosInstance.post("/email/remove-staff-from-team", form),

  // teams
  createTeam: async (form) =>
    await axiosInstance.post("/team/create-team", form),
  updateTeamInfo: async (form) =>
    await axiosInstance.post("/team/update-team", form),
  getTeamDetails: async (teamId) => await axiosInstance.get(`/team/${teamId}`),
  updateTeamProfileImage: async (form, teamId) =>
    await axiosInstance.post(`/team/update/profile-image/${teamId}`, form),
  removeTeamProfileImage: async (teamId) =>
    await axiosInstance.post(`/team/remove-profile-image/${teamId}`),
  deleteTeam: async (form) =>
    await axiosInstance.post("/team/delete-team", form),

  // athlete CRUD
  createAthlete: async (form) =>
    await axiosInstance.post("/admin/create-athlete", form),
  getTeams: async (userId) => await axiosInstance.get(`/admin/teams/${userId}`),
  addAthleteToTeam: async (teamId, athleteId) =>
    await axiosInstance.post("/admin/teams/add-athlete", { teamId, athleteId }),
  removeAthleteFromTeam: async (teamId, athleteId) =>
    await axiosInstance.post("/admin/teams/remove-athlete", {
      teamId,
      athleteId,
    }),
  addStaffToTeam: async (teamId, athleteId) =>
    await axiosInstance.post("/admin/teams/add-staff", { teamId, athleteId }),
  // removeStaffFromTeam: async (teamId, athleteId) =>
  //   await axiosInstance.post("/admin/teams/remove-staff", {
  //     teamId,
  //     athleteId,
  //   }),
  getAdminAthletes: async () => await axiosInstance.get("/admin/athletes"),
  getTeamAthletes: async (teamId) =>
    await axiosInstance.get(`/team/athletes/${teamId}`),
  getAdminAthleteDetails: async (athleteId) =>
    await axiosInstance.get(`/admin/athletes/${athleteId}`),
  getAdminTeamDetails: async (teamId) =>
    await axiosInstance.get(`/admin/teams/${teamId}`),
  uploadStatSportCSV: async (report) =>
    await axiosInstance.post("/reports/stat-sports/upload", report),
  uploadTitanCSV: async (report) =>
    await axiosInstance.post("/reports/titan/upload", report),

  // stripe
  createStripeCustomer: async (email) =>
    await axiosInstance.post("/stripe/create-customer", email),
  createIntentToBuy: async (priceId) =>
    await axiosInstance.post("/stripe/create-payment-intent", priceId),
  createSubscription: async (data) =>
    await axiosInstance.post("/stripe/create-subscription", data),
  updateSubscriptionPaymentMethod: async (data) =>
    await axiosInstance.post("/stripe/update-payment-method", data),
  getPrices: async () => await axiosInstance.get("/stripe/get-prices"),
  createQuote: async (quote) =>
    await axiosInstance.post("/stripe/create-quote", quote),
  getSubscriptionInfo: async (subId) =>
    await axiosInstance.post(
      "/stripe/retrieve-subscription-information",
      subId
    ),
  cancelSubscription: async (subId) =>
    await axiosInstance.post("/stripe/cancel-subscription", subId),
};

export default service;
