import React, { useContext } from "react";
import "./NavBar.css";
import NavBarAccountMenu from "../navBarAccountMenu/NavBarAccountMenu";
// import NavBarAdminMenu from "../navBarAdminMenu/NavBarAdminMenu";
import { useAuth0 } from "@auth0/auth0-react";
import { Mongo } from "../../auth/mongo-provider";
import { Link } from "react-router-dom";
import AuthenticationButton from "../auth0/authenticationButton";
import SignUpButton from "../auth0/signUpButton";
import Logo from "../../images/UA-Logo.png";
import { Image, Transformation, CloudinaryContext } from "cloudinary-react";
import { MediaSize } from "../../styles/MediaSizeProvider";
import NavBarDrawerMenu from "../navBarDrawerMenu/NavBarDrawerMenu";
import { useHistory } from "react-router-dom";

export default function NavBar() {
  const { onBoarded, profileImageId, userDB } = useContext(Mongo);
  const { isAuthenticated } = useAuth0();
  const { Desktop, Tablet, Mobile } = useContext(MediaSize);
  let history = useHistory();

  const renderDesktopNavBar = () => {
    return (
      <>
        <ul className={"navbar-ul"}>
          {/* navbar open to public */}

          {isAuthenticated && (
            <>
              <div className={"navbar-icon-block"}>
                <img src={Logo} alt="Upfield Analytics" />
              </div>
            </>
          )}

          {!isAuthenticated && (
            <>
              {/* <div className={"external-nav"}> */}

              <div className={"navbar-items"}>
                <Link className={"nav-bar-links"} to={"/"}>
                  Home
                </Link>
              </div>
              <div className={"navbar-items"}>
                <Link className={"nav-bar-links"} to={"/wellness"}>
                  How it Works
                </Link>
              </div>
              <div className={"navbar-items"}>
                <Link className={"nav-bar-links"} to={"/pricing"}>
                  Pricing/Subsription
                </Link>
              </div>
              {/* <div className={"navbar-items"}>
                <Link className={"nav-bar-links"} to={"/about"}>
                  About
                </Link>
              </div> */}
              <div className={"navbar-items"}>
                <Link className={"nav-bar-links"} to={"/contact"}>
                  Contact
                </Link>
              </div>
              <div className={"navbar-items"}>
                <AuthenticationButton />
                <SignUpButton />
              </div>
              {/* </div> */}
            </>
          )}

          {/* onboarding navbar */}
          {/* {isAuthenticated && !onBoarded && (
            <>
              <div className={"navbar-items"}>
                <h1>Welcome! Create your profile to get started.</h1>
              </div>
            </>
          )} */}

          {/* authenticated user that has completed onboarding */}
          {isAuthenticated && (
            <>
              {/* admin navbar */}
              {/* <div className={"navbar-items"}>
                {userDB && userDB.admin && <NavBarAdminMenu />}
              </div> */}
              <div className={"internal-navbar"}>
                <div className={"navbar-items"}>
                  {userDB && userDB.stripeSubscriptionId ? (
                    <>
                      <Link className={"nav-bar-links"} to={"/teams"}>
                        Create/Mange Teams
                      </Link>

                      <div className={"navbar-items"}>
                        <Link className={"nav-bar-links"} to={"/home"}>
                          My Wellness
                        </Link>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={"navbar-items"}>
                        <Link className={"nav-bar-links"} to={"/wellness"}>
                          How it Works
                        </Link>
                      </div>
                      <div className={"navbar-items"}>
                        <Link className={"nav-bar-links"} to={"/pricing"}>
                          Pricing/Subsription
                        </Link>
                      </div>
                    </>
                  )}
                </div>
                {/* <div className={"navbar-items"}>
                  <Link
                    className={"nav-bar-links"}
                    to={{
                      pathname: `/wellness-reports/${userDB && userDB._id}`,
                      state: {
                        userId: userDB && userDB._id,
                        update: true,
                      },
                    }}
                  >
                    My Wellness
                  </Link>
                </div> */}
                {!onBoarded && (
                  <div className={"navbar-items"}>
                    <AuthenticationButton />
                  </div>
                )}
                {/* invites */}
                <div className={"navbar-items"}>
                  {onBoarded && (
                    <>
                      <CloudinaryContext
                        cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
                      >
                        <Image
                          publicId={profileImageId}
                          format="png"
                          onClick={() => {
                            history.push(`/memberships/${userDB._id}`);
                          }}
                        >
                          <Transformation
                            gravity="face"
                            width="50"
                            height="50"
                            radius="max"
                            zoom="0.7"
                            crop="fill"
                          />
                        </Image>
                      </CloudinaryContext>
                      <p>
                        {userDB &&
                          userDB.teamInvitesRecieved.length > 0 &&
                          userDB.teamInvitesRecieved.length}
                      </p>
                    </>
                  )}
                </div>
                {onBoarded && (
                  <div className={"navbar-items"}>
                    <NavBarAccountMenu />
                  </div>
                )}
              </div>
            </>
          )}
        </ul>
      </>
    );
  };

  const renderMobileNavBar = () => {
    return (
      <>
        <div className={"navbar-container-mobile"}>
          <div className={"navbar-icon-block"}>
            <img src={Logo} alt="Upfield Analytics" />
          </div>
          <NavBarDrawerMenu />
        </div>
      </>
    );
  };

  return (
    <>
      <Desktop>
        <div className={"navbar"}>{renderDesktopNavBar()}</div>
      </Desktop>
      <Tablet>{renderMobileNavBar()}</Tablet>
      <Mobile>{renderMobileNavBar()}</Mobile>
    </>
  );
}
