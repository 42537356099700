import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";

const sliderBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const StyledSlider = withStyles({
  root: {
    color: "#3880ff",
    height: 2,
    padding: "15px 0",
    width: 250,
    marginLeft: 10,
  },
  thumb: {
    height: 20,
    width: 21,
    backgroundColor: "#171b1e",
    boxShadow: sliderBoxShadow,
    marginTop: -8,
    marginLeft: -14,
    "&:focus, &:hover, &$active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: sliderBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 12px)",
    top: -12,
    "& *": {
      background: "transparent",
      color: "#171b1e",
    },
  },
  track: {
    height: 9,
    color: "#171b1e",
  },
  rail: {
    height: 9,
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  // mark: {
  //   backgroundColor: "blue",
  //   height: 4,
  //   width: 1,
  //   marginTop: -3,
  // },
  // markActive: {
  //   opacity: 1,
  //   backgroundColor: "currentColor",
  // },
})(Slider);

const marks = [
  {
    value: 3,
  },
  {
    value: 5,
  },
  {
    value: 7,
    // label: "7 reports",
  },
  {
    value: 14,
    // label: "14",
  },
];

function valuetext(value) {
  return `${value} reports`;
}

export default function DateRangeSliderUserWellness({ setTargetChartRange }) {
  const handleChangeRange = (e, value) => {
    setTargetChartRange(value);
  };

  return (
    <div>
      <StyledSlider
        defaultValue={5}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        step={null}
        min={3}
        max={14}
        marks={marks}
        onChange={(e, value) => handleChangeRange(e, value)}
      />
    </div>
  );
}
