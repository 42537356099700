import React from "react";
import "./WellnessReportCard.css";
import { DateTime } from "luxon";
import FatigueIcon from "../../images/wellness-card-fatigue-icon.png";
import SleepIcon from "../../images/wellness-card-sleep-icon.png";
import SorenessIcon from "../../images/wellness-card-soreness-icon.png";
import StressIcon from "../../images/wellness-card-stress-icon.png";
import ChartIcon from "../../images/responsive-chart-icon.png";

export default function WellnessReportCard({ report, i }) {
  // some condition to change the background color
  const handleStyleOnCondition = (value) => {
    if (
      value.stress <= 5 &&
      value.fatigue <= 5 &&
      value.sleep > 8 &&
      value.soreness <= 5 
    ) {
      return {
        backgroundColor: "#0cc38a",
      };
    } else if (
      (value.stress > 5 && value.stress) <= 7 &&
      (value.fatigue > 5 && value.fatigue) <= 7 &&
      value.sleep > 5 &&
      value.sleep <= 8 &&
      value.soreness > 5 &&
      value.soreness <= 7
    ) {
      return {
        backgroundColor: "#fcb757",
      };
    } else {
      return {
        backgroundColor: "#e45741",
      };
    }
  };

  return (
    <div
      className={"individual-wellness-report-card-background-color"}
      style={handleStyleOnCondition(report)}
    >
      <div className={"individual-wellness-report-card"} key={i}>
        <div className={"individual-wellness-report-card-date"}>
          <p>
            {" "}
            {DateTime.fromISO(report.createdAt).toLocaleString({
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            })}{" "}
          </p>
        </div>

        <div className={"individual-wellness-report-card-content-block"}>
          <div className={"individual-wellness-report-card-content"}>
            <img src={FatigueIcon} alt={"fatigue"} />
            <p>Fatigue: {report.fatigue}</p>
          </div>

          <div className={"individual-wellness-report-card-content"}>
            <img src={SleepIcon} alt={"sleep"} />
            <p>Sleep: {report.sleep}</p>
          </div>
        </div>

        <div className={"individual-wellness-report-card-content-block"}>
          <div className={"individual-wellness-report-card-content"}>
            <img src={SorenessIcon} alt={"soreness"} />
            <p>Soreness: {report.soreness}</p>
          </div>

          <div className={"individual-wellness-report-card-content"}>
            <img src={StressIcon} alt={"stress"} />
            <p>Stress: {report.stress}</p>
          </div>
        </div>
        <div className={"individual-wellness-report-card-content-block"}>
          <div className={"individual-wellness-report-card-content"}>
            <img src={ChartIcon} alt={"hrv"} />
            <p>HRV: {report.hrv}</p>
          </div>

          <div className={"individual-wellness-report-card-content"}>
            <img src={ChartIcon} alt={"rhr"} />
            <p>RHR: {report.rhr}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
