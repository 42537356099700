import React, { useContext, useEffect, useState } from "react";
import "./EditTeam.css";
import Loading from "../loading/Loading";
import SideMenu from "../sideMenu/SideMenu";
import { MediaSize } from "../../styles/MediaSizeProvider";
import service from "../../utils/service";
import { Mongo } from "../../auth/mongo-provider";
import UpdateTeamInfo from "../updateTeamInfo/UpdateTeamInfo";

export default function EditTeam({ match }) {
  const { Desktop } = useContext(MediaSize);
  const [loading, setLoading] = useState(true);
  const teamId = match.params.teamId;
  const [teamDetails, setTeamDetails] = useState();
  const { userDB } = useContext(Mongo);

  useEffect(() => {
    teamId &&
      userDB &&
      service.getTeamDetails(teamId).then((response) => {
        setTeamDetails(response.data.teamDetails);
        setLoading(false);
      });

    // eslint-disable-next-line
  }, [setTeamDetails]);





  return (
    <>
      <div className={"edit-team-main-container"}>
        {!loading ? (
          <>
            <Desktop>
              <div className={"home-side-menu-container"}>
                <SideMenu />
              </div>
            </Desktop>
            <div className={"edit-team-main-content-container"}>
              <h2>{teamDetails.name} Team Profile</h2>

              <UpdateTeamInfo
                teamDetails={teamDetails}
                setTeamDetails={setTeamDetails}
                userId={userDB._id}
              />

            </div>
          </>
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
}
