import "../about/About.css"
export default function InfoCard({ image, title, description }) {
  return (
    <div className={"about-mission-card"}>
      <div className={"about-mission-card-inner"}>
        <img className={"about-mission-card-image"} src={image} alt={title} />
        <div className={"about-mission-card-title"}>{title}</div>
        <div className={"about-mission-card-caption"}>{description}</div>
      </div>
    </div>
  );
}
