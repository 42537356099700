import React from "react";
import "./Loading.css";

export default function Loading() {
    const handleRefresh = () => {
        return window.location.reload()
    }
  return (
    <>
      <div className={"loading-container"}>
        <div className={"loading-content"}>
          <h2>Loading...</h2>
          <button
            className={"loading-refresh-button"}
            onClick={handleRefresh}
          >
            Reload Page
          </button>
        </div>
      </div>
    </>
  );
}
