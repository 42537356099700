import React from "react";
import "./SearchAthletesAdmin.css";

export default function SearchAthletesAdmin(props) {
  const search = (e) => {
    props.executeSearch(e.target.value);
  };
  return (
    <div id="search-box-input">
      <input onChange={search} type="text" placeholder="Search" />
    </div>
  );
}
