import React, { useContext, useEffect, useState } from "react";
import { Mongo } from "../../auth/mongo-provider";
import "./TeamMemberDetails.css";
import service from "../../utils/service";
import Loading from "../loading/Loading";
import { MediaSize } from "../../styles/MediaSizeProvider";
import SideMenu from "../sideMenu/SideMenu";
import TeamMemberDetailsCard from "../teamMemberDetailsCard/TeamMemberDetailsCard";
import Pagination from "../pagination/Pagination";
import PlusIcon from "../../images/plus-sign.png";
import { useHistory } from "react-router-dom";
import Search from "../searchAthletesAdmin/SearchAthletesAdmin";

export default function TeamMemberDetails({ match }) {
  const { Desktop } = useContext(MediaSize);
  const { userDB } = useContext(Mongo);
  const [teamDetails, setTeamDetails] = useState();
  const [loading, setLoading] = useState(true);
  const teamId = match.params.teamId;
  let history = useHistory();
  const [visibleMembers, setVisibleMembers] = useState();
  const [teamMembers, setTeamMembers] = useState();

  useEffect(() => {
    teamId &&
      userDB &&
      service.getTeamDetails(teamId).then((response) => {
        setTeamDetails(response.data.teamDetails);
        setTeamMembers(response.data.teamDetails.athletes)
        setVisibleMembers(response.data.teamDetails.athletes);
        setLoading(false);
      });

    // eslint-disable-next-line
  }, [userDB, setTeamDetails, setVisibleMembers]);


 const filterResults = (searchTerm) => {
    const copy = [...teamMembers];
    const filteredResults = copy.filter((team) => {
      return Object.keys(team).some((key) => {
        return team[key]
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
    });
    setVisibleMembers([...filteredResults]);
  };

  return (
    <>
      <div className={"team-details-main-container"}>
        {!loading ? (
          <>
            <Desktop>
              <div className={"home-side-menu-container"}>
                <SideMenu />
              </div>
            </Desktop>
            <div className={"team-details-main-content-container-members"}>
              <div className={"team-details-main-content-header-container"}>
                <h2>{teamDetails.name} Members</h2>
                <div
                  className={"home-main-content-header-navigation-button"}
                  onClick={() => {
                    history.push(`/team-invite/${teamId}`);
                  }}
                >
                  <img src={PlusIcon} alt="create-team" />
                  <p>Invite Member</p>
                </div>
              </div>
              <div className={"serch-box-container"}>
                <Search executeSearch={filterResults} />
              </div>

              <div className={"team-details-main-reports-container"}>
                <div className={"team-details-card-colum-header"}>
                  <p>Name</p>

                  <Desktop>
                    <p>Email</p>
                    {/* <p>Last Report</p> */}
                  </Desktop>
                  <p>Report Status</p>

                  <p>Action</p>
                </div>
                <div className={"team-card-colum-header-line"}></div>

                {visibleMembers &&
                  visibleMembers.map((athlete, i) => {
                    return (
                      <>
                        <TeamMemberDetailsCard
                          athlete={athlete}
                          teamDetails={teamDetails}
                          setTeamDetails={setTeamDetails}
                          i={i}
                        />
                        <div className={"team-card-colum-header-line"}></div>
                      </>
                    );
                  })}
              </div>
              <Pagination />
            </div>
          </>
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
}
