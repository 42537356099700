import React, { useContext, useState } from "react";
import "./NewExertionReportContainer.css";
import { MediaSize } from "../../styles/MediaSizeProvider";
import Loading from "../loading/Loading";
import SideMenu from "../sideMenu/SideMenu";
import NewExersionReport from "../newExertionReport/NewExertionReport";

export default function NewExertionReportContainer({ match }) {
  const { Desktop } = useContext(MediaSize);
  const [loading] = useState(null);
  const userId = match && match.params.userId;
  console.log("userId", userId);

  return (
    <>
      <div className={"wellness-report-main-container"}>
        {!loading ? (
          <>
            <Desktop>
              <div className={"home-side-menu-container"}>
                <SideMenu />
              </div>
            </Desktop>
            <div className={"wellness-report-main-content-container"}>
              <h2>New Exertion Report</h2>

              <div className={"wellness-report-main-reports-container"}>
                <NewExersionReport userId={userId} />
              </div>
            </div>
          </>
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
}
