import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import { Mongo } from "../../auth/mongo-provider";
import { useAuth0 } from "@auth0/auth0-react";
import MenuIcon from "../../images/Menu_Icon.png";
import LogoutButton from "../auth0/logoutButton";
import AuthenticationButton from "../auth0/authenticationButton";
import SignUpButton from "../auth0/signUpButton";
import "./NavBarDrawerMenu.css";
import AvatarIcon from "../../images/responsive-avatar-icon.png";
import ChartIcon from "../../images/responsive-chart-icon.png";
import ReportIcon from "../../images/responsive-report-icon.png";
import SettingsIcon from "../../images/responsive-settings-icon.png";
import TeamIcon from "../../images/responsive-team-icon.png";
import MemberIcon from "../../images/account-details-total-teams.png";
import CheckIcon from "../../images/b.png";

export default function NavBarDrawerMenu() {
  const { isAuthenticated } = useAuth0();
  const { userDB, onBoarded } = useContext(Mongo);
  const [externalNavigation] = React.useState([
    {
      text: "Home",
      path: "/",
    },
    {
      text: "Wellness",
      path: "/wellness",
    },
    {
      text: "Pricing",
      path: "/pricing",
    },
    // {
    //   text: "About",
    //   path: "/about",
    // },
    {
      text: "Contact",
      path: "/contact",
    },
  ]);

  const [InternalNavigationSubscriber] = React.useState([
    {
      text: "My Wellness Chart",
      path: `/wellness-chart/${userDB && userDB._id}`,
      icon: ChartIcon,
    },
    {
      text: "My Wellness Reports",
      path: `/wellness-reports/${userDB && userDB._id}`,
      icon: ReportIcon,
    },

    {
      text: "Create/Manage Teams",
      path: "/teams",
      icon: TeamIcon,
    },
    {
      text: "My Team Memberships",
      path: `/memberships/${userDB && userDB._id}`,
      icon: MemberIcon,
    },
    // {
    //   text: "Exertion Chart",
    //   path: userDB && `/exertion-chart/${userDB._id}`,
    //   icon: ChartIcon,
    // },
    // {
    //   text: "Exertion Reports",
    //   path: `/exertion-reports/${userDB && userDB._id}`,
    //   icon: ReportIcon,
    // },
    {
      text: "My Account",
      path: `/account/${userDB && userDB._id}`,
      icon: AvatarIcon,
    },
    {
      text: "Profile",
      path: `/profile/${userDB && userDB._id}`,
      icon: SettingsIcon,
    },
  ]);

  const [InternalNavigationNonSubscriber] = React.useState([
    {
      text: "How it Works",
      path: "/wellness",
      icon: TeamIcon,
    },
    {
      text: "Pricing/Subscription",
      path: "/pricing",
      icon: TeamIcon,
    },
    {
      text: "My Wellness Chart",
      path: `/wellness-chart/${userDB && userDB._id}`,
      icon: ChartIcon,
    },
    {
      text: "My Wellness Reports",
      path: `/wellness-reports/${userDB && userDB._id}`,
      icon: ReportIcon,
    },
    {
      text: "My Team Memberships",
      path: `/memberships/${userDB && userDB._id}`,
      icon: TeamIcon,
    },
    // {
    //   text: "Exertion Chart",
    //   path: userDB && `/exertion-chart/${userDB._id}`,
    //   icon: ChartIcon,
    // },
    // {
    //   text: "Exertion Reports",
    //   path: `/exertion-reports/${userDB && userDB._id}`,
    //   icon: ReportIcon,
    // },
    {
      text: "Profile",
      path: `/profile/${userDB && userDB._id}`,
      icon: SettingsIcon,
    },
    {
      text: "My Account",
      path: `/account/${userDB && userDB._id}`,
      icon: AvatarIcon,
    },

  ]);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {!isAuthenticated && !onBoarded && !userDB && (
        <>
          <div className={"responsive-menu-auth-container"}>
            <SignUpButton />
            <AuthenticationButton />
          </div>
          <List>
            {externalNavigation.map((selection, index) => (
              <ListItem button key={selection.text}>
                <Link
                  className={"responsive-menu-text"}
                  to={{
                    pathname: selection.path,
                    state: {
                      userId: userDB && userDB._id,
                      update: true,
                    },
                  }}
                >
                  <ListItemText primary={selection.text} />
                </Link>
              </ListItem>
            ))}
            <ListItem>
              <div className={"responsive-menu-support-container"}>
                <a
                  href={
                    "https://upfield-analytics.atlassian.net/servicedesk/customer/portals"
                  }
                  className={"responsive-suport-text"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Support
                </a>
              </div>
            </ListItem>
          </List>
        </>
      )}
      {isAuthenticated && onBoarded && userDB.stripeSubscriptionId && (
        <>
          <List>
            {InternalNavigationSubscriber.map((selection, index) => (
              <ListItem button key={selection.text}>
                <img
                  className={"responsive-menu-icon"}
                  src={selection.icon}
                  alt={selection.text}
                />
                <Link
                  className={"responsive-menu-text"}
                  to={{
                    pathname: selection.path,
                    state: {
                      userId: userDB && userDB._id,
                      update: true,
                    },
                  }}
                >
                  <ListItemText primary={selection.text} />
                </Link>
              </ListItem>
            ))}
            <ListItem>
              <div className={"responsive-menu-support-container"}>
                <img
                  className={"responsive-menu-icon"}
                  src={CheckIcon}
                  alt={"Support"}
                />
                <a
                  href={
                    "https://upfield-analytics.atlassian.net/servicedesk/customer/portals"
                  }
                  className={"responsive-suport-text"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Support
                </a>
              </div>
            </ListItem>
            {userDB && userDB.admin && (
              <ListItem>
                <div className={"responsive-menu-support-container"}>
                  <img
                    className={"responsive-menu-icon"}
                    src={SettingsIcon}
                    alt={"Support"}
                  />
                  <Link
                    to={{
                      pathname: `/admin-teams/${userDB && userDB._id}`,
                      state: {
                        userId: userDB && userDB._id,
                        update: true,
                      },
                    }}
                    className={"responsive-suport-text"}
                  >
                    Admin Teams
                  </Link>
                </div>
              </ListItem>
            )}
            <div className={"responsive-menu-logout-container"}>
              <LogoutButton />
            </div>
          </List>
        </>
      )}

      {isAuthenticated && onBoarded && !userDB.stripeSubscriptionId && (
        <>
          <List>
            {InternalNavigationNonSubscriber.map((selection, index) => (
              <ListItem button key={selection.text}>
                <img
                  className={"responsive-menu-icon"}
                  src={selection.icon}
                  alt={selection.text}
                />
                <Link
                  className={"responsive-menu-text"}
                  to={{
                    pathname: selection.path,
                    state: {
                      userId: userDB && userDB._id,
                      update: true,
                    },
                  }}
                >
                  <ListItemText primary={selection.text} />
                </Link>
              </ListItem>
            ))}
            <ListItem>
              <div className={"responsive-menu-support-container"}>
                <img
                  className={"responsive-menu-icon"}
                  src={CheckIcon}
                  alt={"Support"}
                />
                <a
                  href={
                    "https://upfield-analytics.atlassian.net/servicedesk/customer/portals"
                  }
                  className={"responsive-suport-text"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Support
                </a>
              </div>
            </ListItem>
            {userDB && userDB.admin && (
              <ListItem>
                <div className={"responsive-menu-support-container"}>
                  <img
                    className={"responsive-menu-icon"}
                    src={SettingsIcon}
                    alt={"Support"}
                  />
                  <Link
                    to={{
                      pathname: `/admin-teams/${userDB && userDB._id}`,
                      state: {
                        userId: userDB && userDB._id,
                        update: true,
                      },
                    }}
                    className={"responsive-suport-text"}
                  >
                    Admin Teams
                  </Link>
                </div>
              </ListItem>
            )}
            <div className={"responsive-menu-logout-container"}>
              <LogoutButton />
            </div>
          </List>
        </>
      )}

      {isAuthenticated && !onBoarded && (
        <>
          <div className={"responsive-menu-onbarding-message"}>
            <h3>Complete onboarding to access internal navigation!</h3>
            <div>
              <AuthenticationButton />
            </div>
          </div>
        </>
      )}
    </Box>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <img
              className={"navbar-mobile-menu-icon"}
              src={MenuIcon}
              alt="Hamburger Menu"
            />
          </Button>

          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
