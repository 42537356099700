import React from "react";
import service from "../../utils/service";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "./Quote.css";

export default function Quote({
  history,
  memberCount,
  setMemberCount,
  displayMonthly,
  cost,
}) {
  const getPrideId = (monthlyTerm) => {
    // display monthly is a boolean: true === monthly
    if (monthlyTerm) {
      return process.env.REACT_APP_PRICE_ID_MONTH;
    } else {
      return process.env.REACT_APP_PRICE_ID_ANNUAL;
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          organization: "",
          email: "",
          quantity: memberCount,
        }}
        validationSchema={Yup.object({
          organization: Yup.string().required("Required"),
          email: Yup.string()
            .email("Please use a valid email address")
            .required("Required"),
        })}
        onSubmit={async (values, { resetForm }) => {
          console.log("values", values);
          const priceId = getPrideId(displayMonthly);
          console.log("priceid", priceId);
          await service.createQuote({ ...values, cost, priceId });
          resetForm();
          history.push("/wellness");
        }}
      >
        {({ errors, touched, setFieldValue }) => (
          <div className={"get-quote-form-container"}>
            <Form>
              <div className={"get-quote-form-text-input-container-main"}>
                <h2># Members {memberCount}</h2>
                <h2>
                  Cost ${cost} / {displayMonthly ? "month" : "year"}
                </h2>
                {/* <div className={"get-quote-form-text-input-container"}>
                  <label htmlFor="firstName">First</label>
                  <Field
                    name="firstName"
                    type="text"
                    placeholder="First"
                    className={"input-field"}
                  />
                </div>
                <div className={"get-quote-form-text-input-container"}>
                  <label htmlFor="lastName">Last</label>
                  <Field
                    label="lastName"
                    name="lastName"
                    type="text"
                    placeholder="lastName"
                    className={"input-field"}
                  />
                </div> */}
                <div className={"get-quote-form-text-input-container"}>
                  <label htmlFor="organization">Organization</label>
                  <Field
                    name="organization"
                    type="text"
                    placeholder="organization"
                    className={"input-field"}
                  />
                </div>
                {errors.organization && touched.organization && (
                  <h3 className={"get-quote-form-error-message"}>
                    {errors.organization}
                  </h3>
                )}
                <div className={"get-quote-form-text-input-container"}>
                  <label htmlFor="email">Email to send quote</label>
                  <Field
                    label="email"
                    name="email"
                    type="text"
                    placeholder="email"
                    className={"input-field"}
                  />
                </div>
                {errors.email && touched.email && (
                  <h3 className={"get-quote-form-error-message"}>
                    {errors.email}
                  </h3>
                )}
                <div className={"get-quote-form-text-input-container"}>
                  <label htmlFor="quantity"># Members</label>
                  <Field
                    label="quantity"
                    name="quantity"
                    type="number"
                    placeholder="quantity"
                    className={"input-field"}
                    value={memberCount}
                    onChange={(e) => {
                      console.log("e", e.target.value);
                      setMemberCount(e.target.value);
                      setFieldValue("quantity", e.target.value);
                    }}
                  />
                </div>
              <button type="submit" className={"submit-button"}>
                Get Quote
              </button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}
