import React, { useEffect, useState } from "react";
import "./StaffFlagReports.css";
import { Image, Transformation, CloudinaryContext } from "cloudinary-react";
import { DateTime, Interval } from "luxon";
// import { useLocation } from "react-router-dom";
import service from "../../utils/service";
import { useHistory } from "react-router-dom";
import ChartIcon from "../../images/responsive-chart-icon.png";
import ReportsIcon from "../../images/responsive-report-icon.png";
// import FlagReportTrendInjury from "../flagReportTrend/FlagReportTrendInjury";

export default function StaffFlagReports({ teamId }) {
  let history = useHistory();
  // const location = useLocation();
  // const teamId = location && location.state && location.state.teamId;
  // data state
  const [now] = useState(
    DateTime.now().set({ hour: 0, minutes: 0, seconds: 0, milliseconds: 0 })
  );

  const [teamAthletes, setTeamAthletes] = useState();

  // flag state
  const [flaggedPreSessionLast24, setFlaggedPreSessionLast24] = useState([]);
  const [flaggedHighStress, setFlaggedHighStress] = useState();
  const [flaggedHighFatigue, setFlaggedHighFatigue] = useState();
  const [flaggedHighSoreness, setFlaggedHighSoreness] = useState();
  const [flaggedLowSleep, setFlaggedLowSleep] = useState();
  const [flaggedInjuryLast24, setFlaggedInjuryLast24] = useState();
  const [reportsToCheckForFlags] = useState([
    // ------------------- eventually don't hard-code
    {
      report: "injuryReports",
    },
    {
      report: "preSessionWellness",
    },
    {
      report: "postSessionWellness",
    },
    {
      report: "stat_sports",
    },
    {
      report: "titan",
    },
    {
      report: "beyond_pulse",
    },
    {
      report: "playertech",
    },
    {
      report: "hrv_sensor",
    },
    {
      report: "force-frame",
    },
    {
      report: "nordboard",
    },
    {
      report: "sparta_science",
    },
  ]);

  const handleClickMemberChart = (userId) => {
    history.push({
      pathname: `/wellness-chart/${userId}`,
      state: {
        userId: userId,
        update: true,
      },
    });
  };

  const handleClickMemberReports = (userId) => {
    history.push({
      pathname: `/wellness-reports/${userId}`,
      state: {
        userId: userId,
        update: true,
      },
    });
  };

  const getLastReportDateAndFormat = (athlete, report, label) => {
    const lastReportDateDisplay =
      athlete[report] && athlete[report].length > 0
        ? DateTime.fromISO(
            athlete[report].slice(-1)[0].createdAt
          ).toLocaleString({
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })
        : "none";

    const hoursSinceLastReport =
      athlete[report] && athlete[report].slice(-1)[0]
        ? Interval.fromDateTimes(
            DateTime.fromISO(athlete[report].slice(-1)[0].createdAt).set({
              hour: 0,
              minutes: 0,
              seconds: 0,
              milliseconds: 0,
            }),
            now
          ).length("hours")
        : 25;

    return (
      <>
        <p
          style={
            // report !== "injuryReports" &&
            hoursSinceLastReport && hoursSinceLastReport > 24
              ? { color: "red" }
              : { color: "green" }
          }
        >
          {lastReportDateDisplay}
        </p>
      </>
    );
  };

  const handleStyleButtonLastFiveReports = (value) => {
    if (value <= 5) {
      return {
        backgroundColor: "#0cc38a",
        color: "white",
      };
    }
    if (value > 5 && value <= 7) {
      return {
        backgroundColor: "#fcb757",
        color: "white",
      };
    }
    if (value > 7) {
      return {
        backgroundColor: "#e45741",
        color: "white",
      };
    } else {
      return;
    }
  };

  const handleStyleButtonLastFiveReportsSleepOnly = (value) => {
    if (value <= 5) {
      return {
        backgroundColor: "#e45741",
        color: "white",
      };
    }
    if (value > 5 && value <= 7) {
      return {
        backgroundColor: "#fcb757",
        color: "white",
      };
    }
    if (value > 7) {
      return {
        backgroundColor: "#0cc38a",
        color: "white",
      };
    } else {
      return;
    }
  };

  const handleDisplayLastFiveReports = (reports, flag) => {
    return (
      <>
        <div id={"staff-wellness-report-card-trend-container"}>
          <p className={"report-card-trend-header"}>Last 5 reports</p>

          <div id={"staff-wellness-report-card-trend"}>
            <div className={"staff-wellness-report-card-trend-value-container"}>
              <div
                className={"staff-wellness-report-card-trend-value"}
                style={handleStyleButtonLastFiveReports(
                  reports[4] && reports[4][flag]
                )}
              >
                <p>{reports[4] ? reports[4][flag] : "na"}</p>
              </div>
              <div
                className={"staff-wellness-report-card-trend-value"}
                style={handleStyleButtonLastFiveReports(
                  reports[3] && reports[3][flag]
                )}
              >
                <p>{reports[3] ? reports[3][flag] : "na"}</p>
              </div>
              <div
                className={"staff-wellness-report-card-trend-value"}
                style={handleStyleButtonLastFiveReports(
                  reports[2] && reports[2][flag]
                )}
              >
                <p>{reports[2] ? reports[2][flag] : "na"}</p>
              </div>
              <div
                className={"staff-wellness-report-card-trend-value"}
                style={handleStyleButtonLastFiveReports(
                  reports[1] && reports[1][flag]
                )}
              >
                <p>{reports[1] ? reports[1][flag] : "na"}</p>
              </div>
              <div
                className={"staff-wellness-report-card-trend-value"}
                style={handleStyleButtonLastFiveReports(
                  reports[0] && reports[0][flag]
                )}
              >
                <p>{reports[0] ? reports[0][flag] : "na"}</p>
              </div>
            </div>
          </div>
          <div className={"staff-wellness-report-card-trend-label"}>
            <p>older</p>
            <p>newest</p>
          </div>
        </div>
      </>
    );
  };

  const handleDisplayLastFiveReportsSleepOnly = (reports, flag) => {
    return (
      <>
        <div id={"staff-wellness-report-card-trend-container"}>
          <p className={"report-card-trend-header"}>Last 5 reports</p>

          <div id={"staff-wellness-report-card-trend"}>
            <div className={"staff-wellness-report-card-trend-value-container"}>
              <div
                className={"staff-wellness-report-card-trend-value"}
                style={handleStyleButtonLastFiveReportsSleepOnly(
                  reports[4] && reports[4][flag]
                )}
              >
                <p>{reports[4] ? reports[4][flag] : "na"}</p>
              </div>
              <div
                className={"staff-wellness-report-card-trend-value"}
                style={handleStyleButtonLastFiveReportsSleepOnly(
                  reports[3] && reports[3][flag]
                )}
              >
                <p>{reports[3] ? reports[3][flag] : "na"}</p>
              </div>
              <div
                className={"staff-wellness-report-card-trend-value"}
                style={handleStyleButtonLastFiveReportsSleepOnly(
                  reports[2] && reports[2][flag]
                )}
              >
                <p>{reports[2] ? reports[2][flag] : "na"}</p>
              </div>
              <div
                className={"staff-wellness-report-card-trend-value"}
                style={handleStyleButtonLastFiveReportsSleepOnly(
                  reports[1] && reports[1][flag]
                )}
              >
                <p>{reports[1] ? reports[1][flag] : "na"}</p>
              </div>
              <div
                className={"staff-wellness-report-card-trend-value"}
                style={handleStyleButtonLastFiveReportsSleepOnly(
                  reports[0] && reports[0][flag]
                )}
              >
                <p>{reports[0] ? reports[0][flag] : "na"}</p>
              </div>
            </div>
          </div>
          <div className={"staff-wellness-report-card-trend-label"}>
            <p>older</p>
            <p>newest</p>
          </div>
        </div>
      </>
    );
  };

  const handleDisplayLastInjuryReport = (reports, flag) => {
    return (
      <>
        <div id={"staff-wellness-report-card-injury-container"}>
          <div id={"staff-wellness-report-card-injury"}>
            <div
              className={"staff-wellness-report-card-injury-value-container"}
            >
              <h5 className={"staff-wellness-report-card-injury-value-text"}>
                {reports[0] && reports[0].region}
              </h5>
              <p className={"staff-wellness-report-card-injury-value-text"}>
                SIDE: {reports[0] && reports[0].side}
              </p>
              <p className={"staff-wellness-report-card-injury-value-text"}>
                PAIN: {reports[0] && reports[0].pain}{" "}
              </p>
            </div>
          </div>
        </div>
      </>
    );
  };

  const getTrendForFlaggedPlayers = (athleteId, flag) => {
    // pre-session
    if (flag === "fatigue" && teamAthletes) {
      const athlete = teamAthletes.filter(
        (athlete) => athlete._id === athleteId
      );
      const reports = athlete[0].preSessionWellness;
      const reverseReports = [...reports].reverse();
      return handleDisplayLastFiveReports(reverseReports, flag);
    }
    if (flag === "stress" && teamAthletes) {
      const athlete = teamAthletes.filter(
        (athlete) => athlete._id === athleteId
      );
      const reports = athlete[0].preSessionWellness;
      const reverseReports = [...reports].reverse();
      return handleDisplayLastFiveReports(reverseReports, flag);
    }
    if (flag === "soreness" && teamAthletes) {
      const athlete = teamAthletes.filter(
        (athlete) => athlete._id === athleteId
      );
      const reports = athlete[0].preSessionWellness;
      const reverseReports = [...reports].reverse();
      return handleDisplayLastFiveReports(reverseReports, flag);
    }
    if (flag === "sleep" && teamAthletes) {
      const athlete = teamAthletes.filter(
        (athlete) => athlete._id === athleteId
      );
      const reports = athlete[0].preSessionWellness;
      const reverseReports = [...reports].reverse();
      return handleDisplayLastFiveReportsSleepOnly(reverseReports, flag);
    }
    // injury
    if (flag === "injury" && teamAthletes) {
      const athlete = teamAthletes.filter(
        (athlete) => athlete._id === athleteId
      );
      const reports = athlete[0].injuryReports;
      const reverseReports = [...reports].reverse();
      return handleDisplayLastInjuryReport(reverseReports, flag);
    }
  };

  const getFlaggReports = () => {
    // get flagged reports
    const flaggedForPreSessionLast24 = [];
    const flaggedForHighStress = [];
    const flaggedForHighFatigue = [];
    const flaggedForHighSoreness = [];
    const flaggedForLowSleep = [];
    const flaggedForInjuryLast24 = [];

    teamAthletes &&
      teamAthletes.forEach((athlete) => {
        reportsToCheckForFlags &&
          reportsToCheckForFlags.forEach((report) => {
            const lastReportSubmittedByAthlete =
              athlete[report.report] && athlete[report.report].slice(-1)[0];
            // get string of last report
            const lastReportDateDisplay =
              athlete[report.report] && athlete[report.report].length > 0
                ? DateTime.fromISO(
                    lastReportSubmittedByAthlete.createdAt
                  ).toLocaleString()
                : "none";

            // hours from last report to now
            const hoursSinceLastReport =
              athlete[report.report] && lastReportSubmittedByAthlete
                ? Interval.fromDateTimes(
                    DateTime.fromISO(
                      lastReportSubmittedByAthlete.createdAt
                    ).set({
                      hour: 0,
                      minutes: 0,
                      seconds: 0,
                      milliseconds: 0,
                    }),
                    now
                  ).length("hours")
                : 25;

            // pre-session reports
            if (report.report === "preSessionWellness") {
              if (hoursSinceLastReport > 12) {
                //---------------------------------Threshold Setting
                const flagReport = {
                  athlete: athlete,
                  flag: report.report,
                  report: lastReportSubmittedByAthlete,
                  lastReport: lastReportDateDisplay,
                  reason: "no submission past 24 hours",
                };
                flaggedForPreSessionLast24.push(flagReport);
                setFlaggedPreSessionLast24(flaggedForPreSessionLast24);
              }

              // stress threshold
              if (
                hoursSinceLastReport <= 96 &&
                lastReportSubmittedByAthlete &&
                lastReportSubmittedByAthlete.stress > 5 //---------------------------------Threshold Setting
              ) {
                const flagReport = {
                  athlete: athlete,
                  flag: "preSessionWellness",
                  report: lastReportSubmittedByAthlete,
                  lastReport: lastReportDateDisplay,
                  reason: "report of elevated stress past 24 hours",
                };
                flaggedForHighStress.push(flagReport);
                setFlaggedHighStress(flaggedForHighStress);
              }

              // fatigue threshold
              if (
                hoursSinceLastReport <= 96 &&
                lastReportSubmittedByAthlete &&
                lastReportSubmittedByAthlete.fatigue > 5 //---------------------------------Threshold Setting
              ) {
                const flagReport = {
                  athlete: athlete,
                  flag: "preSessionWellness",
                  report: lastReportSubmittedByAthlete,
                  lastReport: lastReportDateDisplay,
                  reason: "report of elevated fatigue past 24 hours",
                };
                flaggedForHighFatigue.push(flagReport);
                setFlaggedHighFatigue(flaggedForHighFatigue);
              }

              // soreness threshold
              if (
                hoursSinceLastReport <= 96 &&
                lastReportSubmittedByAthlete &&
                lastReportSubmittedByAthlete.soreness > 5 //---------------------------------Threshold Setting
              ) {
                const flagReport = {
                  athlete: athlete,
                  flag: "preSessionWellness",
                  report: lastReportSubmittedByAthlete,
                  lastReport: lastReportDateDisplay,
                  reason: "report of elevated fatigue past 24 hours",
                };
                flaggedForHighSoreness.push(flagReport);
                setFlaggedHighSoreness(flaggedForHighSoreness);
              }

              // sleep threshold
              if (
                hoursSinceLastReport <= 96 &&
                lastReportSubmittedByAthlete &&
                lastReportSubmittedByAthlete.sleep < 6 //---------------------------------Threshold Setting
              ) {
                const flagReport = {
                  athlete: athlete,
                  flag: "preSessionWellness",
                  report: lastReportSubmittedByAthlete,
                  lastReport: lastReportDateDisplay,
                  reason: "report of low sleep",
                };
                flaggedForLowSleep.push(flagReport);
                setFlaggedLowSleep(flaggedForLowSleep);
              }
            }

            // injury reports
            if (report.report === "injuryReports") {
              if (
                hoursSinceLastReport <= 96 &&
                lastReportSubmittedByAthlete &&
                lastReportSubmittedByAthlete.region !== "0"
              ) {
                //---------------------------------Threshold Setting
                const flagReport = {
                  athlete: athlete,
                  flag: "injuryReports",
                  report: lastReportSubmittedByAthlete,
                  lastReport: lastReportDateDisplay,
                  reason: "submitted injury report in past 24 hours",
                };
                flaggedForInjuryLast24.push(flagReport);
                setFlaggedInjuryLast24(flaggedForInjuryLast24);
              }
            }
          });
      });
  };

  useEffect(() => {
    // get updated data from DB
    teamId &&
      service.getTeamDetails(teamId).then((team) => {
        if (team.data.teamDetails) {
          setTeamAthletes(team.data.teamDetails.athletes);
        }
      });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getFlaggReports();
    // eslint-disable-next-line
  }, [teamAthletes, teamId]);

  console.log("flagInjury", flaggedInjuryLast24);

  // // modify date format
  // const newArray =
  //   flaggedInjuryLast24 &&
  //   flaggedInjuryLast24.map((obj) => ({
  //     ...obj,
  //     date: DateTime.fromISO(obj.report.createdAt).toLocaleString({
  //       month: "numeric",
  //       day: "numeric",
  //       hour: "numeric",
  //       minute: "numeric",
  //     }),
  //   }));

  // const injuryRegion =
  //   newArray && newArray.map((obs) => obs.report.region);

  return (
    <div>
      <h3>Reported Injuries</h3>
      {/* <div className={"flag-trend-container"}>
        <div className={"flag-chart-container"} >
          <FlagReportTrendInjury flagReports={injuryRegion} />
        </div>
      </div> */}
      <div id={"staff-wellness-report-widget-athlete-card-container"}>
        {flaggedInjuryLast24 &&
          flaggedInjuryLast24.map((flagReport, i) => {
            return (
              <div className={"wellness-report-card-outer-container"}>
                <div id={"staff-wellness-report-card-container"} key={i}>
                  <div id={"staff-wellness-report-card-name"}>
                    <p>
                      {flagReport.athlete.firstName.toUpperCase()}{" "}
                      {flagReport.athlete.lastName[0].toUpperCase()}
                    </p>

                    <CloudinaryContext
                      cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
                    >
                      <Image publicId={flagReport.athlete.imageId} format="png">
                        <Transformation
                          gravity="face"
                          width="80"
                          height="80"
                          radius="max"
                          zoom="0.7"
                          crop="fill"
                        />
                      </Image>
                    </CloudinaryContext>
                  </div>
                  <div id={"staff-wellness-report-card-last-report"}>
                    {getLastReportDateAndFormat(
                      flagReport.athlete,
                      "injuryReports",
                      "injury"
                    )}
                    {getTrendForFlaggedPlayers(
                      flagReport.athlete._id,
                      "injury"
                    )}
                    <div className={"flag-report-button-container"}>
                      <div className={"flag-report-view-chart-button"}>
                        <img
                          src={ChartIcon}
                          alt="chart"
                          className={"view-member-chart-icon"}
                          onClick={() => {
                            handleClickMemberChart(flagReport.athlete._id);
                          }}
                          title="View wellness chart."
                        />
                      </div>
                      <div className={"flag-report-view-reports-button"}>
                        <img
                          src={ReportsIcon}
                          alt="chart"
                          className={"view-member-chart-icon"}
                          onClick={() => {
                            handleClickMemberReports(flagReport.athlete._id);
                          }}
                          title="View wellness reports."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      {/* ------------ */}
      <h3>High Stress</h3>
      <div id={"staff-wellness-report-widget-athlete-card-container"}>
        {flaggedHighStress &&
          flaggedHighStress.map((flagReport, i) => {
            return (
              <div id={"staff-wellness-report-card-container"} key={i}>
                <div id={"staff-wellness-report-card-name"}>
                  <p>
                    {flagReport.athlete.firstName.toUpperCase()}{" "}
                    {flagReport.athlete.lastName[0].toUpperCase()}
                  </p>

                  <CloudinaryContext
                    cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
                  >
                    <Image publicId={flagReport.athlete.imageId} format="png">
                      <Transformation
                        gravity="face"
                        width="80"
                        height="80"
                        radius="max"
                        zoom="0.7"
                        crop="fill"
                      />
                    </Image>
                  </CloudinaryContext>
                </div>
                <div id={"staff-wellness-report-card-last-report"}>
                  {getLastReportDateAndFormat(
                    flagReport.athlete,
                    "preSessionWellness",
                    "wr"
                  )}
                  {getTrendForFlaggedPlayers(flagReport.athlete._id, "stress")}
                  <div className={"flag-report-button-container"}>
                    <div className={"flag-report-view-chart-button"}>
                      <img
                        src={ChartIcon}
                        alt="chart"
                        className={"view-member-chart-icon"}
                        onClick={() => {
                          handleClickMemberChart(flagReport.athlete._id);
                        }}
                        title="View member's chart."
                      />
                    </div>
                    <div className={"flag-report-view-reports-button"}>
                      <img
                        src={ReportsIcon}
                        alt="chart"
                        className={"view-member-chart-icon"}
                        onClick={() => {
                          handleClickMemberReports(flagReport.athlete._id);
                        }}
                        title="View member's report."
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      {/* ---------- */}
      <h3>High Fatigue</h3>
      <div id={"staff-wellness-report-widget-athlete-card-container"}>
        {flaggedHighFatigue &&
          flaggedHighFatigue.map((flagReport, i) => {
            return (
              <div id={"staff-wellness-report-card-container"} key={i}>
                <div id={"staff-wellness-report-card-name"}>
                  <p>
                    {flagReport.athlete.firstName.toUpperCase()}{" "}
                    {flagReport.athlete.lastName[0].toUpperCase()}
                  </p>

                  <CloudinaryContext
                    cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
                  >
                    <Image publicId={flagReport.athlete.imageId} format="png">
                      <Transformation
                        gravity="face"
                        width="80"
                        height="80"
                        radius="max"
                        zoom="0.7"
                        crop="fill"
                      />
                    </Image>
                  </CloudinaryContext>
                </div>
                <div id={"staff-wellness-report-card-last-report"}>
                  {getLastReportDateAndFormat(
                    flagReport.athlete,
                    "preSessionWellness",
                    "wr"
                  )}
                  {getTrendForFlaggedPlayers(flagReport.athlete._id, "fatigue")}
                  <div className={"flag-report-button-container"}>
                    <div className={"flag-report-view-chart-button"}>
                      <img
                        src={ChartIcon}
                        alt="chart"
                        className={"view-member-chart-icon"}
                        onClick={() => {
                          handleClickMemberChart(flagReport.athlete._id);
                        }}
                      />
                    </div>
                    <div className={"flag-report-view-reports-button"}>
                      <img
                        src={ReportsIcon}
                        alt="chart"
                        className={"view-member-chart-icon"}
                        onClick={() => {
                          handleClickMemberReports(flagReport.athlete._id);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {/* --------------- */}
      <h3>High Soreness</h3>
      <div id={"staff-wellness-report-widget-athlete-card-container"}>
        {flaggedHighSoreness &&
          flaggedHighSoreness.map((flagReport, i) => {
            return (
              <div id={"staff-wellness-report-card-container"} key={i}>
                <div id={"staff-wellness-report-card-name"}>
                  <p>
                    {flagReport.athlete.firstName.toUpperCase()}{" "}
                    {flagReport.athlete.lastName[0].toUpperCase()}
                  </p>

                  <CloudinaryContext
                    cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
                  >
                    <Image publicId={flagReport.athlete.imageId} format="png">
                      <Transformation
                        gravity="face"
                        width="80"
                        height="80"
                        radius="max"
                        zoom="0.7"
                        crop="fill"
                      />
                    </Image>
                  </CloudinaryContext>
                </div>
                <div id={"staff-wellness-report-card-last-report"}>
                  {getLastReportDateAndFormat(
                    flagReport.athlete,
                    "preSessionWellness",
                    "wr"
                  )}
                  {getTrendForFlaggedPlayers(
                    flagReport.athlete._id,
                    "soreness"
                  )}
                  <div className={"flag-report-button-container"}>
                    <div className={"flag-report-view-chart-button"}>
                      <img
                        src={ChartIcon}
                        alt="chart"
                        className={"view-member-chart-icon"}
                        onClick={() => {
                          handleClickMemberChart(flagReport.athlete._id);
                        }}
                      />
                    </div>
                    <div className={"flag-report-view-reports-button"}>
                      <img
                        src={ReportsIcon}
                        alt="chart"
                        className={"view-member-chart-icon"}
                        onClick={() => {
                          handleClickMemberReports(flagReport.athlete._id);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {/* --------------- */}
      <h3>Low Sleep</h3>
      <div id={"staff-wellness-report-widget-athlete-card-container"}>
        {flaggedLowSleep &&
          flaggedLowSleep.map((flagReport, i) => {
            return (
              <div id={"staff-wellness-report-card-container"} key={i}>
                <div id={"staff-wellness-report-card-name"}>
                  <p>
                    {flagReport.athlete.firstName.toUpperCase()}{" "}
                    {flagReport.athlete.lastName[0].toUpperCase()}
                  </p>

                  <CloudinaryContext
                    cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
                  >
                    <Image publicId={flagReport.athlete.imageId} format="png">
                      <Transformation
                        gravity="face"
                        width="80"
                        height="80"
                        radius="max"
                        zoom="0.7"
                        crop="fill"
                      />
                    </Image>
                  </CloudinaryContext>
                </div>
                <div id={"staff-wellness-report-card-last-report"}>
                  {getLastReportDateAndFormat(
                    flagReport.athlete,
                    "preSessionWellness",
                    "wr"
                  )}
                  {getTrendForFlaggedPlayers(flagReport.athlete._id, "sleep")}
                  <div className={"flag-report-button-container"}>
                    <div className={"flag-report-view-chart-button"}>
                      <img
                        src={ChartIcon}
                        alt="chart"
                        className={"view-member-chart-icon"}
                        onClick={() => {
                          handleClickMemberChart(flagReport.athlete._id);
                        }}
                      />
                    </div>
                    <div className={"flag-report-view-reports-button"}>
                      <img
                        src={ReportsIcon}
                        alt="chart"
                        className={"view-member-chart-icon"}
                        onClick={() => {
                          handleClickMemberReports(flagReport.athlete._id);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {/* --------------- */}
      <h3>No Submission (Last 12 hrs)</h3>
      <div id={"staff-wellness-report-widget-athlete-card-container"}>
        {flaggedPreSessionLast24 &&
          flaggedPreSessionLast24.map((flagReport, i) => {
            return (
              <div id={"staff-wellness-report-card-container"} key={i}>
                <div id={"staff-wellness-report-card-name"}>
                  <p>
                    {flagReport.athlete.firstName.toUpperCase()}{" "}
                    {flagReport.athlete.lastName[0].toUpperCase()}
                  </p>

                  <CloudinaryContext
                    cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
                  >
                    <Image publicId={flagReport.athlete.imageId} format="png">
                      <Transformation
                        gravity="face"
                        width="80"
                        height="80"
                        radius="max"
                        zoom="0.7"
                        crop="fill"
                      />
                    </Image>
                  </CloudinaryContext>
                </div>
                <div id={"staff-wellness-report-card-last-report"}>
                  <p>Last Report</p>
                  {getLastReportDateAndFormat(
                    flagReport.athlete,
                    "preSessionWellness",
                    "wr"
                  )}
                  <div className={"flag-report-button-container"}>
                    <div className={"flag-report-view-chart-button"}>
                      <img
                        src={ChartIcon}
                        alt="chart"
                        className={"view-member-chart-icon"}
                        onClick={() => {
                          handleClickMemberChart(flagReport.athlete._id);
                        }}
                      />
                    </div>
                    <div className={"flag-report-view-reports-button"}>
                      <img
                        src={ReportsIcon}
                        alt="chart"
                        className={"view-member-chart-icon"}
                        onClick={() => {
                          handleClickMemberReports(flagReport.athlete._id);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {/* --------------- */}
      {/* --------------- */}
    </div>
  );
}
