import React, { useContext } from "react";
import "./SideMenu.css";
import { Mongo } from "../../auth/mongo-provider";
import AvatarIcon from "../../images/responsive-avatar-icon.png";
import ChartIcon from "../../images/responsive-chart-icon.png";
import ReportIcon from "../../images/responsive-report-icon.png";
import SettingsIcon from "../../images/responsive-settings-icon.png";
import TeamIcon from "../../images/responsive-team-icon.png";
import MemberIcon from "../../images/account-details-total-teams.png";
import CheckIcon from "../../images/b.png";
import { Link } from "react-router-dom";

export default function SideMenu() {
  const { userDB } = useContext(Mongo);

  const [InternalNavigation] = React.useState([

    {
      text: "My Wellness Chart",
      path: userDB && `/wellness-chart/${userDB._id}`,
      icon: ChartIcon,
    },
    {
      text: "My Wellness Reports",
      path: `/wellness-reports/${userDB && userDB._id}`,
      icon: ReportIcon,
    },
    {
      text: "Create/Manage Teams",
      path: "/teams",
      icon: TeamIcon,
    },
    // {
    //   text: "Exertion Chart",
    //   path: userDB && `/exertion-chart/${userDB._id}`,
    //   icon: ChartIcon,
    // },
    // {
    //   text: "Exertion Reports",
    //   path: `/exertion-reports/${userDB && userDB._id}`,
    //   icon: ReportIcon,
    // },
    {
      text: "My Team Memberships",
      path: `/memberships/${userDB && userDB._id}`,
      icon: MemberIcon,
    },
    {
      text: "My Account",
      path: userDB && `/account/${userDB._id}`,
      icon: AvatarIcon,
    },
    {
      text: "Profile",
      path: userDB && `/profile/${userDB._id}`,
      icon: SettingsIcon,
    },
  ]);

  return (
    <div className={"side-menu-container"}>
      {InternalNavigation.map((selection) => (
        <div key={selection.text} className={"side-menu-item"}>
          <img
            className={"side-menu-icon"}
            src={selection.icon}
            alt={selection.text}
          />
          <Link
            to={{
              pathname: selection.path,
              state: {
                userId: userDB && userDB._id,
                update: true,
              },
            }}
            className={"side-menu-text"}
          >
            <p>{selection.text}</p>
          </Link>
        </div>
      ))}
      <div className={"side-menu-item"}>
        <img className={"side-menu-icon"} src={CheckIcon} alt={"Support"} />
        <a
          href={
            "https://upfield-analytics.atlassian.net/servicedesk/customer/portals"
          }
          className={"side-menu-text"}
          target="_blank"
          rel="noreferrer"
        >
          <p>{"Support"}</p>
        </a>
      </div>
      {userDB && userDB.admin && (
        <div className={"side-menu-item"}>
          <img className={"side-menu-icon"} src={CheckIcon} alt={"Support"} />
          <Link
            to={{
              pathname: `/admin-teams/${userDB && userDB._id}`,
              state: {
                userId: userDB && userDB._id,
                update: true,
              },
            }}
            className={"side-menu-text"}
          >
            <p>{"Admin Teams"}</p>
          </Link>
        </div>
      )}
      {userDB && userDB.admin && (
        <div className={"side-menu-item"}>
          <img className={"side-menu-icon"} src={CheckIcon} alt={"Support"} />
          <Link
            to={{
              pathname: `/admin-users/${userDB && userDB._id}`,
              state: {
                userId: userDB && userDB._id,
                update: true,
              },
            }}
            className={"side-menu-text"}
          >
            <p>{"Admin Users"}</p>
          </Link>
        </div>
      )}
    </div>
  );
}
