import React, { useContext, useEffect, useState } from "react";
import "./Checkout.css";
import { useHistory, useLocation } from "react-router-dom";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Mongo } from "../../auth/mongo-provider";
import service from "../../utils/service";
import { DateTime } from "luxon";

export default function Checkout() {
  let history = useHistory();
  const { userDB } = useContext(Mongo);
  const location = useLocation();
  const [successMsg, setSuccessMsg] = useState();
  const [failureMsg, setFailureMsg] = useState();
  const [client_secret] = useState(location.state.client_secret);
  const priceId = location.state.priceId;
  // const stripeId = location.state.stripeId;
  const term = location.state.term;
  const total_cost = location.state.total_cost;
  // const member_count = location.state.member_count;
  const [stripeId, setStripeId] = useState();

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Proxima Nova"',
        fontSmoothing: "antialiased",
        fontSize: "18px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },

    },
  };

  const stripe = useStripe();
  const elements = useElements();

  // const handlePaymentThatRequiresCustomerAction = () => {
  //   console.log(
  //     "placeholder function to do some things that need action for payment"
  //   );
  // };

  // const handleRequiresPaymentMethod = () => {
  //   console.log(
  //     "placeholder function to do some things that need action for payment"
  //   );
  // };

  // const onSubscriptionComplete = () => {
  //   console.log("provision service, no more action required");
  //   console.log("subscription", subscriptionStatus);
  // };

  useEffect(() => {
    userDB &&
      service.getUserFromDB(userDB.email).then((foundUser) => {
        setStripeId(foundUser.data.userDB.stripeCustomerId);
      });

    // create stripe if they don't have one yet
    if (!stripeId) {
      console.log("creating new stripe customer");
      userDB &&
        service
          .createStripeCustomer({ email: userDB.email, userId: userDB._id })
          .then((response) => {
            setStripeId(response.data.customer.id);
          });
    } else {
      console.log("already has stripe id", stripeId);
    }

    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        email: userDB && userDB.email,
      },
    });

    if (error) {
      console.log("[error]", error);
    } else {
      console.log("[PaymentMethod]", paymentMethod);
    }

    const paymentAttempt = await stripe.confirmCardPayment(client_secret, {
      receipt_email: userDB.email,
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          email: userDB.email,
        },
      },
    });

    if (paymentAttempt.error) {
      // Show error to your customer (e.g., insufficient funds)
      setFailureMsg(paymentAttempt.error.message);
    } else {
      // The payment has been processed!
      if (paymentAttempt.paymentIntent.status === "succeeded") {
        setSuccessMsg(paymentAttempt.paymentIntent.status);

        // create subscription and redirect
        await service
          .createSubscription({
            priceId: priceId,
            stripeId: stripeId,
            paymentMethodId: paymentMethod.id,
            userId: userDB._id,
          })
          .then((result) => {
            if (result.error) {
              // The card had an error when trying to attach it to a customer.
              throw result;
            }
            return result;
          })
          // Normalize the result to contain the object returned by Stripe.
          // Add the additional details we need.
          .then((result) => {
            // if (result.data.status === "active") {
            // Change your UI to show a success message to your customer.
            // Call your backend to grant access to your service based on
            // `result.subscription.items.data[0].price.product` the customer subscribed to.
            history.push({
              pathname: "/teams",
              state: {
                status: result.data.status,
                trial_end: result.data.trial_end,
                product: result.data.plan.product,
                price: result.data.plan.id,
                interval: result.data.plan.interval,
                update: true,
              },
            });
            // }
          })
          // // Some payment methods require a customer to be on session
          // // to complete the payment process. Check the status of the
          // // payment intent to handle these actions.
          // .then(handlePaymentThatRequiresCustomerAction)
          // // If attaching this card to a Customer object succeeds,
          // // but attempts to charge the customer fail, you
          // // get a requires_payment_method error.
          // .then(handleRequiresPaymentMethod)
          // // No more actions required. Provision your service for the user.
          // .then(onSubscriptionComplete)
          .catch((error) => {
            // An error has happened. Display the failure to the user here.
            // We utilize the HTML element we created.
            setFailureMsg(error.message);
          });
      }
    }
  };

  const getTrialEnd = () => {
    const dt = DateTime.now();
    const trialEnd = dt.minus({ days: 30 }).toLocaleString();
    return trialEnd;
  };

  return (
    <div className={"checkout-container"}>
      {window.scroll({ top: 0, behavior: "smooth" })}
      <h1>Checkout</h1>
      <div className={"checkout-content-container"}>
        <div className={"checkout-content-block"}>
          {/* <p>priceId: {priceId}</p>
          <p>client_secret: {client_secret}</p>
          <p>stripeId: {stripeId}</p> */}

          <h2>
            Starting at ${total_cost} / member / {term}
          </h2>

          <p>
            Free trial ends on {getTrialEnd()} and your card will be charged
            each billing cylce.
          </p>
          <p>Your account will not be charged until your first member joins.</p>
          <p>
            Billing amounts calculated based on max usage during billing cycle.
          </p>
        </div>

        <div className={"checkout-content-block"}>
          <h2>Payment Details</h2>

          <div className={"card-payment-messages"}>
            {failureMsg && failureMsg}
            {successMsg && successMsg}
          </div>
          <div id="payment-form">
            <form onSubmit={handleSubmit}>
              <label htmlFor="card-element">Card</label>
              <CardElement id="card-element" options={CARD_ELEMENT_OPTIONS} />
              <button className={"checkout-button"}>Pay</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
