import React, { useContext, useState } from "react";
import service from "../../utils/service";
import "./OnboardingProfileImage.css";
import { Image, Transformation, CloudinaryContext } from "cloudinary-react";
import { Mongo } from "../../auth/mongo-provider";

export default function OnboardingProfileImage() {
  const { userDB, setProfileImageId } = useContext(Mongo);
  const [form, setForm] = useState(new FormData());
  const [confirmationMsg, setConfirmationMsg] = useState(null);
  const [canSubmit, setCanSubmit] = useState(false);
  const [userImage, setUserImage] = useState(userDB && userDB.imageId);

  const submitHandler = async (e) => {
    e.preventDefault();
    canSubmit &&
      (await service
        .updateUserProfileImage(form.image, userDB && userDB._id)
        .then((response) => {
          if (response.data.userDB.imageId) {
            setUserImage(response.data.userDB.imageId);
            setConfirmationMsg("Your profile udated succesfully. ");
            setCanSubmit(false);
            setProfileImageId(response.data.userDB.imageId);
          }
        }));
  };

  const changeHandler = async (e) => {
    const imageForm = new FormData();
    const imageFile = e.target.files[0];
    // setImagePreview(URL.createObjectURL(imageFile));
    imageForm.append("image", imageFile);
    setForm({ image: imageForm });
    setCanSubmit(true);
  };

  return (
    <div className={"profile-upload-container"}>
      <>
        <h2>Edit Profile Image</h2>
        <div className={"img-preview-container"}>
          <CloudinaryContext
            cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
          >
            <Image
              publicId={userImage && userImage}
              className={"img-preview"}
              format="png"
            >
              <Transformation
                gravity="face"
                width="200"
                height="200"
                radius="max"
                zoom="0.7"
                crop="thumb"
              />
              <Transformation effect="shadow" />
            </Image>
          </CloudinaryContext>
        </div>
        {confirmationMsg && <h4>{confirmationMsg}</h4>}

        <form onSubmit={submitHandler} encType="multipart/form-data">
          <div className="form-group">
            <input
              type="file"
              name="image"
              id="image-input"
              onChange={changeHandler}
            />
          </div>

          {canSubmit && (
            <button type="submit" className={"update-form-submit-button"}>
              Submit
            </button>
          )}
        </form>
      </>
    </div>
  );
}
