import React from "react";
import { useHistory } from "react-router-dom";
import "./MyStaffMembershipsCard.css";

export default function MyStaffMembershipsCard({
  team,
  i,
  handleRemoveFromTeam,
}) {
  let history = useHistory();

  const handleClickTeam = (teamId) => {
    console.log("go to team chart", teamId);
    history.push({
      pathname: `/team-wellness/${teamId}`,
      state: {
        teamId: teamId,
        update: true,
      },
    });
  };

  return (
    <div className={"staff-memberships-details-main-card-container"} key={i}>
      <div
        className={"staff-memberships-details-team-card-outer-container"}
        key={i}
      >
        <div className={"staff-memberships-details-team-card-text"}>
          <h2>Staff Member</h2>
          <h3>Team: {team.name}</h3>
          <p>{team.email}</p>
          <p>Activity: {team.sport}</p>
          <p>City: {team.city}</p>
          <div
            className={"staff-view-team-button"}
            onClick={() => {
              handleClickTeam(team._id);
            }}
          >
            <p>View Team</p>
          </div>
        </div>

        {/* <div className={"accept-team-membershipss-button-container"}>
        <button
          className={"staff-memberships-remove-button"}
          onClick={() => handleRemoveFromTeam(team)}
        >
          Remove
        </button>
      </div> */}
      </div>
    </div>
  );
}
