import React, { useContext, useEffect, useState } from "react";
import "./InviteUser.css";
import service from "../../utils/service";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "yup-phone";
import { Mongo } from "../../auth/mongo-provider";
import Loading from "../loading/Loading";
import { MediaSize } from "../../styles/MediaSizeProvider";
import SideMenu from "../sideMenu/SideMenu";
import SendAgainIcon from "../../images/send-again-icon.png";
import DeleteMemberIcon from "../../images/teams-delete-team.png";

export default function InviteUser({ match, location }) {
  const { Desktop } = useContext(MediaSize);
  const { userDB } = useContext(Mongo);
  const [teamDetails, setTeamDetails] = useState();
  const [teamInvitesSent, setTeamInvitesSent] = useState();
  const [loading, setLoading] = useState();
  const [sent, setSent] = useState(false);
  const [validationMessage, setValidationMessage] = useState(null);
  const teamId = match && match.params.teamId;
  const [userId, setUserId] = useState();
  const userEmail = location.state !== undefined ? location.state.email : null;
  const [confirmReminderSent, setConfirmReminderSent] = useState(false);

  useEffect(() => {
    teamId && userDB && setLoading(true);
    service.getTeamDetails(teamId).then((response) => {
      setTeamDetails(response.data.teamDetails);
      setLoading(false);
    });

    userDB &&
      service
        .getUserFromDB(userEmail ? userEmail : userDB.email)
        // .getUserFromDB(userDB.email)
        .then((foundUser) => {
          const justInvitesForThisTeam =
            foundUser.data.userDB.teamInvitesSent.filter(
              (inv) => inv.teamId === teamId
            );
          setUserId(foundUser.data.userDB._id); // this is to support admin functionality
          setTeamInvitesSent(justInvitesForThisTeam);
        });

    // eslint-disable-next-line
  }, [setTeamDetails, setTeamInvitesSent]);

  const handleResendInvite = (invite) => {
    service.resendTeamInvite({
      email: invite.email,
      teamName: teamDetails.name,
    });
    // setSent(true);
    setConfirmReminderSent(true);
    setTimeout(() => {
      setConfirmReminderSent(null);
    }, 2000);
  };

  const handleRemoveInvite = (invite) => {
    console.log("invite", invite);
    service
      .removeFromTeamInvite({
        email: invite.email,
        userId: userEmail ? userId : userDB._id,
        teamId: teamDetails._id,
        inviteId: invite._id,
      })
      .then((response) => {
        // update invites sent here from the response
        setTeamInvitesSent(response.data.teamInvitesSent);
      });
  };

  return (
    <div className={"invite-user-main-container"}>
      {!loading && teamDetails ? (
        <>
          <Desktop>
            <div className={"home-side-menu-container"}>
              <SideMenu />
            </div>
          </Desktop>
          <div className={"invite-user-main-content-container"}>
            <h2>Invite Members To {teamDetails.name} </h2>

            <div className={"invite-user-form-main-container"}>
              {validationMessage && validationMessage}
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("Not a valid email address")
                    .required("Required"),
                })}
                onSubmit={async (values, { resetForm }) => {
                  // put logic here to prevent from sending an invite to yourself
                  if (values.email === userDB.email) {
                    setValidationMessage("you cannot send yourself an invite");
                    setTimeout(() => {
                      setValidationMessage(null);
                    }, 5000);
                    resetForm();
                    return;
                  }
                  // prevent from sending a new invite to someone already in the invites sent list
                  // const checkInvites = userDB.teamInvitesSent.filter(
                  //   (invite) => invite.email === values.email
                  // );
                  // if (checkInvites.length > 0) {
                  //   setValidationMessage(
                  //     "you have already sent an invite to this email, try resending instead"
                  //   );
                  //   setTimeout(() => {
                  //     setValidationMessage(null);
                  //   }, 5000);
                  //   resetForm();
                  //   return;
                  // }
                  // service call to update user
                  await service
                    .inviteUserToTeam({
                      ...values,
                      userId: userEmail ? userId : userDB._id,
                      teamId: teamDetails._id,
                      teamName: teamDetails.name,
                    })
                    .then((response) => {
                      // update invites sent here from the response
                      setTeamInvitesSent(response.data.teamInvitesSent);
                    });
                  setSent(true);
                  setTimeout(() => {
                    setSent(false);
                  }, 2000);
                  resetForm();
                }}
              >
                {({ errors, touched }) => (
                  <div className={"invite-user-form-container"}>
                    <Form>
                      <div className={"invite-user-form-input-field"}>
                        <div className={"invite-user-field-header-container"}>
                          <h2>Email</h2>
                          {errors.email && touched.email && (
                            <p className={"invite-user-form-error-message"}>
                              * {errors.email}
                            </p>
                          )}
                        </div>
                        <Field
                          name="email"
                          type="text"
                          placeholder="jane@doe.com"
                          className={"invite-form-input-field"}
                        />
                        {sent && <h2>message sent</h2>}
                      </div>
                      <button type="submit" className={"invite-submit-button"}>
                        Send
                      </button>
                    </Form>
                  </div>
                )}
              </Formik>
            </div>

            <h2>Sent Invites For {teamDetails.name} </h2>
            <div className={"sent-invites-main-container"}>
              <div className={"invites-sent-card-colum-header"}>
                <p>Email</p>

                <p>Status</p>

                <p>Action</p>
              </div>
              <div className={"team-card-colum-header-line"}></div>
              {teamInvitesSent &&
                teamInvitesSent.map((invite, i) => {
                  const checkMember = teamDetails.athletes.filter(
                    (s) => s.email === invite.email
                  );
                  return (
                    <>
                      <div className={"invites-sent-card-container"} key={i}>
                        <p>{invite.email}</p>

                        <div
                          className={
                            "invites-sent-card-invite-status-container"
                          }
                        >
                          {checkMember && checkMember.length > 0 ? (
                            <div
                              className={
                                "team-details-staff-card-action-icon-submitted"
                              }
                              title="Accepted team invite."
                            >
                              <p>Accepted</p>
                            </div>
                          ) : (
                            <>
                              <div
                                className={
                                  "team-details-staff-card-pending-container"
                                }
                              >
                                <div
                                  className={
                                    "team-details-staffs-card-action-icon-pending"
                                  }
                                  title="Pending acceptance of invite."
                                >
                                  <p>Pending</p>
                                </div>
                                {!confirmReminderSent && (
                                  <div className={"my-teams-card-action-icon"}>
                                    <img
                                      src={SendAgainIcon}
                                      alt="send again"
                                      onClick={() => handleResendInvite(invite)}
                                      title="Send invite again."
                                    />
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        </div>

                        <div
                          className={"invites-sent-card-action-icons-container"}
                        >
                          <div
                            className={"my-teams-card-action-icon"}
                            onClick={() => handleRemoveInvite(invite)}
                          >
                            <img
                              src={DeleteMemberIcon}
                              alt="remove invite"
                              title="Delete from invites sent."
                            />
                          </div>
                        </div>
                      </div>
                      <div className={"team-card-colum-header-line"}></div>
                    </>
                  );
                })}
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
}
