import React, { useContext, useEffect, useState } from "react";
import { Mongo } from "../../auth/mongo-provider";
import { useHistory } from "react-router-dom";
import "./StaffHome.css";
import service from "../../utils/service";
import Loading from "../loading/Loading";
import SideMenu from "../sideMenu/SideMenu";
import { MediaSize } from "../../styles/MediaSizeProvider";
import PlusIcon from "../../images/plus-sign.png";
import MyTeamsCard from "../myTeamsCard/MyTeamsCard";
import Pagination from "../pagination/Pagination";
import { useLocation } from "react-router-dom";
import Search from "../searchAthletesAdmin/SearchAthletesAdmin";

export default function StaffHome() {
  const { Desktop } = useContext(MediaSize);
  const { userDB } = useContext(Mongo);
  const [myTeams, setMyTeams] = useState();
  const [subscriptionStatus, setSubscriptionStatus] = useState();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [visibleTeams, setVisibleTeams] = useState();
  const stripeStatus = location && location.state && location.state.status;
  let history = useHistory();

  useEffect(() => {
    userDB &&
      service
        .getUserFromDB(userDB.email)
        .then((foundUser) => {
          if (foundUser.data.userDB.myTeams) {
            setMyTeams(foundUser.data.userDB.myTeams);
            setVisibleTeams(foundUser.data.userDB.myTeams);
          }
        })
        .then(() => {
          // get subscription status
          if (userDB && userDB.stripeSubscriptionId) {
            service
              .getSubscriptionInfo({ subId: userDB.stripeSubscriptionId })
              .then((response) => {
                setSubscriptionStatus(response.data.status);
                setLoading(false);
              });
          } else {
            setLoading(false);
          }
        });

    // eslint-disable-next-line
  }, [userDB, setMyTeams, subscriptionStatus]);

  const filterResults = (searchTerm) => {
    const copy = [...myTeams];
    const filteredResults = copy.filter((team) => {
      return Object.keys(team).some((key) => {
        return team[key]
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
    });
    setVisibleTeams([...filteredResults]);
  };

  const handleSeePricing = () => {
    history.push(`/pricing`);
  };

  return (
    <>
      <div className={"team-home-main-container"}>
        {!loading ? (
          <>
            <Desktop>
              <div className={"home-side-menu-container"}>
                <SideMenu />
              </div>
            </Desktop>
            {subscriptionStatus === "trialing" || // from DB
            stripeStatus === "trialing" || // from new checkout
            subscriptionStatus === "active" ? (
              <>
                {/* {myTeams && myTeams.length > 0 ? ( */}
                <>
                  <div className={"team-main-content-container-teams"}>
                    <div className={"team-main-content-header-container-teams"}>
                      <h2>My Teams</h2>

                      <div
                        className={"home-main-content-header-navigation-button"}
                        onClick={() => {
                          history.push("/create-team");
                        }}
                      >
                        <img src={PlusIcon} alt="create-team" />
                        <p>New Team</p>
                      </div>
                    </div>
                    <div className={"serch-box-container"}>
                      <Search executeSearch={filterResults} />
                    </div>
                    <div className={"team-main-reports-container"}>
                      <div className={"team-card-colum-header-teams"}>
                        <p>Team</p>

                        {/* <Desktop>
                          <p>Activity</p>
                          <p>City</p>
                        </Desktop> */}
                        <p>Members</p>
                        <p>Staff</p>
                        <p>Action</p>
                      </div>
                      <div className={"team-card-colum-header-line"}></div>

                      {visibleTeams &&
                        visibleTeams.map((team, i) => {
                          return (
                            <>
                              <MyTeamsCard team={team} i={i} />
                              <div
                                className={"team-card-colum-header-line"}
                              ></div>
                            </>
                          );
                        })}
                    </div>
                    <Pagination />
                  </div>
                </>
              </>
            ) : (
              <>
                <div className={"staff-home-no-subscription-container"}>
                  <div className={"staff-home-no-subscription-content"}>
                    <h2>Whoops, You dont have a subscription!</h2>
                    <p>Sign up to get started</p>
                    <button
                      className={"refresh-button"}
                      onClick={handleSeePricing}
                    >
                      See Pricing
                    </button>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
}
