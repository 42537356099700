import React, { useContext, useState } from "react";
import "./NewTeam.css";
import { MediaSize } from "../../styles/MediaSizeProvider";
import Loading from "../loading/Loading";
import SideMenu from "../sideMenu/SideMenu";
import CreateTeamForm from "../createTeamForm/CreateTeamForm";

export default function EditTeam({ match }) {
  const { Desktop } = useContext(MediaSize);
  const [loading] = useState(null);

  return (
    <>
      <div className={"edit-team-main-container"}>
        {!loading ? (
          <>
            <Desktop>
              <div className={"home-side-menu-container"}>
                <SideMenu />
              </div>
            </Desktop>
            <div className={"edit-team-main-content-container"}>
              <h2>Create Team Profile</h2>

              <CreateTeamForm />
              <div className={"team-main-reports-container"}></div>
            </div>
          </>
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
}
