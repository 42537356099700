import React from "react";
import "./AcceptTeamInvite.css";
import service from "../../utils/service";
import "yup-phone";


export default function AcceptTeamInvite({
  setTeamInvitesRecieved,
  teamInvitesRecieved,
  setMyTeams_athlete,
  user
}) {

  const handleAcceptInvite = (invite) => {
    console.log("invite", invite)
    service
      .acceptTeamInvite({
        email: invite.email,
        teamName: invite.name,
        teamId: invite._id,
        userId: user._id,
      })
      .then((response) => {
        setMyTeams_athlete(response.data.myTeams_athlete);
        setTeamInvitesRecieved(response.data.teamInvitesRecieved);
      });
  };

  const handleRejectInvite = (invite) => {
    service
      .rejectTeamInvite({
        email: invite.email,
        userId: user._id,
        teamId: invite._id,
      })
      .then((response) => {
        // update invites sent here from the response
        setTeamInvitesRecieved(response.data.teamInvitesRecieved);
        setMyTeams_athlete(response.data.myTeams_athlete);
      });
  };

  return (
    <div className={"accept-invite-main-container"}>
      {teamInvitesRecieved && teamInvitesRecieved.length > 0 ? (
        <>
          <div className={"accept-invite-widget-container"}>
            <div className={"invite-details-main-card-container"}>
              {teamInvitesRecieved &&
                teamInvitesRecieved.map((invite, i) => {
                  return (
                    <div
                      className={"invite-details-team-card-outer-container"}
                      key={i}
                    >
                      <div
                        className={"invite-details-team-card-container"}
                        key={i}
                      >
                        <div className={"invite-details-team-card-text"}>
                          <h2>Team Member Invite</h2>
                          <h3>Team: {invite.name}</h3>
                          <p>{invite.email}</p>
                          <p>Activity: {invite.sport}</p>
                          <p>City: {invite.city}</p>
                        </div>
                      </div>
                      <div className={"accept-invites-button-container"}>
                        <button
                          className={"invite-accept-button"}
                          onClick={() => handleAcceptInvite(invite)}
                        >
                          Accept
                        </button>
                        <button
                          className={"invite-reject-button"}
                          onClick={() => handleRejectInvite(invite)}
                        >
                          Reject
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      ) : (
        <div className={"accept-invites-no-invites"}>
          <h2>Team invites will show up here!</h2>
        </div>
      )}
    </div>
  );
}
