import React from "react";
import "./ExertionReportCard.css";
import { DateTime } from "luxon";
// import legsIcon from "../../images/wellness-card-fatigue-icon.png";
// import lungsIcon from "../../images/wellness-card-sleep-icon.png";
import cognitiveIcon from "../../images/wellness-card-soreness-icon.png";
import overallIcon from "../../images/wellness-card-stress-icon.png";

export default function ExertionReportCard({ report, i }) {
  // some condition to change the background color
  const handleStyleOnCondition = (value) => {
    if (
      value.overall <= 2 &&
      value.overall > 0 &&
      value.legs <= 2 &&
      value.legs > 0 &&
      value.lungs <= 2 &&
      value.lungs > 0 &&
      value.cognitive <= 2 &&
      value.cognitive > 0
    ) {
      return {
        backgroundColor: "#0cc38a",
      };
    } else if (
      (value.overall > 2 && value.overall) <= 6 &&
      (value.legs > 2 && value.legs) <= 6 &&
      value.lungs > 2 &&
      value.lungs <= 6 &&
      value.cognitive > 2 &&
      value.cognitive <= 6
    ) {
      return {
        backgroundColor: "#fcb757",
      };
    } else {
      return {
        backgroundColor: "#e45741",
      };
    }
  };

  return (
    <div
      className={"individual-wellness-report-card-background-color"}
      style={handleStyleOnCondition(report)}
    >
      <div className={"individual-wellness-report-card"} key={i}>
        <div className={"individual-wellness-report-card-date"}>
          <p>
            {" "}
            {DateTime.fromISO(report.createdAt).toLocaleString({
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            })}{" "}
          </p>
        </div>

        <div className={"individual-wellness-report-card-content-block"}>
          <div className={"individual-wellness-report-card-content"}>
            <img src={overallIcon} alt={"overall"} />
            <p>Overall: {report.overall}</p>
          </div>
          <div className={"individual-wellness-report-card-content"}>
            <img src={cognitiveIcon} alt={"cognitive"} />
            <p>cognitive: {report.cognitive}</p>
          </div>
        </div>

        <div className={"individual-wellness-report-card-content-block"}>
          <div className={"individual-wellness-report-card-content"}>
            <img src={overallIcon} alt={"lungs"} />
            <p>lungs: {report.lungs}</p>
          </div>
          <div className={"individual-wellness-report-card-content"}>
            <img src={overallIcon} alt={"legs"} />
            <p>legs: {report.legs}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
