import React, { useContext, useEffect, useState } from "react";
import { Mongo } from "../../auth/mongo-provider";
import { useHistory } from "react-router-dom";
import "./TeamStaffDetails.css";
import service from "../../utils/service";
import Loading from "../loading/Loading";
import { MediaSize } from "../../styles/MediaSizeProvider";
import SideMenu from "../sideMenu/SideMenu";
import PlusIcon from "../../images/plus-sign.png";
import Pagination from "../pagination/Pagination";
import TeamStaffDetailsCard from "../teamStaffDetailsCard/TeamStaffDetailsCard";

export default function TeamStaffDetails({ match }) {
  const { Desktop } = useContext(MediaSize);
  const { userDB } = useContext(Mongo);
  const [teamDetails, setTeamDetails] = useState();
  const [loading, setLoading] = useState(true);
  const teamId = match.params.teamId;
  let history = useHistory();

  useEffect(() => {
    teamId &&
      userDB &&
      service.getTeamDetails(teamId).then((response) => {
        setTeamDetails(response.data.teamDetails);
        setLoading(false);
      });

    // eslint-disable-next-line
  }, [userDB]);

  return (
    <>
      <div className={"team-details-main-container"}>
        {!loading ? (
          <>
            <Desktop>
              <div className={"home-side-menu-container"}>
                <SideMenu />
              </div>
            </Desktop>
            <div className={"team-details-main-content-container"}>
              <div className={"team-details-main-content-header-container"}>
                <h2>{teamDetails.name} Staff</h2>
                <div
                  className={"home-main-content-header-navigation-button"}
                  onClick={() => {
                    history.push(`/staff-invite/${teamId}`)
                  }}
                >
                  <img src={PlusIcon} alt="create-team" />
                  <p>Invite Staff</p>
                </div>
              </div>

              <div className={"team-details-main-reports-container"}>
                <div className={"team-details-card-colum-header"}>
                  <p>Name</p>

                  <Desktop>
                  <p>Email</p>
                  </Desktop>
                    <p>Report Status</p>
                    {/* <p>Last Report</p> */}
                  {/* <p>Report Status</p> */}

                  <p>Action</p>
                </div>
                <div className={"team-card-colum-header-line"}></div>
                {teamDetails &&
                  teamDetails.staff.map((athlete, i) => {
                    return (
                      <>
                        <TeamStaffDetailsCard
                          athlete={athlete}
                          teamDetails={teamDetails}
                          setTeamDetails={setTeamDetails}
                          i={i}
                        />
                        <div className={"team-card-colum-header-line"}></div>
                      </>
                    );
                  })}
              </div>
              <Pagination />
            </div>
          </>
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
}
