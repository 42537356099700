import React, { useContext, useState } from "react";
import service from "../../utils/service";
import { Mongo } from "../../auth/mongo-provider";
import "./ProfileImageEdit.css";
import { Image, Transformation, CloudinaryContext } from "cloudinary-react";

export default function ProfileImageEdit({ user }) {
  const { setProfileImageId } = useContext(Mongo);
  const [form, setForm] = useState(new FormData());
  const [imagePreview, setImagePreview] = useState(null);
  const [confirmationMsg, setConfirmationMsg] = useState(null);
  const [editPic, setEditPic] = useState(false);
  const [submitVisible, setSubmitVisible] = useState(false);
  const [removeVisible, setRemoveVisible] = useState(false);
  const [newImageId, setNewImageId] = useState();

  const submitHandler = async (e) => {
    e.preventDefault();
    await service
      .updateUserProfileImage(form.image, user._id)
      .then((response) => {
        if (response.data.userDB) {
          setConfirmationMsg("Your profile udated succesfully. ");
          setProfileImageId(response.data.userDB.imageId);
          setNewImageId(response.data.userDB.imageId);
          setTimeout(() => {
            setConfirmationMsg(null);
          }, 3000);
        }
      });

    setEditPic(false);
    setImagePreview(null);
  };

  const changeHandler = (e) => {
    const imageForm = new FormData();
    const imageFile = e.target.files[0];
    setImagePreview(URL.createObjectURL(imageFile));
    imageForm.append("image", imageFile);
    setForm({ image: imageForm });
    setSubmitVisible(true);
    setRemoveVisible(false);
  };

  const handleEditImage = () => {
    setEditPic(true);
    setSubmitVisible(true);
    setRemoveVisible(true);
  };

  const handleDisplayProfileImage = (id) => {
    if (!imagePreview && id === "Edgefy User Profile/f8eeezrkcwfz1d0hbkpk") {
      return (
        <CloudinaryContext
          cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
        >
          <Image publicId={id} type="facebook" format="png">
            <Transformation
              gravity="face"
              width="200"
              height="200"
              radius="max"
              zoom="0.7"
              crop="thumb"
            />
          </Image>
          <Transformation effect="shadow" />
        </CloudinaryContext>
      );
    }
    if (!imagePreview) {
      return (
        <CloudinaryContext
          cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
        >
          <Image publicId={id} className={"img-preview"} format="png">
            <Transformation
              gravity="face"
              width="200"
              height="200"
              radius="max"
              zoom="0.7"
              crop="thumb"
            />
            <Transformation effect="shadow" />
          </Image>
        </CloudinaryContext>
      );
    }
    if (imagePreview && editPic) {
      return (
        <img
          className={"upload-img-preview"}
          src={imagePreview}
          alt={"preview"}
        />
      );
    }
  };

  const handleRemoveProfileImage = async () => {
    await service.removeUserProfileImage(user._id).then((response) => {
      if (response.data.userDB) {
        setConfirmationMsg("Your profile udated succesfully. ");
        setNewImageId(response.data.userDB.imageId);
        setTimeout(() => {
          setConfirmationMsg(null);
        }, 3000);
      }
    });
    setEditPic(false);
    setImagePreview(null);
  };

  return (
    <div className={"profile-upload-container"}>
      {user && (
        <>
          <div className={"img-preview-container"}>
            {handleDisplayProfileImage(
              newImageId ? newImageId : user.imageId
            )}
          </div>
          {confirmationMsg && <h4>{confirmationMsg}</h4>}
          <div className={"image-form-container"}>
            {editPic ? (
              <>
                <div>
                  {removeVisible && user && user.onboardingComplete === true && (
                    <button
                      className={"profile-image-upload-button"}
                      onClick={handleRemoveProfileImage}
                    >
                      Remove
                    </button>
                  )}
                </div>
                <form onSubmit={submitHandler} encType="multipart/form-data">
                  <div className="profile-image-edit-form-block">
                    <input
                      type="file"
                      name="image"
                      id="image-input"
                      onChange={changeHandler}
                    />
                  </div>
                  {submitVisible && (
                    <button
                      type="submit"
                      className={"profile-image-submit-button"}
                    >
                      Upload Image
                    </button>
                  )}
                </form>
              </>
            ) : (
              <button
                className={"profile-image-upload-button"}
                onClick={handleEditImage}
              >
                Change Profile Picture
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
}
