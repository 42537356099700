import React, { useContext, useEffect, useState } from "react";
import "./TieredPricingModels.css";
import { useHistory } from "react-router-dom";
import service from "../../utils/service";
import { Mongo } from "../../auth/mongo-provider";
import Loading from "../loading/Loading";
import HeroSection from "../heroSection/HeroSection";
import PricingCard from "./PricingCard";
import MonthlyImage from "../../images/icon-1.png";
import AnnualImage from "../../images/icon-2.png";
import FaqImage from "../../images/icon-3.png";
import TrailImage from "../../images/wellness-trail-1.png";
import Faqs from "./Faqs";

const monthlyPrices = [
  "Start with a 30-day free trial",
  "$5.99 per member first 1 to 20 members",
  "$5.49 per member next 21 to 50 members",
  "$4.99 per member next 51 to 100 members",
  "$4.49 per member next 101 to 250 members",
  "$3.39 per member next 251 or more members",
];
const annualPrices = [
  "Start with a 30-day free trial",
  "$64.70 per member first 1 to 20 members",
  "$59.30 per member next 21 to 50 members",
  "$53.89 per member next 51 to 100 members",
  "$48.49 per member next 101 to 250 members",
  "$43.09 per member next 251 or more members",
];

const faqArray = [
  {
    question: "Creating an account/ Signing up to Upfield",
    details:
      "We offer sports analytics for teams to monitor and improve their performance. To accomplish this, we survey athletes ",
  },
  {
    question: "Who is this app for?",
    details:
      "We offer sports analytics for teams to monitor and improve their performance. To accomplish this, we survey athletes ",
  },
  {
    question: "How do you ensure data security?",
    details:
      "We offer sports analytics for teams to monitor and improve their performance. To accomplish this, we survey athletes ",
  },
  {
    question: "How do I create a team?",
    details:
      "We offer sports analytics for teams to monitor and improve their performance. To accomplish this, we survey athletes ",
  },
];

export default function TieredPricingModels() {
  const [loading, setLoading] = useState(true);
  let history = useHistory();
  // user info
  const { userDB } = useContext(Mongo);
  const [stripeId] = useState();
  // const [setUserProductId] = useState();
  const [monthlyPriceId, setMonthlyPriceId] = useState();
  const [annualPriceId, setAnnualPriceId] = useState();
  // const [displayMonthly, setDisplayMonthly] = useState(true);
  const [memberCount, setMemberCount] = useState();

  const displayMonthlyCost = (count) => {
    // tier one
    if (count <= 20) {
      const tierOneCost = 3.99;
      return Math.round((count * tierOneCost + Number.EPSILON) * 100) / 100;
    }
    // tier two
    if (count >= 21 && count <= 50) {
      const tierOneTotalCost = 20 * 3.99;
      const tierTwoCount = count - 20;
      const tierTwoCost = tierTwoCount * 2.99;
      return (
        Math.round((tierOneTotalCost + tierTwoCost + Number.EPSILON) * 100) /
        100
      );
    }
    // tier three
    if (count >= 51 && count <= 100) {
      const tierOneTotalCost = 20 * 3.99;
      const tierTwoTotalCost = 29 * 2.99;
      const tierThreeCount = count - 50;
      const tierThreeCost = tierThreeCount * 1.99;
      return (
        Math.round(
          (tierOneTotalCost +
            tierTwoTotalCost +
            tierThreeCost +
            Number.EPSILON) *
            100
        ) / 100
      );
    }
    // tier four
    if (count >= 101 && count <= 250) {
      const tierOneTotalCost = 20 * 3.99;
      const tierTwoTotalCost = 29 * 2.99;
      const tierThreeTotalCost = 49 * 1.99;
      const tierFourCount = count - 100;
      const tierFourCost = tierFourCount * 0.99;
      return (
        Math.round(
          (tierOneTotalCost +
            tierTwoTotalCost +
            tierThreeTotalCost +
            tierFourCost +
            Number.EPSILON) *
            100
        ) / 100
      );
    }
    // tier five
    if (count >= 251) {
      const tierOneTotalCost = 20 * 3.99;
      const tierTwoTotalCost = 29 * 2.99;
      const tierThreeTotalCost = 49 * 1.99;
      const tierFourTotalCost = 149 * 0.99;
      const tierFiveCount = count - 250;
      const tierFiveCost = tierFiveCount * 0.49;
      return (
        Math.round(
          (tierOneTotalCost +
            tierTwoTotalCost +
            tierThreeTotalCost +
            tierFourTotalCost +
            tierFiveCost +
            Number.EPSILON) *
            100
        ) / 100
      );
    }
  };

  const displayAnnualCost = (count) => {
    // tier one
    if (count <= 20) {
      const tierOneCost = 3.99 * 12 * 0.9;
      return Math.round((count * tierOneCost + Number.EPSILON) * 100) / 100;
    }
    // tier two
    if (count >= 21 && count <= 50) {
      const tierOneTotalCost = 20 * 3.99 * 12 * 0.9;
      const tierTwoCount = count - 20;
      const tierTwoCost = tierTwoCount * 2.99 * 12 * 0.9;
      return (
        Math.round((tierOneTotalCost + tierTwoCost + Number.EPSILON) * 100) /
        100
      );
    }
    // tier three
    if (count >= 51 && count <= 100) {
      const tierOneTotalCost = 20 * 3.99 * 12 * 0.9;
      const tierTwoTotalCost = 29 * 2.99 * 12 * 0.9;
      const tierThreeCount = count - 50;
      const tierThreeCost = tierThreeCount * 1.99 * 12 * 0.9;
      return (
        Math.round(
          (tierOneTotalCost +
            tierTwoTotalCost +
            tierThreeCost +
            Number.EPSILON) *
            100
        ) / 100
      );
    }
    // tier four
    if (count >= 101 && count <= 250) {
      const tierOneTotalCost = 20 * 3.99 * 12 * 0.9;
      const tierTwoTotalCost = 29 * 2.99 * 12 * 0.9;
      const tierThreeTotalCost = 49 * 1.99 * 12 * 0.9;
      const tierFourCount = count - 100;
      const tierFourCost = tierFourCount * 0.99 * 12 * 0.9;
      return (
        Math.round(
          (tierOneTotalCost +
            tierTwoTotalCost +
            tierThreeTotalCost +
            tierFourCost +
            Number.EPSILON) *
            100
        ) / 100
      );
    }
    // tier five
    if (count >= 251) {
      const tierOneTotalCost = 20 * 3.99 * 12 * 0.9;
      const tierTwoTotalCost = 29 * 2.99 * 12 * 0.9;
      const tierThreeTotalCost = 49 * 1.99 * 12 * 0.9;
      const tierFourTotalCost = 149 * 0.99 * 12 * 0.9;
      const tierFiveCount = count - 250;
      const tierFiveCost = tierFiveCount * 0.49 * 12 * 0.9;
      return (
        Math.round(
          (tierOneTotalCost +
            tierTwoTotalCost +
            tierThreeTotalCost +
            tierFourTotalCost +
            tierFiveCost +
            Number.EPSILON) *
            100
        ) / 100
      );
    }
  };

  useEffect(() => {
    // userDB &&
    //   service.getUserFromDB(userDB.email).then((foundUser) => {
    //     setStripeId(foundUser.data.userDB.stripeCustomerId);
    //     setUserProductId(foundUser.data.userDB.stripeProductId);
    //   });

    // get pricing information from server
    service.getPrices().then((response) => {
      // price id
      setMonthlyPriceId(response.data.priceId_month);
      setAnnualPriceId(response.data.priceId_annual);
      // product id
      // setMonthlyProductId(response.data.productId_month);
      // setAnnualProductId(response.data.productId_annual);
      setLoading(false);
    });

    setMemberCount(1);
    // eslint-disable-next-line
  }, []);

  // const submitHandler = (e) => {
  //   e.preventDefault();
  // };

  // const changeHandler = (e) => {
  //   const name = e.target.name;
  //   const value = e.target.value;
  //   setMemberCount(value);
  // };

  const handleSelectMonthlyPlan = async () => {
    await service
      .createIntentToBuy({
        priceId: monthlyPriceId && monthlyPriceId,
      })
      .then((response) => {
        history.push({
          pathname: "/checkout",
          state: {
            priceId: monthlyPriceId && monthlyPriceId,
            client_secret: response.data.client_secret,
            stripeId: stripeId,
            term: "month",
            total_cost: monthlyCostValue,
            member_count: memberCount,
            update: true,
          },
        });
      });
  };

  const handleSelectAnnualPlan = async () => {
    await service
      .createIntentToBuy({
        priceId: annualPriceId && annualPriceId,
      })
      .then((response) => {
        history.push({
          pathname: "/checkout",
          state: {
            priceId: annualPriceId && annualPriceId,
            client_secret: response.data.client_secret,
            stripeId: stripeId,
            term: "annual",
            total_cost: annualCostValue,
            member_count: memberCount,
            update: true,
          },
        });
      });
  };

  // const handleAddMemberCount = () => {
  //   let newVal = memberCount + 1;
  //   setMemberCount(newVal);
  // };

  // const handleSubtractMemberCount = () => {
  //   if (memberCount >= 2) {
  //     setMemberCount(memberCount - 1);
  //   }
  // };

  let monthlyCostValue = displayMonthlyCost(memberCount);
  let annualCostValue = displayAnnualCost(memberCount);

  return (
    <div className={"pricing-main-container"}>
      <HeroSection prevPage={"Home"} currentPage={"Pricing"} />
      <div className={"pricing-title-section"}>
        <div className={"pricing-small-font"}>offers</div>
        <div className={"pricing-title-font"}>What we offer</div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className={"pricing-cards"}>
            <PricingCard
              monthly
              image={MonthlyImage}
              title={"Monthly Starting At"}
              price={`$5.99 / member / mo`}
              caption={"Billed Monthly"}
              prices={monthlyPrices}
              handleSelectPlan={handleSelectMonthlyPlan}
              userDB={userDB}
            />
            <PricingCard
              annually
              image={AnnualImage}
              title={"Annual Starting At"}
              price={`$64 / member / year`}
              caption={"Billed Annually (10% Discount)"}
              prices={annualPrices}
              handleSelectPlan={handleSelectAnnualPlan}
              userDB={userDB}
            />
          </div>
          <img className={"pricing-trail"} src={TrailImage} alt={"pricing"} />
          <div className={"pricing-faq-main-container"}>
            <div className={"pricing-faq-container"}>
              <div className={"pricing-faq-chip"}>
                <div className={"pricing-faq-chip-text"}>Asked Questions</div>
              </div>
              <div className={"pricing-faq-title"}>
                Frequently Asked Questions
              </div>
              <div className={"pricing-faq-questions-container"}>
                <Faqs faqArray={faqArray} />
              </div>
            </div>
            <img
              className={"pricing-faq-image"}
              src={FaqImage}
              alt={"pricing"}
            />
          </div>

          {/* //////////////////////////////////////////////////////////////////////////////// OLD CODE BELOW */}
          {/* <div className={"tiered-pricing-models-content-container"}>
            <div className={"tiered-pricing-models-content-block"}>
              <div className={"subscription-switch-container"}>
                {displayMonthly ? (
                  <h2>Monthly</h2>
                ) : (
                  <h2>Annual (10% Discount)</h2>
                )}
                <SubscriptionTermSwitch
                  setDisplayMonthly={setDisplayMonthly}
                  displayMonthly={displayMonthly}
                />
              </div>
            </div>
          </div>

          <div className={"tiered-pricing-models-subscription-container"}>
            <div className={"tiered-pricing-models-subscription-block"}>
              {displayMonthly ? (
                <>
                  <h1>${monthlyCostValue} / mo</h1>
                </>
              ) : (
                <>
                  <h1>${annualCostValue} / year</h1>

                </>
              )}
              {displayMonthly ? (
                <p>Billed Monthly</p>
              ) : (
                <>
                  <p>Billed Annually (10% Discount)</p>
                </>
              )}
              <div className={"tiered-pricing-model-price-info-container"}>
                <div className={"tiered-pricing-model-price-info-block"}>
                  {displayMonthly ? (
                    <>
                      <p>start with a 30-day free trial</p>
                      <p>$3.99 per member first 1 to 20 members</p>
                      <p>$2.99 per member next 21 to 50 members</p>
                      <p>$1.99 per member next 51 to 100 members</p>
                      <p>$0.99 per member next 101 to 250 members</p>
                      <p>$0.49 per member next 251 or more members</p>
                    </>
                  ) : (
                    <>
                      <p>start with a 30-day free trial</p>
                      <p>$43.09 per member first 1 to 20 members</p>
                      <p>$32.29 per member next 21 to 50 members</p>
                      <p>$24.78 per member next 51 to 100 members</p>
                      <p>$10.69 per member next 101 to 250 members</p>
                      <p>$5.29 per member next 251 or more members</p>
                    </>
                  )}
                </div>
              </div>
              <div className={"member-count-slider-container"}>
                <MemberCountSlider
                  setMemberCount={setMemberCount}
                  memberCount={memberCount}
                />
              </div>
              <div className={"handle-member-count-container"}>
                <div
                  className={"add-subtract-member-icon-container"}
                  onClick={handleSubtractMemberCount}
                >
                  <RemoveIcon />
                </div>
                <div className={"member-count-input"}>
                  <h2>{memberCount && memberCount}</h2>
                </div>

                <div
                  className={"add-subtract-member-icon-container"}
                  onClick={handleAddMemberCount}
                >
                  <AddIcon />
                </div>
              </div>
              <div className={"tiered-pricing-get-started-container"}>
                {displayMonthly ? (
                  <>
                    {userProductId ? (
                      <button
                        onClick={() => {
                          history.push(`/account/${userDB._id}`);
                        }}
                      >
                        Manage Plan
                      </button>
                    ) : (
                      <>
                        {userDB ? (
                          <button
                            className={"get-started-button"}
                            onClick={handleSelectMonthlyPlan}
                          >
                            Start Monthly Plan
                          </button>
                        ) : (
                          <SignUpButton />
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {userDB ? (
                      <button
                        className={"get-started-button"}
                        onClick={handleSelectAnnualPlan}
                      >
                        Start Annual Plan
                      </button>
                    ) : (
                      <SignUpButton />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className={"tiered-pricing-models-content-container"}>
            <div className={"tiered-pricing-models-content-block"}>
              <h2>Need a quote? No problem!</h2>
              <div className={"tiered-pricing-models-content-container"}>
                <div className={"tiered-pricing-models-content-block"}>
                  <div className={"subscription-switch-container"}>
                    {displayMonthly ? (
                      <h2>Monthly</h2>
                    ) : (
                      <h2>Annual (10% Discount)</h2>
                    )}
                    <SubscriptionTermSwitch
                      setDisplayMonthly={setDisplayMonthly}
                      displayMonthly={displayMonthly}
                    />
                  </div>
                </div>
              </div>
              <Quote
                displayMonthly={displayMonthly}
                cost={displayMonthly ? monthlyCostValue : annualCostValue}
                memberCount={memberCount}
                setMemberCount={setMemberCount}
              />
            </div>
          </div> */}
          {/* //////////////////////////////////////////////////////////////////////////////// OLD CODE ABOVE */}
        </>
      )}
    </div>
  );
}
