import React, { useContext, useEffect, useState } from "react";
import service from "../../utils/service";
import "./UserAccount.css";
import { Mongo } from "../../auth/mongo-provider";
import Loading from "../loading/Loading";
import { useHistory } from "react-router-dom";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import SideMenu from "../sideMenu/SideMenu";
import { MediaSize } from "../../styles/MediaSizeProvider";
import LastInvoiceIcon from "../../images/account-details-last-invoice.png";
import DownloadInvoiceIcon from "../../images/account-details-download.png";
import TotalMembersIcon from "../../images/account-details-total-members.png";
import TotalTeamsIcon from "../../images/account-details-total-teams.png";

export default function UserAccount() {
  const { userDB } = useContext(Mongo);
  const [productDescription, setProductDescription] = useState();
  const [card, setCard] = useState();
  const [currentPrice, setCurrentPrice] = useState();
  const [latestInvoice, setLatestInvoice] = useState();
  const [status, setStatus] = useState();
  const [subId, setSubId] = useState();
  const [stripeId, setStripeId] = useState();
  const [updateBillingMsg, setUpdateBillingMsg] = useState();
  const [updateBillingVisible, setUpdateBillingVisible] = useState(true);
  const [cancelAtPeriodEnd, setCancelAtPeriodEnd] = useState();
  const [loading, setLoading] = useState(true);
  const { Desktop } = useContext(MediaSize);
  let history = useHistory();

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (userDB && userDB.stripeSubscriptionId) {
      service
        .getSubscriptionInfo({ subId: userDB.stripeSubscriptionId })
        .then((foundUser) => {
          console.log("found user", foundUser.data.cancelAtPeriodEnd);
          setCard(foundUser.data.card);
          setProductDescription(foundUser.data.product_description);
          setCurrentPrice(foundUser.data.current_price);
          setLatestInvoice(foundUser.data.latest_invoice);
          setStatus(foundUser.data.status);
          setSubId(foundUser.data.subId);
          setStripeId(foundUser.data.stripeId);
          setCancelAtPeriodEnd(foundUser.data.cancelAtPeriodEnd);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [userDB]);

  const secondsToISOString = (seconds) => {
    const date = new Date(seconds * 1000);
    return date.toLocaleString({
      month: "long",
      day: "numeric",
    });
  };

  const handleCancelSubscription = () => {
    service
      .cancelSubscription({
        subId: userDB.stripeSubscriptionId,
      })
      .then((response) => {
        console.log("response", response.data);
        setStatus("cancelled");
      });
  };

  const handleGoToWellnessPage = () => {
    history.push(`/wellness`);
  };

  const handleSubmitNewPaymentMethod = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        email: userDB && userDB.email,
      },
    });

    if (error) {
      console.log("[error]", error);
    } else {
      console.log("[PaymentMethod]", paymentMethod);
    }

    // create subscription and redirect
    await service
      .updateSubscriptionPaymentMethod({
        subId: subId && subId,
        stripeId: stripeId,
        paymentMethodId: paymentMethod.id,
      })
      .then((result) => {
        if (result.error) {
          // The card had an error when trying to update
          setUpdateBillingMsg(
            "Oops, something went wrong. Try refreshing the page."
          );
          setUpdateBillingVisible(false);
          throw result;
        }
        setUpdateBillingMsg("successful update");
        setUpdateBillingVisible(false);

        return result;
      });
  };


  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Proxima Nova"',
        fontSmoothing: "antialiased",
        fontSize: "18px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <div className={"user-account-main-container"}>
      {!loading ? (
        <>
          <Desktop>
            <div className={"user-account-side-menu-container"}>
              <SideMenu />
            </div>
          </Desktop>

          <div className={"user-account-main-content-container"}>
            <h2>Account Information</h2>

            <div className={"user-account-settings-content-block"}>
              {userDB && userDB.stripeSubscriptionId ? (
                <>
                  <div className={"user-account-details-block"}>
                    <div className={"user-account-details-block-content"}>
                      <h3>Status: </h3>
                      <p>{status}</p>
                    </div>
                    <div className={"user-account-details-block-content"}>
                      <h3>Description: </h3>
                      <p>{productDescription}</p>
                    </div>
                    <div className={"user-account-details-block-content"}>
                      <h3>Pay Amount: </h3>
                      <p>{currentPrice}</p>
                    </div>
                    <div className={"user-account-details-block-content"}>
                      <h3>Card: </h3>
                      <p>
                        exp: {card && card.exp_month}/{card && card.exp_year}{" "}
                        ending with: {card && card.last4}
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <div className={"user-account-no-subscription-container"}>
                  <div className={"user-account-no-subscription-content"}>
                    <h2>You don't have a subscription yet.</h2>
                    <button
                      className={"user-account-details-block-card-button"}
                      onClick={handleGoToWellnessPage}
                    >
                      Learn More
                    </button>
                  </div>
                </div>
              )}
            </div>

            {userDB && userDB.stripeSubscriptionId && !cancelAtPeriodEnd && (
              <>
                <h2>Change Payment Method</h2>
                <div className={"user-account-details-block-card"}>
                  <form
                    className="user-account-details-block-card-form"
                    onSubmit={handleSubmitNewPaymentMethod}
                  >
                    {updateBillingVisible === true ? (
                      <>
                        <h3>Card</h3>
                        <CardElement id="card-element"
               options={CARD_ELEMENT_OPTIONS} 
               />
                        <button
                          className={"user-account-details-block-card-button"}
                        >
                          Update Payment Method
                        </button>
                      </>
                    ) : (
                      <>{updateBillingMsg}</>
                    )}
                  </form>
                </div>
              </>
            )}

            {(status === "active" || status === "trialing") &&
              !cancelAtPeriodEnd && (
                <>
                  <h3>Cancel Subscription</h3>
                  <div className={"user-account-details-block-cancel"}>
                    <br />
                    <button onClick={handleCancelSubscription}>
                      Cancel Subscription
                    </button>
                  </div>
                </>
              )}
          </div>

          <div className={"account-side-menu-container"}>
            <div className={"account-side-menu-container-block"}>
              <img src={LastInvoiceIcon} alt={"last invoice"} />
              <div className={"account-side-menu-container-content"}>
                <h3>Last Invoice Date</h3>
                <p>
                  {latestInvoice && secondsToISOString(latestInvoice.created)}
                </p>
              </div>
            </div>
            <div className={"account-side-menu-container-block"}>
              <img src={TotalMembersIcon} alt={"total members"} />
              <div className={"account-side-menu-container-content"}>
                <h3>Total Members</h3>
                <p>{userDB && userDB.myAthletes.length}</p>
              </div>
            </div>
            <div className={"account-side-menu-container-block"}>
              <img src={TotalMembersIcon} alt={"total members"} />
              <div className={"account-side-menu-container-content"}>
                <h3>Total Staff</h3>
                <p>{userDB && userDB.myStaff.length}</p>
              </div>
            </div>
            <div className={"account-side-menu-container-block"}>
              <img src={TotalTeamsIcon} alt={"total teams"} />
              <div className={"account-side-menu-container-content"}>
                <h3>Total Teams</h3>
                <p>{userDB && userDB.myTeams.length}</p>
              </div>
            </div>
            <div className={"account-side-menu-container-block"}>
              <img src={DownloadInvoiceIcon} alt={"download invoice"} />
              <div className={"account-side-menu-container-content"}>
                <h3>Latest Invoice</h3>
                <a
                  href={latestInvoice && latestInvoice.hosted_invoice_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  (Downloadable PDF)
                </a>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
}
