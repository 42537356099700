import React, { useContext } from "react";
import "./Pagination.css";
import RightArrow from "../../images/right-arrow.png";
import LeftArrow from "../../images/left-arrow.png";
import { MediaSize } from "../../styles/MediaSizeProvider";

export default function Pagination() {
  const { Desktop } = useContext(MediaSize);
  return (
    <div className={"pagination-container"}>
      <Desktop>
        <p>Showing 1-6 from 100 data</p>
      </Desktop>
      <div className={"pagination-navigation-container"}>
        {/* make this a map function based on the number of observations */}
        <img src={LeftArrow} alt="left arrow" />

        {/* condition on the position to change the background to green */}
        <div className={"pagination-navigation-container-active"}>
          <p>1</p>
        </div>
        <p>2</p>
        <img src={RightArrow} alt="right arrow" />
      </div>
    </div>
  );
}
