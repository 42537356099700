import React, { useContext } from "react";
import "./MyTeamsCard.css";
import { Image, Transformation, CloudinaryContext } from "cloudinary-react";
import { useHistory } from "react-router-dom";
import AddMemberIcon from "../../images/teams-add-member-icon.png";
import AddStaffIcon from "../../images/add-member.png";
import EditTeamIcon from "../../images/teams-edit-team.png";
import ChartIcon from "../../images/chart-1.png";
// import DeleteTeamIcon from "../../images/teams-delete-team.png";
import { MediaSize } from "../../styles/MediaSizeProvider";

export default function MyTeamsCard({ team, i }) {
  let history = useHistory();
  const { Desktop } = useContext(MediaSize);

  const handleClickTeamAdmin = (teamId) => {
    console.log("go to team chart", teamId);
    history.push({
      pathname: `/team-wellness/${teamId}`,
      state: {
        teamId: teamId,
        update: true,
      },
    });
  };

  const handleManageTeam = (teamId) => {
    history.push(`/manage/${teamId}`);
  };

  const handleManageStaff = (teamId) => {
    history.push(`/manage-staff/${teamId}`);
  };

  const handleEditTeam = (teamId) => {
    history.push(`/edit/${teamId}`);
  };

  const handleInvite = (team) => {
    history.push({
      pathname: `/team-invite/${team._id}`,
      state: {
        email: team.email,
        update: true,
      },
    });
  };
  
  console.log("team email", team.email)
  const handleInviteStaff = (team) => {
    history.push({
      pathname: `/staff-invite/${team._id}`,
      state: {
        email: team.email,
        update: true,
      },
    });
  };

  // get athlete images in array
  const athleteImages = team.athletes.map((athlete) => athlete.imageId);
  const staffImages = team.staff.map((staff) => staff.imageId);

  return (
    <>
      <div className={"my-teams-card-container-members"} key={i}>
        <p
          className={"my-teams-card-team-name"}
          onClick={() => {
            handleClickTeamAdmin(team._id);
          }}
          title="View team wellness."
        >
          {" "}
          {team.name}
        </p>

        {/* <Desktop> */}
        {/* <p
            onClick={() => {
              handleClickTeamAdmin(team._id);
            }}
          >
            {team.sport}
          </p> */}
        {/* <p
            onClick={() => {
              handleClickTeamAdmin(team._id);
            }}
          >
            {team.city}
          </p> */}
        {/* </Desktop> */}

        {/*-------------------------------------------team members */}
        <div
          className={"my-teams-card-team-members"}
          title="View team members."
        >
          <Desktop>
            <CloudinaryContext
              cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
            >
              <Image
                publicId={athleteImages[0]}
                format="png"
                className={"team-member-icon"}
                onClick={() => {
                  handleManageTeam(team._id);
                }}
              >
                <Transformation
                  gravity="face"
                  width="25"
                  height="25"
                  radius="100"
                  zoom="0.7"
                  crop="fill"
                />
              </Image>
              <Image
                publicId={athleteImages[1]}
                format="png"
                className={"team-member-icon"}
                onClick={() => {
                  handleManageTeam(team._id);
                }}
              >
                <Transformation
                  gravity="face"
                  width="25"
                  height="25"
                  radius="100"
                  zoom="0.7"
                  crop="fill"
                />
              </Image>
              <Image
                publicId={athleteImages[2]}
                format="png"
                className={"team-member-icon"}
                onClick={() => {
                  handleManageTeam(team._id);
                }}
              >
                <Transformation
                  gravity="face"
                  width="25"
                  height="25"
                  radius="100"
                  zoom="0.7"
                  crop="fill"
                />
              </Image>
            </CloudinaryContext>
          </Desktop>
          <p
            onClick={() => {
              handleManageTeam(team._id);
            }}
          >
            {team.athletes.length}
          </p>
          <div className={"my-teams-card-action-icon"}>
            <img
              src={AddMemberIcon}
              alt="add member"
              title="Invite team members."
              onClick={() => {
                handleInvite(team);
              }}
            />
          </div>
        </div>
        {/*-------------------------------------------staff members */}

        <div
          className={"my-teams-card-team-members"}
          title="View staff members."
        >
          <Desktop>
            <CloudinaryContext
              cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
            >
              <Image
                publicId={staffImages[0]}
                format="png"
                className={"team-member-icon"}
                onClick={() => {
                  handleManageStaff(team._id);
                }}
              >
                <Transformation
                  gravity="face"
                  width="25"
                  height="25"
                  radius="100"
                  zoom="0.7"
                  crop="fill"
                />
              </Image>
              <Image
                publicId={staffImages[1]}
                format="png"
                className={"team-member-icon"}
                onClick={() => {
                  handleManageStaff(team._id);
                }}
              >
                <Transformation
                  gravity="face"
                  width="25"
                  height="25"
                  radius="100"
                  zoom="0.7"
                  crop="fill"
                />
              </Image>
              <Image
                publicId={staffImages[2]}
                format="png"
                className={"team-member-icon"}
                onClick={() => {
                  handleManageStaff(team._id);
                }}
              >
                <Transformation
                  gravity="face"
                  width="25"
                  height="25"
                  radius="100"
                  zoom="0.7"
                  crop="fill"
                />
              </Image>
            </CloudinaryContext>
          </Desktop>
          <p
            onClick={() => {
              handleManageStaff(team._id);
            }}
          >
            {team.staff.length}
          </p>
          <div
            className={"my-teams-card-action-icon"}
            onClick={() => {
              handleInviteStaff(team);
            }}
          >
            <img
              src={AddStaffIcon}
              alt="add staff"
              title="Invite staff members."
            />
          </div>
        </div>

        <div className={"my-teams-card-action-icons-container"}>
          <img
            src={ChartIcon}
            alt="chart"
            className={"my-teams-chart-icon"}
            onClick={() => {
              handleClickTeamAdmin(team._id);
            }}
            title="View team wellness."
          />
          <div
            className={"my-teams-card-action-icon"}
            onClick={() => {
              handleEditTeam(team._id);
            }}
          >
            <img src={EditTeamIcon} alt="edit team" title="Edit team." />
          </div>

          {/* <div className={"my-teams-card-action-icon"}>
          <img src={DeleteTeamIcon} alt="delete team" />
        </div> */}
        </div>
      </div>
      <div className={"team-card-colum-header-line"}></div>
    </>
  );
}
