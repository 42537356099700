import React, { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import "./Home.css";
import { Mongo } from "../../auth/mongo-provider";
import service from "../../utils/service";

export default function Home() {
  const { userDB } = useContext(Mongo);
  const [userId, setUserId] = useState();

  useEffect(() => {
    userDB &&
      service.getUserFromDB(userDB.email).then((foundUser) => {
        setUserId(foundUser.data.userDB._id);
      });

    // eslint-disable-next-line
  }, [userDB]);

  return (
    <div>
      {userId && (
        <>
          <Redirect
            to={{
              pathname: `/wellness-reports/${userId}`,
              state: {
                userId: userId,
                update: true,
              },
            }}
          />
        </>
      )}
    </div>
  );
}
