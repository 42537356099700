export default function HowItWorksSection({
  textRight,
  image,
  num,
  title,
  description,
}) {
  return (
    <div className={"wellness-hiw-section"}>
      {textRight ? (
        <img className={"wellness-hiw-image"} src={image} alt={"invite"} />
      ) : null}

      <div
        className={
          textRight ? "wellness-text-container-r" : "wellness-text-container-l"
        }
      >
        <div className={"wellness-hiw-num"}>{num}</div>
        <div
          className={
            textRight ? "wellness-hiw-title-r" : "wellness-hiw-title-l"
          }
        >
          {title}
        </div>
        <div className={textRight ? "wellness-hiw-p-r" : "wellness-hiw-p-l"}>
          {description}
        </div>
      </div>
      {!textRight ? (
        <img className={"wellness-hiw-image"} src={image} alt={"invite"} />
      ) : null}
    </div>
  );
}
