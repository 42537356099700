import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import MongoProvider from "./auth/mongo-provider";
import MediaSizeProvider from "./styles/MediaSizeProvider";


ReactDOM.render(
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <MongoProvider>
        <MediaSizeProvider>
          <App />
        </MediaSizeProvider>
      </MongoProvider>
    </Auth0ProviderWithHistory>
  </BrowserRouter>,
  document.getElementById("root")
);
