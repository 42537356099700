import React, { useContext, useEffect, useState } from "react";
import { Mongo } from "../../auth/mongo-provider";
import "./StaffHome.css";
import service from "../../utils/service";
import SideMenu from "../sideMenu/SideMenu";
import { MediaSize } from "../../styles/MediaSizeProvider";
import Pagination from "../pagination/Pagination";
import Search from "../searchAthletesAdmin/SearchAthletesAdmin";
import AllUsersDetailsCard from "../allUsersDetailsCard/AllUsersDetailsCard";

export default function AdminUsersHome() {
  const { Desktop } = useContext(MediaSize);
  const { userDB } = useContext(Mongo);
  const [allAthletes, setAllAthletes] = useState();
  const [visibleTeams, setVisibleTeams] = useState();

  useEffect(() => {
    userDB &&
      userDB.admin &&
      service.getAdminAthletes().then((response) => {
        if (response.data.athletes) {
          setAllAthletes(response.data.athletes);
          setVisibleTeams(response.data.athletes);
        }
      });

    // eslint-disable-next-line
  }, [userDB, setAllAthletes]);

  const filterResults = (searchTerm) => {
    const copy = [...allAthletes];
    const filteredResults = copy.filter((team) => {
      return Object.keys(team).some((key) => {
        return team[key]
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
    });
    setVisibleTeams([...filteredResults]);
  };

  return (
    <>
      <div className={"team-home-main-container"}>
        {/* {!loading ? ( */}
        <>
          <Desktop>
            <div className={"home-side-menu-container"}>
              <SideMenu />
            </div>
          </Desktop>
          {userDB && userDB.admin ? (
            <>
              {/* {allAthletes && allAthletes.length > 0 ? ( */}
              <>
                <div className={"team-main-content-container-teams"}>
                  <div className={"team-main-content-header-container-teams"}>
                    <h2>All Users</h2>

                  </div>
                  <div className={"serch-box-container"}>
                    <Search executeSearch={filterResults} />
                  </div>

                  <div className={"team-main-reports-container"}>
                    {/* <div className={"team-card-colum-header-teams"}>
                      <p>Team</p>


                      <p>Members</p>
                      <p>Staff</p>
                      <p>Action</p>
                    </div> */}
                    <div className={"team-card-colum-header-line"}></div>

                    {visibleTeams &&
                      visibleTeams.map((athlete, i) => {
                        return (
                          <>
                            <AllUsersDetailsCard athlete={athlete}/>
                            <div
                              className={"team-card-colum-header-line"}
                            ></div>
                          </>
                        );
                      })}
                  </div>
                  <Pagination />
                </div>
              </>
            </>
          ) : (
            <>
              <div className={"staff-home-no-subscription-container"}>
                <div className={"staff-home-no-subscription-content"}>
                  <h2>Whoops, You dont have an Admin Account</h2>
                </div>
              </div>
            </>
          )}
        </>
        {/* ) : (
          <Loading />
        )} */}
      </div>
    </>
  );
}
