import React, { useContext, useState } from "react";
import { Mongo } from "../../auth/mongo-provider";
import NewUser from "../newUser/NewUser";
import { Link } from "react-router-dom";
import "./Welcome.css";
import OnboardingProfileImage from "../onboardingProfileImage/OnboardingProfileImage";
import {useLocation} from "react-router-dom";

export default function Welcome() {
  const [onBoardingStep1, setOnBoardingStep1] = useState(true);
  const [onBoardingStep2, setOnBoardingStep2] = useState(false);
  const [onBoardingStep3, setOnBoardingStep3] = useState(false);
  const { setOnBoarded } = useContext(Mongo);

  const location = useLocation();
  const userId = location && location.state && location.state.userId;

  const handleOnBoardingStep1 = () => {
    return (
      <div className={"onboarding-form-container"}>
        <NewUser
          setOnBoardingStep1={setOnBoardingStep1}
          setOnBoardingStep2={setOnBoardingStep2}
        />
      </div>
    );
  };

  const handleOnboardingStep2 = () => {
    return (
      <div>
        <OnboardingProfileImage />
      </div>
    );
  };

  const handleComplete = () => {
    setOnBoardingStep3(true);
    setOnBoarded(true);
    setOnBoarded(true);
  };

  const handleOnBoardingStep3 = () => {
    return (
      <div className={"onboarding-welcome-msg"}>
        <h1>Congratulations! Your Account Is Now Active!</h1>
        <div className={"welcome-msg-content-block"}>
          <p>Make a selection that best describes your interest.</p>
          <h2>
            <Link to={`/wellness-reports/${userId && userId}`}>
              <button
                onClick={handleComplete}
                className={"welcome-onboarding-button"}
              >
                I'm Only Interested In My Wellness
              </button>
            </Link>
            <Link to={"/pricing"}>
              <button
                onClick={handleComplete}
                className={"welcome-onboarding-button"}
              >
                I Want To Improve My Team's Performance
              </button>
            </Link>
          </h2>
        </div>
      </div>
    );
  };

  // for onboarding only
  const handleSkip = () => {
    setOnBoardingStep2(false);
    setOnBoardingStep3(true);
  };
  return (
    <>
      <div>
        {/* onboarding step 1 */}
        {onBoardingStep1 && handleOnBoardingStep1()}

        {/* onboarding step 2 */}
        {onBoardingStep2 && handleOnboardingStep2()}

        {/* onboarding step 3 */}
        {onBoardingStep3 && handleOnBoardingStep3()}
      </div>
      {onBoardingStep2 && (
        <div className={"skip-step"}>
          <p onClick={handleSkip}>next-></p>
        </div>
      )}
    </>
  );
}
