import React, { useState } from "react";
import "./ContactForm.css";
import LearnMoreAboutWellness from "../learnMoreAboutWellness/LearnMoreAboutWellness";

export default function ContactForm() {
  const [learnMoreVisible, setLearnMoreVisible] = useState(true);

  return (
    <div className={"contact-form-container"}>
      <div className={"contact-form-title-container"}>
        <div className={"contact-form-title"}>Contact Us</div>
        <div className={"contact-form-caption"}>
          Upfield Analytics (UA) is the premier performance monitoring service
          for any organization that depends upon human performance to succeed.
        </div>
      </div>
      <div className={"contact-auth-container"}>
        {learnMoreVisible ? (
          <>
            <LearnMoreAboutWellness setLearnMoreVisible={setLearnMoreVisible} />
          </>
        ) : (
          <>
            <div className={"learn-more-sent-message"}>
              <h2>Thanks for reach out! We'll be in touch soon.</h2>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
