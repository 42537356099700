import React, { useState } from "react";
import service from "../../utils/service";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "yup-phone";
import "./NewExertionReport.css";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useLocation } from "react-router-dom";

export default function NewExertionReport({ userId }) {
  const [wellnessVisible, setWellnessVisible] = useState(true);
  const [wellnessMessage] = useState(false);

  const location = useLocation();
  // const userId = location && location.state && location.state.userId;

  const userFirstName =
    location && location.state && location.state.userFirstName;

  const customStyles = {
    menu: () => ({
      backgroundColor: "#ffffff",
      border: "1px solid #dbdbdb91",
      height: "46",
      // color: "#373737af",
      fontFamily: "Proxima Nova",
      fontSize: "18px",
    }),
  };

  const overall = [
    { label: "Choose level of exersion..", value: 0 },
    { label: "1. Very easy", value: 1 },
    { label: "2. ", value: 2 },
    { label: "3. ", value: 3 },
    { label: "4. ", value: 4 },
    { label: "5. ", value: 5 },
    { label: "6. Normal", value: 6 },
    { label: "7. ", value: 7 },
    { label: "8. ", value: 8 },
    { label: "9. ", value: 9 },
    { label: "10. Very hard", value: 10 },
  ];

  const legs = [
    { label: "Choose level of exersion..", value: 0 },
    { label: "1. Very easy", value: 1 },
    { label: "2. ", value: 2 },
    { label: "3. ", value: 3 },
    { label: "4. ", value: 4 },
    { label: "5. ", value: 5 },
    { label: "6. Normal", value: 6 },
    { label: "7. ", value: 7 },
    { label: "8. ", value: 8 },
    { label: "9. ", value: 9 },
    { label: "10. Very hard", value: 10 },
  ];

  const lungs = [
    { label: "Choose level of exersion..", value: 0 },
    { label: "1. Very easy", value: 1 },
    { label: "2. ", value: 2 },
    { label: "3. ", value: 3 },
    { label: "4. ", value: 4 },
    { label: "5. ", value: 5 },
    { label: "6. Normal", value: 6 },
    { label: "7. ", value: 7 },
    { label: "8. ", value: 8 },
    { label: "9. ", value: 9 },
    { label: "10. Very hard", value: 10 },
  ];

  const cognitive = [
    { label: "Choose level of exersion..", value: 0 },
    { label: "1. Very easy", value: 1 },
    { label: "2. ", value: 2 },
    { label: "3. ", value: 3 },
    { label: "4. ", value: 4 },
    { label: "5. ", value: 5 },
    { label: "6. Normal", value: 6 },
    { label: "7. ", value: 7 },
    { label: "8. ", value: 8 },
    { label: "9. ", value: 9 },
    { label: "10. Very hard", value: 10 },
  ];

  return (
    <>
      <div className={"wellness-report-form-main-container"}>
        {wellnessVisible ? (
          <>
            {userFirstName && <h3>{userFirstName}'s Exertion</h3>}
            <Formik
              initialValues={{
                overall: 0,
                legs: 0,
                lungs: 0,
                cognitive: 0,
              }}
              validationSchema={Yup.object().shape({
                legs: Yup.number().min(1, "Required").required("Required"),
                overall: Yup.number().min(1, "Required").required("Required"),
                lungs: Yup.number().min(1, "Required").required("Required"),
                cognitive: Yup.number().min(1, "Required").required("Required"),
              })}
              onSubmit={async (values, { resetForm }) => {
                // service call to update user
                await service
                  .newExertionReport({ ...values, user: userId })
                  .then((response) => {});

                // setWellnessMessage(true);
                resetForm();
                window.scroll({ top: 0, behavior: "smooth" });

                setWellnessVisible(false);
              }}
            >
              {({ errors, touched, setFieldValue }) => (
                <div className={"wellness-report-form-container"}>
                  <Form>
                    <div
                      className={"wellness-report-wellness-form-input-field"}
                    >
                      <div className={"wellness-report-field-header-container"}>
                        <h2>1. Scroll to your feeling of overall exersion.</h2>
                        {errors.legs && touched.legs && (
                          <h3 className={"wellness-report-form-error-message"}>
                            * {errors.legs}
                          </h3>
                        )}
                      </div>
                      <Select
                        as="select"
                        name="legs"
                        className={"wellness-form-input-field"}
                        onChange={(e) => {
                          setFieldValue("legs", parseInt(e.value));
                        }}
                        styles={customStyles}
                        options={overall}
                      />
                    </div>

                    <div
                      className={"wellness-report-wellness-form-input-field"}
                    >
                      <div className={"wellness-report-field-header-container"}>
                        <h2>
                          2. Scroll to your feeling of exersion on your legs.
                        </h2>
                        {errors.overall && touched.overall && (
                          <h3 className={"wellness-report-form-error-message"}>
                            * {errors.overall}
                          </h3>
                        )}
                      </div>
                      <Select
                        as="select"
                        name="overall"
                        className={"wellness-form-input-field"}
                        onChange={(e) => {
                          setFieldValue("overall", parseInt(e.value));
                        }}
                        styles={customStyles}
                        options={legs}
                      />
                    </div>

                    <div
                      className={"wellness-report-wellness-form-input-field"}
                    >
                      <div className={"wellness-report-field-header-container"}>
                        <h2>
                          3. Scroll to your feeling of exersion on your lungs.
                        </h2>
                        {errors.lungs && touched.lungs && (
                          <h3 className={"wellness-report-form-error-message"}>
                            * {errors.lungs}
                          </h3>
                        )}
                      </div>
                      <Select
                        as="select"
                        name="lungs"
                        className={"wellness-form-input-field"}
                        onChange={(e) => {
                          setFieldValue("lungs", parseInt(e.value));
                        }}
                        styles={customStyles}
                        options={lungs}
                      />
                    </div>

                    <div
                      className={"wellness-report-wellness-form-input-field"}
                    >
                      <div className={"wellness-report-field-header-container"}>
                        <h2>
                          4. Scroll to your feeling of cognitive exersion.
                        </h2>
                        {errors.cognitive && touched.cognitive && (
                          <h3 className={"wellness-report-form-error-message"}>
                            * {errors.cognitive}
                          </h3>
                        )}
                      </div>
                      <Select
                        as="select"
                        name="cognitive"
                        className={"wellness-form-input-field"}
                        onChange={(e) => {
                          setFieldValue("cognitive", parseInt(e.value));
                        }}
                        styles={customStyles}
                        options={cognitive}
                      />
                    </div>

                    <button
                      type="submit"
                      className={"update-form-submit-button"}
                    >
                      Submit
                    </button>
                  </Form>
                </div>
              )}
            </Formik>
          </>
        ) : (
          <>
            {wellnessMessage && (
              <>
                <h2>Exertion Report Submitted!</h2>
                <Link
                  to={{
                    pathname: `/exertion-reports/${userId}`,
                    state: {
                      userId: userId,
                      update: true,
                    },
                  }}
                >
                  See Reports
                </Link>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
