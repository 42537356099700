import React from "react";
import "./Footer.css";
import Logo from "../../images/UA-Logo.png";
// import FacebookIcon from "../../images/facebook-icon.png";
// import TwitterIcon from "../../images/twitter-icon.png";
// import LinkedinIcon from "../../images/linkedin-icon.png";

export default function Footer() {
  return (
    <div className={"footer-container"}>
      <div className={"footer-content-container"}>
        <div className={("footer-content-block", "footer-icon-block")}>
          <img src={Logo} alt="Upfield Analytics" />
        </div>

        {/* <div className={"footer-content-block"}>
          <h3>My Account</h3>
          <ul>
            <h5>
              <li>
                <a href="https://admiring-hawking-3c90e6.netlify.app/">New User</a>
              </li>
              <li>
                <a href="https://admiring-hawking-3c90e6.netlify.app/">User Login</a>
              </li>
              <li>
                <a href="https://admiring-hawking-3c90e6.netlify.app/">My Account</a>
              </li>
              <li>
                <a href="https://admiring-hawking-3c90e6.netlify.app/">My Team</a>
              </li>
            </h5>
          </ul>
        </div> */}

        {/* <div className={"footer-content-block"}>
          <h3>About Us</h3>

          <ul>
            <h5>
              <li>
                <a href="https://admiring-hawking-3c90e6.netlify.app/">About us</a>
              </li>
              <li>
                <a href="https://admiring-hawking-3c90e6.netlify.app/">Services</a>
              </li>
              <li>
                <a href="https://admiring-hawking-3c90e6.netlify.app/">Team</a>
              </li>
              <li>
                <a href="https://admiring-hawking-3c90e6.netlify.app/">Plan & Pricing</a>
              </li>
              <li>
                <a href="https://admiring-hawking-3c90e6.netlify.app/">News</a>
              </li>
            </h5>
          </ul>
        </div> */}

        {/* <div className={"footer-content-block"}>
          <h3>Follow Us</h3>
          <div className={"footer-social-icons"}>
            <img src={FacebookIcon} alt="Facebook" />
            <img src={TwitterIcon} alt="Twitter" />
            <img src={LinkedinIcon} alt="LinkedIn" />
          </div>
        </div> */}
      </div>

      <div className={"copywrite-container"}>
        <h5>Copywrite @2021 . Upfield Analytics</h5>
      </div>
    </div>
  );
}
