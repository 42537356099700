import React, { useState } from "react";
import service from "../../utils/service";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "yup-phone";
import "./UpdateUser.css";
// import { Mongo } from "../../auth/mongo-provider";
import Select from "react-select";
import { DateTime } from "luxon";

export default function UpdateUser({ user }) {
  // const { userDB } = useContext(Mongo);
  const [confirmationMsg, setConfirmationMsg] = useState(null);

  const timeZoneOptions = [
    { label: "US Eastern-(EST UTC-5)", value: 5 },
    { label: "US Central-(CST UTC-6)", value: 6 },
    { label: "US Mountain-(MST UTC-7)", value: 7 },
    { label: "US Pacific-(PST UTC-8)", value: 8 },
    { label: "UK Longon-(GMT UTC)", value: 0 },
  ];

  const notificationOptions = [
    { label: "5:00 am", value: 5 },
    { label: "6:00 am", value: 6 },
    { label: "7:00 am", value: 7 },
    { label: "8:00 am", value: 8 },
    { label: "9:00 am", value: 9 },
    { label: "10:00 am", value: 10 },
    { label: "11:00 am", value: 11 },
    { label: "12:00 pm", value: 12 },
    { label: "1:00 pm", value: 13 },
    { label: "2:00 pm", value: 14 },
    { label: "3:00 pm", value: 15 },
    { label: "4:00 pm", value: 16 },
    { label: "5:00 pm", value: 17 },
    { label: "6:00 pm", value: 18 },
    { label: "7:00 pm", value: 19 },
    { label: "8:00 pm", value: 20 },
    { label: "9:00 pm", value: 21 },
    { label: "10:00 pm", value: 22 },
    { label: "11:00 pm", value: 23 },
    { label: "12:00 am", value: 24 },
    { label: "1:00 am", value: 1 },
    { label: "2:00 am", value: 2 },
    { label: "3:00 am", value: 3 },
    { label: "4:00 am", value: 4 },
  ];

  const customStyles = {
    menu: () => ({
      backgroundColor: "#ffffff",
      border: "1px solid #dbdbdb91",
      height: "46",
      // color: "#373737af",
      fontFamily: "Proxima Nova",
      fontSize: "18px",
    }),
  };

  const displayTimeZone = (zone) => {
    switch (zone) {
      case "0":
        return <p>UK Longon-(GMT UTC)</p>;

      case "5":
        return <p>US Eastern-(EST UTC-5)</p>;

      case "6":
        return <p>US Central-(CST UTC-6)</p>;

      case "7":
        return <p>US Mountain-(MST UTC-7)</p>;

      case "8":
        return <p>US Pacific-(PST UTC-8)</p>;

      default:
        break;
    }
  };

  return (
    <>
      <div className={"profile-form-main-container"}>
        {user && (
          <>
            <p>{user.email}</p>
            <Formik
              initialValues={{
                firstName: user.firstName,
                lastName: user.lastName,
                phone: user.phone,
                city: user.city,
                state: user.state,
                notifications_SMS_recieveWellnessReminder:
                  user.notifications_SMS_recieveWellnessReminder,
                timeZone: user.timeZone,
                timeOfNotification: user.timeOfNotification,
              }}
              validationSchema={Yup.object({
                firstName: Yup.string().required("Required"),
                lastName: Yup.string().required("Required"),
                phone: Yup.string().phone("US").required("Required"),
                city: Yup.string().required("Required"),
                state: Yup.string().required("Required"),
                timeZone: Yup.string().required("Required"),
                timeOfNotification: Yup.string().required("Required"),
              })}
              onSubmit={async (values, { resetForm }) => {
                // service call to update user
                await service
                  .updateUserDB(values, user._id)
                  .then((response) => {
                    if (response.data.userDB) {
                      setConfirmationMsg("Your profile updated succesfully");
                      setTimeout(() => {
                        setConfirmationMsg(null);
                      }, 3000);
                    }
                  });
              }}
            >
              {({ errors, touched, setFieldValue }) => (
                <div className={"update-team-form-container"}>
                  <Form>
                    <div className={"update-team-form-input-field"}>
                      <div className={"update-team-field-header-container"}>
                        <h2>First Name</h2>
                        {errors.firstName && touched.firstName && (
                          <h3 className={"update-team-form-error-message"}>
                            * {errors.firstName}
                          </h3>
                        )}
                      </div>
                      <Field
                        name="firstName"
                        type="text"
                        placeholder="First Name"
                        className={"form-input-field"}
                      />
                    </div>
                    <div className={"update-team-form-input-field"}>
                      <div className={"user-update-form-input-container"}>
                        <div className={"update-team-field-header-container"}>
                          <h2>Last Name</h2>
                          {errors.lastName && touched.lastName && (
                            <h3 className={"update-team-form-error-message"}>
                              * {errors.lastName}
                            </h3>
                          )}
                        </div>
                        <Field
                          name="lastName"
                          type="text"
                          placeholder="Last Name"
                          className={"form-input-field"}
                        />
                      </div>
                    </div>

                    <div className={"user-update-form-input-container"}>
                      <div className={"update-team-field-header-container"}>
                        <h2>Phone</h2>
                        {errors.phone && touched.phone && (
                          <h3 className={"update-team-form-error-message"}>
                            * {errors.phone}
                          </h3>
                        )}
                      </div>
                      <Field
                        name="phone"
                        type="text"
                        placeholder="Primary Phone"
                        className={"form-input-field"}
                      />
                    </div>

                    <div className={"user-update-form-input-container"}>
                      <div className={"update-team-field-header-container"}>
                        <h2>City</h2>
                        {errors.city && touched.city && (
                          <h3 className={"update-team-form-error-message"}>
                            * {errors.city}
                          </h3>
                        )}
                      </div>
                      <Field
                        label="City"
                        name="city"
                        type="text"
                        placeholder="City"
                        className={"form-input-field"}
                      />
                    </div>

                    <div className={"user-update-form-input-container"}>
                      <div className={"update-team-field-header-container"}>
                        <h2>State</h2>
                        {errors.state && touched.state && (
                          <h3 className={"update-team-form-error-message"}>
                            * {errors.state}
                          </h3>
                        )}
                      </div>
                      <Field
                        name="state"
                        type="text"
                        placeholder="State"
                        className={"form-input-field"}
                      />
                      <div className={"user-update-form-input-container"}>
                        <div className={"update-team-field-header-container"}>
                          <h2>Recieve SMS Notifications</h2>
                        </div>
                        <Field
                          name="notifications_SMS_recieveWellnessReminder"
                          type="checkbox"
                          className={"form-input-field"}
                        />
                      </div>

                      <div className={"user-update-form-input-container"}>
                        <div className={"update-team-field-header-container"}>
                          <h2>Select Time Zone</h2>
                          {errors.timeZone && touched.timeZone && (
                            <h3
                              className={"wellness-report-form-error-message"}
                            >
                              * {errors.timeZone}
                            </h3>
                          )}
                        </div>
                        <p>{displayTimeZone(user.timeZone)}</p>
                        <Select
                          as="select"
                          name="timeZone"
                          className={"wellness-form-input-field"}
                          onChange={(e) => {
                            setFieldValue("timeZone", parseInt(e.value));
                          }}
                          styles={customStyles}
                          options={timeZoneOptions}
                        />
                      </div>

                      <div className={"user-update-form-input-container"}>
                        <div className={"update-team-field-header-container"}>
                          <h2>Select Notification Time </h2>
                          {errors.timeOfNotification &&
                            touched.timeOfNotification && (
                              <h3
                                className={"wellness-report-form-error-message"}
                              >
                                * {errors.timeOfNotification}
                              </h3>
                            )}
                        </div>
                        <p>
                          {user.timeOfNotification &&
                            DateTime.fromObject({
                              hour: user.timeOfNotification,
                            }).toLocaleString({
                              hour: "numeric",
                              minute: "numeric",
                            })}
                        </p>
                        <Select
                          as="select"
                          name="timeOfNotification"
                          className={"wellness-form-input-field"}
                          onChange={(e) => {
                            setFieldValue(
                              "timeOfNotification",
                              parseInt(e.value)
                            );
                          }}
                          styles={customStyles}
                          options={notificationOptions}
                        />
                      </div>

                      {!confirmationMsg && <h3>{confirmationMsg}</h3>}
                    </div>

                    <button
                      type="submit"
                      className={"update-form-submit-button"}
                    >
                      Update
                    </button>
                    <div className={"upload-complete-msg"}>
                      {confirmationMsg && <h4>{confirmationMsg}</h4>}
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          </>
        )}
      </div>
    </>
  );
}
