import React from "react";
import service from "../../utils/service";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "yup-phone";
import "./LearnMoreAboutWellness.css";

export default function LearnMoreAboutWellness({ setLearnMoreVisible }) {
  return (
    <>
      <div className={"learn-more-learn-more-form-main-container"}>
        <>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
              notes: "",
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("email is required")
                .required("Required"),
            })}
            onSubmit={async (values, { resetForm }) => {
              // service call
              await service.learnMoreAboutWellness(values).then(() => {
                setLearnMoreVisible(false);
              });
              resetForm();
            }}
          >
            {({ errors, touched }) => (
              <div className={"learn-more-form-container"}>
                <Form>
                  <div className={"learn-more-form-text-input-container-main"}>
                    <div className={"learn-more-form-text-input-container"}>
                      <label htmlFor="firstName">First Name</label>
                      <Field
                        name="firstName"
                        type="text"
                        placeholder="First Name"
                        className={"learn-more-input-field"}
                      />
                    </div>
                    <div className={"learn-more-form-text-input-container"}>
                      <label htmlFor="lastName">Last Name</label>
                      <Field
                        name="lastName"
                        type="text"
                        placeholder="Last Name"
                        className={"learn-more-input-field"}
                      />
                    </div>
                    <div className={"learn-more-form-text-input-container"}>
                      <label htmlFor="lastName">Email</label>
                      <Field
                        name="email"
                        type="text"
                        placeholder="jane@doe.com"
                        className={"learn-more-input-field"}
                      />
                    </div>
                    {errors.email && touched.email && (
                      <p className={"learn-more-form-error-message"}>
                        {errors.email}
                      </p>
                    )}
                    <div className={"learn-more-form-text-input-container"}>
                      <label htmlFor="phone">Phone</label>
                      <Field
                        name="phone"
                        type="text"
                        placeholder="Primary Phone"
                        className={"learn-more-input-field"}
                      />
                    </div>
                    <div className={"learn-more-form-text-input-container"}>
                      <label htmlFor="notes">Note</label>
                      <Field
                        name="notes"
                        type="text"
                        placeholder="Note"
                        className={"learn-more-input-field"}
                        id="learn-more-note-block"
                      />
                    </div>
                  </div>
                  <button type="submit" className={"learn-more-submit-button"}>
                    Submit
                  </button>
                </Form>
              </div>
            )}
          </Formik>
        </>
      </div>
    </>
  );
}
