import React from "react";
import "./Landing.css";
import Image1 from "../../images/img-1.png";
import Image2 from "../../images/img-2.png";
import Image3 from "../../images/img-3.png";
import QuoteIcon from "../../images/icon-4.png";
import QuoteImage from "../../images/landing-quote-image.png";
// import HeroPersonImage from "../../images/home-hero-guy.png";
import NursePersonImage from "../../images/slide-4.png";
import ChartImage from "../../images/home-charts-img.png";
import ContactsImage from "../../images/home-contacts-img.png";
import WellnessImage from "../../images/home-wellness-img.png";

import ContactForm from "../contactForm/ContactForm";
import InfoCard from "../infoCard/InfoCard";
import TeamWellnessSection from "./TeamWellnessSection";

import { useAuth0 } from "@auth0/auth0-react";

export default function Landing() {
  const { loginWithRedirect } = useAuth0();
  return (
    <div className={"wellness-main-container"}>
      {/* ------------------------------------------------------ Hero */}
      <div className={"landing-hero-container"}>
        <div className={"landing-hero-text-container"}>
          <div className={"landing-hero-title"}>
            Track wellness for teams or individuals. Sign up and get started!
          </div>
          <div className={"landing-hero-caption"}>
            Do or your team depend on human performance? Now any organization
            can track team wellness with Upfield Analytics!
          </div>
          <div className={"landing-hero-button-container"}>
            <button
              className={"landing-button"}
              onClick={() =>
                loginWithRedirect({
                  screen_hint: "signup",
                })
              }
            >
              Get Started
            </button>
            <button className={"landing-button"}>Learn More</button>
          </div>
        </div>
        <div className={"landing-hero-img-container"}>
          <img
            className={"landing-hero-img"}
            src={NursePersonImage}
            alt={"landing hero"}
          />
        </div>
      </div>

      {/* ------------------------------------------------------ What we do */}
      <div className={"landing-wwd-container"}>
        <div className={"landing-title-section"}>
          <div className={"landing-small-font"}>what we do</div>
          <div className={"landing-title-font"}>
            Improve human performance
          </div>
        </div>
        <div className={"landing-card-container"}>
          <InfoCard
            image={Image1}
            title={"Generate Reports"}
            description={`Provides insights to individual and team wellness with simple and easy to ready reports.`}
          />
          <InfoCard
            image={Image2}
            title={"Improve Performance"}
            description={`Human performance is directly related to individual and team wellness.  Bring your team to the next level with Upfield Analytics.`}
          />
          <InfoCard
            image={Image3}
            title={"Reduce Incident Rates"}
            description={`Studies show that health is directly tied to human performance.  Reduce your team's incident rates by monitoring thier wellness.`}
          />
        </div>
      </div>
      {/* ------------------------------------------------------ Team Wellness */}
      <div className={"landing-hiw-container"}>
        <div className={"landing-title-section"}>
          <div className={"landing-title-font"}>Team Wellness</div>
        </div>
        <TeamWellnessSection
          image={WellnessImage}
          smallText={"GET STARTED IN MINUTES"}
          title={"Improve your team  performance"}
          caption={
            "We offer analytics to monitor and improve performance."
          }
          description={`Build unlimited teams of any size and track wellness to improve performance.  Invite staff members to join your teams.`}
        />
        <TeamWellnessSection
          textRight
          image={ContactsImage}
          smallText={"MEMBER WELLNESS CARDS"}
          title={"Get the insights directly from the source"}
          caption={"Team members spend less than a minute each day to report."}
          description={`It has never been easier to monitor wellness and gain insights provided from our reports. Members recieve daiy reminders and reports to stay on track.`}
        />
        <TeamWellnessSection
          button
          buttonText={"Get Started"}
          buttonFunction={() =>
            loginWithRedirect({
              screen_hint: "signup",
            })
          }
          image={ChartImage}
          smallText={"CHARTS AND REPORTS"}
          title={"We make it easy to visualize wellness."}
          caption={"Deep insights to human performance and individual wellness."}
          description={"We pride ourselves on making it easy to report and monitor wellness to improve performance."}
        />
      </div>
      {/* ------------------------------------------------------ Quote */}
      
      <div className={"landing-quote-container"}>
        <img
          className={"landing-quote-image"}
          src={QuoteImage}
          alt={"landing quote"}
        />
        <div className={"landing-quote-text-container"}>
          <img
            className={"landing-quote-icon"}
            src={QuoteIcon}
            alt={"landing quote"}
          />
          <div className={"landing-quote-text"}>
            Thank you very much. This web-app will be used on a daily basis by
            our team leaders.
          </div>
          <div className={"landing-quote-name"}>Mike Lucas</div>
          <div className={"landing-quote-job"}>Team Coach</div>
        </div>
      </div>
      {/* ------------------------------------------------------ Contact Us */}
      <ContactForm />
    </div>
  );
}
