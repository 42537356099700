import React, { useContext, useState } from "react";
import service from "../../utils/service";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "yup-phone";
import "./CreateTeamForm.css";
import { Mongo } from "../../auth/mongo-provider";
import { useHistory } from "react-router";

export default function CreateTeamForm() {
  const [confirmationMsg, setConfirmationMsg] = useState(null);
  const { userDB } = useContext(Mongo);
  let history = useHistory();

  return (
    <>
      <div className={"update-team-form-main-container"}>
        {userDB && (
          <Formik
            initialValues={{
              stat_sports: false,
              beyond_pulse: false,
              playertech: false,
              titan: false,
              hrv_sensor: false,
              force_frame: false,
              nordboard: false,
              sparta_science: false,
              notifications_Email_recieveDailyReport: true,
            }}
            validationSchema={Yup.object({
              name: Yup.string().required("Required"),
              city: Yup.string().required("Required"),
              state: Yup.string().required("Required"),
              sport: Yup.string().required("Required"),
            })}
            onSubmit={async (values, { resetForm }) => {
              await service.createTeam({
                ...values,
                user: userDB._id,
                email: userDB.email,
              });
              resetForm();
              setConfirmationMsg("Your team profile saved succesfully");
              history.push("/teams");
            }}
          >
            {({ errors, touched }) => (
              <div className={"update-team-form-container"}>
                <Form>
                  <div className={"update-team-form-input-field"}>
                    <div className={"update-team-field-header-container"}>
                      <h2>Team Name</h2>
                      {errors.name && touched.name && (
                        <h3 className={"update-team-form-error-message"}>
                          * {errors.name}
                        </h3>
                      )}
                    </div>
                    <Field
                      name="name"
                      type="text"
                      placeholder="Team Name"
                      className={"form-input-field"}
                    />
                  </div>
                  <div className={"update-team-form-input-field"}>
                    <div className={"update-team-field-header-container"}>
                      <h2>City</h2>
                      {errors.city && touched.city && (
                        <h3 className={"update-team-form-error-message"}>
                          * {errors.city}
                        </h3>
                      )}
                    </div>
                    <Field
                      label="City"
                      name="city"
                      type="text"
                      placeholder="City"
                      className={"form-input-field"}
                    />
                  </div>

                  <div className={"update-team-form-input-field"}>
                    <div className={"update-team-field-header-container"}>
                      <h2>State</h2>
                      {errors.state && touched.state && (
                        <h3 className={"update-team-form-error-message"}>
                          * {errors.state}
                        </h3>
                      )}
                    </div>
                    <Field
                      name="state"
                      type="text"
                      placeholder="State"
                      className={"form-input-field"}
                    />
                  </div>

                  <div className={"update-team-form-input-field"}>
                    <div className={"update-team-field-header-container"}>
                      <h2>Team Activity</h2>
                      {errors.sport && (
                        <h3 className={"update-team-form-error-message"}>
                          * {errors.sport}
                        </h3>
                      )}
                    </div>
                    <Field
                      name="sport"
                      type="text"
                      placeholder="Team Activity"
                      className={"form-input-field"}
                    />
                  </div>

                  <div className={"update-team-form-input-field"}>
                    <div className={"update-team-field-header-container"}>
                      <p>Get Daily Reports</p>
                    </div>
                    <Field
                      name="notifications_Email_recieveDailyReport"
                      type="checkbox"
                      className={"form-input-field"}
                    />
                  {!confirmationMsg && <h3>{confirmationMsg}</h3>}
                  </div>


                  <button type="submit" className={"update-form-submit-button"}>
                    Create Team
                  </button>
                </Form>
              </div>
            )}
          </Formik>
        )}
      </div>
    </>
  );
}
