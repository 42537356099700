import React from "react";
import "./WellnessLanding.css";
import SummaryImage from "../../images/wellness-summary-img.png";
import InviteImage from "../../images/wellness-invite-img.png";
import DailyImage from "../../images/wellness-daily-img.png";
import ChartImage from "../../images/wellness-chart-img.png";
import Person1Img from "../../images/testimonials-1.png";
import Person2Img from "../../images/testimonials-2.png";
import Person3Img from "../../images/testimonials-3.png";
// import { useHistory } from "react-router-dom";
import HowItWorksSection from "./HowItWorksSection";
import TestimonialCard from "./TestimonialCard";
import GetStartedSection from "../getStartedSection/GetStartedSection";
import HeroSection from "../heroSection/HeroSection";
import ContactForm from "../contactForm/ContactForm";

// TODO: make responsive

export default function WellnessLanding() {
  // const [learnMoreVisible, setLearnMoreVisible] = useState(true);
  // let history = useHistory();
  return (
    <div className={"wellness-main-container"}>
      <HeroSection prevPage={"Home"} currentPage={"Wellness"} />
      <div className={"wellness-summary-container"}>
        <div className={"wellness-sum-inner-container"}>
          <div className={"wellness-sum-text-container"}>
            <div className={"wellness-sum-welcome-text"}>
              welcome to upfield analytics
            </div>
            <div className={"wellness-sum-title"}>
              Wellness monitoring for teams!
            </div>
            <div className={"wellness-sum-caption"}>
              Automated results tracking.
            </div>
            <div className={"wellness-sum-p"}>
              Save time with automatic wellness reminders and automated reports.
            </div>
          </div>
          <img
            className={"wellness-summary-image"}
            src={SummaryImage}
            alt={"summary"}
          />
        </div>
      </div>
      <div className={"wellness-hiw-container"}>
        <div className={"wellness-title-section"}>
          <div className={"wellness-small-font"}>steps</div>
          <div className={"wellness-title-font"}>How it works</div>
        </div>
        <HowItWorksSection
          textRight
          image={InviteImage}
          num={"1"}
          title={"Invite members"}
          description={`Create the teams you want to track and invite unlimited members and staff.`}
        />
        <HowItWorksSection
          image={DailyImage}
          num={"2"}
          title={"Get daily wellness reports"}
          description={`Access your wellness reports at any time through the app and automaticlly recieve reports sent straight to your inbox.`}
        />
        <HowItWorksSection
          textRight
          image={ChartImage}
          num={"3"}
          title={"Gain team wellness perspective.."}
          description={`Team members provide you with the information you need straight from the source. We make it easy to visualize and understand team wellness with our unique reporting. `}
        />
      </div>
      <div className={"wellness-testimonials-container"}>
        <div className={"wellness-title-section"}>
          <div className={"wellness-small-font"}>what they say</div>
          <div className={"wellness-title-font"}>Testimonials</div>
        </div>
        <div className={"wellness-card-container"}>
          <TestimonialCard
            pic={Person1Img}
            name={"Mike Lucas"}
            title={"Team Coach"}
            message={
              "Thank you very much. This web-app will be used on a daily basis by athletes and team leaders."
            }
          />
          <TestimonialCard
            pic={Person2Img}
            name={"Patrick Livingston"}
            title={"Team Coach"}
            message={
              "We can't understand how we've been living without Upfield. Upfield has got everything I need."
            }
          />
          <TestimonialCard
            pic={Person3Img}
            name={"Mike Lucas"}
            title={"Team Coach"}
            message={
              "I would be lost without Upfield. Upfield is worth much more than I paid. Upfield is awesome!"
            }
          />
        </div>
      </div>
      <GetStartedSection />
      <ContactForm />
    </div>
  );
}
