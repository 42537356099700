import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./UserWellnessChartContainer.css";
import DateRangeSliderUserWellness from "../sliderUserWellness/DateRangeSliderUserWellness";
import { MediaSize } from "../../styles/MediaSizeProvider";
import SideMenu from "../sideMenu/SideMenu";
import PlusIcon from "../../images/plus-sign.png";
import WellnessChart from "../../components/wellnessChart/WellnessChart";
// import { Mongo } from "../../auth/mongo-provider";
import service from "../../utils/service";
import { DateTime } from "luxon";
import Loading from "../loading/Loading";
import { useLocation } from "react-router-dom";
import WellnessChartHRV from "../wellnessChart/WellnessChartHRV";

export default function UserWellnessChartContainer() {
  const [targetChartRange, setTargetChartRange] = useState(5);
  const [userFirstName, setUserFirstName] = useState();
  const { Desktop } = useContext(MediaSize);
  let history = useHistory();

  // const { userDB } = useContext(Mongo);  // we want to pass userDB instead of getting from context
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState();

  const location = useLocation();
  const userId = location && location.state && location.state.userId;

  window.scroll({ top: 0, behavior: "smooth" });

  useEffect(() => {
    userId &&
      service.getUserFromDBWithId(userId).then((foundUser) => {
        console.log("found user", foundUser.data);

        setChartData(foundUser.data.userDB.preSessionWellness);
        setUserFirstName(foundUser.data.userDB.firstName);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [userId]);

  // modify date format
  const newArray =
    chartData &&
    chartData.map((obj) => ({
      ...obj,
      date: DateTime.fromISO(obj.createdAt).toLocaleString({
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      }),
    }));

  // get data in arrays for chart
  const date = newArray && newArray.map((obs) => obs.date).reverse();
  const sleep = newArray && newArray.map((obs) => obs.sleep).reverse();
  const stress = newArray && newArray.map((obs) => obs.stress).reverse();
  const fatigue = newArray && newArray.map((obs) => obs.fatigue).reverse();
  const soreness = newArray && newArray.map((obs) => obs.soreness).reverse();
  const hrv = newArray && newArray.map((obs) => obs.hrv).reverse();
  const rhr = newArray && newArray.map((obs) => obs.rhr).reverse();

  return (
    <div className={"wellness-chart-main-container"}>
      {!loading ? (
        <>
          <Desktop>
            <div className={"wellness-chart-side-menu-container"}>
              <SideMenu />
            </div>
          </Desktop>
          <div className={"wellness-chart-main-content-container"}>
            <div className={"wellness-chart-main-content-header"}>
              <h2>{userFirstName && userFirstName}'s Wellness Chart</h2>

              <div
                className={"home-main-content-header-navigation-button"}
                onClick={() => {
                  history.push({
                    pathname: `/new/${userId}`,
                    state: {
                      userId: userId,
                      userFirstName: userFirstName,
                      update: true,
                    },
                  });
                }}
              >
                <img src={PlusIcon} alt="new" />
                <p>New Report</p>
              </div>
            </div>
            <div className={"wellness-report-slider"}>
              <p>Select Range</p>
              <DateRangeSliderUserWellness
                setTargetChartRange={setTargetChartRange}
              />
            </div>

            <div className={"wellness-chart-container"}>
              <div className={"wellness-chart-block"}>
                <h2>Sleep</h2>
                <WellnessChart
                  date={date && date.slice(0, targetChartRange).reverse()}
                  variable={sleep && sleep.slice(0, targetChartRange).reverse()}
                  label={"hours of sleep"}
                />
              </div>
              <div className={"wellness-chart-block"}>
                <h2>Stress</h2>
                <WellnessChart
                  date={date && date.slice(0, targetChartRange).reverse()}
                  variable={
                    stress && stress.slice(0, targetChartRange).reverse()
                  }
                  label={"percieved value of stress"}
                />
              </div>

              <div className={"wellness-chart-block"}>
                <h2>Fatigue</h2>
                <WellnessChart
                  date={date && date.slice(0, targetChartRange).reverse()}
                  variable={
                    fatigue && fatigue.slice(0, targetChartRange).reverse()
                  }
                  label={"percieved value of fatigue"}
                />
              </div>

              <div className={"wellness-chart-block"}>
                <h2>Soreness</h2>
                <WellnessChart
                  date={date && date.slice(0, targetChartRange).reverse()}
                  variable={
                    soreness && soreness.slice(0, targetChartRange).reverse()
                  }
                  label={"percieved value of soreness"}
                />
              </div>
              <div className={"wellness-chart-block"}>
                <h2>HRV</h2>
                <WellnessChartHRV
                  date={date && date.slice(0, targetChartRange).reverse()}
                  variable={hrv && hrv.slice(0, targetChartRange).reverse()}
                  label={"heart rate variability"}
                />
              </div>
              <div className={"wellness-chart-block"}>
                <h2>RHR</h2>
                <WellnessChartHRV
                  date={date && date.slice(0, targetChartRange).reverse()}
                  variable={rhr && rhr.slice(0, targetChartRange).reverse()}
                  label={"resting heart rate"}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
}
