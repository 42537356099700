import React, { useContext, useState } from "react";
import { DateTime, Interval } from "luxon";
import "./TeamStaffDetailsCard.css";
import { Image, Transformation, CloudinaryContext } from "cloudinary-react";
import service from "../../utils/service";
// import AddMemberIcon from "../../images/teams-add-member-icon.png";
import SendAgainIcon from "../../images/send-again-icon.png";
import DeleteMemberIcon from "../../images/teams-delete-team.png";
import { MediaSize } from "../../styles/MediaSizeProvider";
import ChartIcon from "../../images/responsive-chart-icon.png";
import ReportsIcon from "../../images/responsive-report-icon.png";
import { useHistory } from "react-router-dom";

export default function TeamStaffDetailsCard({
  athlete,
  teamDetails,
  setTeamDetails,
  i,
}) {
  let history = useHistory();
  const { Desktop } = useContext(MediaSize);
  const [confirmReminderSent, setConfirmReminderSent] = useState(false);
  const [now] = useState(
    DateTime.now().set({ hour: 0, minutes: 0, seconds: 0, milliseconds: 0 })
  );

  const handleClickMemberChart = (userId) => {
    history.push({
      pathname: `/wellness-chart/${userId}`,
      state: {
        userId: userId,
        update: true,
      },
    });
  };

  const handleClickMemberReports = (userId) => {
    history.push({
      pathname: `/wellness-reports/${userId}`,
      state: {
        userId: userId,
        update: true,
      },
    });
  };

  const handleRemoveFromTeam = (athlete) => {
    teamDetails &&
      service
        .removeStaffFromTeam({
          userId: athlete._id,
          teamId: teamDetails._id,
        })
        .then((response) => {
          setTeamDetails(response.data.teamDetails);
        });
  };

  const handleSendReminder = (athlete) => {
    teamDetails &&
      service
        .sendWellnessReminder({
          id: athlete._id,
          email: athlete.email,
          teamName: teamDetails.name,
        })
        .then((response) => {
          if (response.data.message) {
            setConfirmReminderSent(response.data.message);
          }
        });
  };

  const lastReport =
    athlete.preSessionWellness.length > 0
      ? athlete.preSessionWellness.slice(-1)[0].createdAt
      : athlete.preSessionWellness.createdAt;

  const daysSinceReport =
    athlete.preSessionWellness.length > 0
      ? Math.round(
          Interval.fromDateTimes(
            DateTime.fromISO(lastReport).set({
              hour: 0,
              minutes: 0,
              seconds: 0,
              milliseconds: 0,
            }),
            now
          ).length("hours")
        )
      : 25;

  return (
    <>
      <div className={"team-details-staff-card-container"} key={i}>
        <div className={"team-details-staff-card-profile-container"}>
          <CloudinaryContext
            cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
          >
            <Image publicId={athlete.imageId} format="png">
              <Transformation
                gravity="face"
                width="48"
                height="48"
                radius="100"
                zoom="0.7"
                crop="fill"
              />
            </Image>
          </CloudinaryContext>
          <p>{athlete.firstName}</p>
        </div>
        <Desktop>
          <p>{athlete.email}</p>
          {/* <p>
            {DateTime.fromISO(lastReport).toLocaleString({
              month: "short",
              day: "numeric",
            })}
          </p> */}
        </Desktop>

        <div className={"team-details-staff-card-invite-status-container"}>
          {daysSinceReport && daysSinceReport > 12 ? (
            <>
              <div className={"team-details-staff-card-pending-container"}>
                <div
                  className={"team-details-staffs-card-action-icon-pending"}
                  title="Wellness report not sumbitted."
                >
                  <p>Pending</p>
                </div>
                {!confirmReminderSent && (
                  <div
                    className={"my-teams-card-action-icon"}
                    onClick={() => {
                      handleSendReminder(athlete);
                    }}
                  >
                    <img
                      src={SendAgainIcon}
                      alt="send again"
                      title="Send email reminder."
                    />
                  </div>
                )}
              </div>
            </>
          ) : (
            <div
              className={"team-details-staff-card-action-icon-submitted"}
              title="Wellness report sumbitted."
            >
              <p>Submitted</p>
            </div>
          )}
        </div>

        <div className={"team-details-staff-card-action-icons-container"}>
          <div className={"my-teams-card-action-icon"}>
            <img
              src={ChartIcon}
              alt="chart"
              className={"view-member-chart-icon"}
              onClick={() => {
                handleClickMemberChart(athlete._id);
              }}
              title="View wellness chart."
            />
          </div>
          <div className={"my-teams-card-action-icon"}>
            <img
              src={ReportsIcon}
              alt="chart"
              className={"view-member-chart-icon"}
              onClick={() => {
                handleClickMemberReports(athlete._id);
              }}
              title="View wellness reports."
            />
          </div>
          <div
            className={"my-teams-card-action-icon"}
            id="delete-button"
            onClick={() => {
              handleRemoveFromTeam(athlete);
            }}
          >
            <img
              src={DeleteMemberIcon}
              alt="edit team"
              title="Delete member from team."
            />
          </div>
        </div>
      </div>
      <div className={"team-card-colum-header-line"}></div>
    </>
  );
}
