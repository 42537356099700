import React, { useContext, useEffect, useState } from "react";
import { Mongo } from "../../auth/mongo-provider";
import service from "../../utils/service";
import { useHistory } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import "./UserExertionReports.css";
// import Loading from "../loading/Loading";
import { MediaSize } from "../../styles/MediaSizeProvider";
import SideMenu from "../sideMenu/SideMenu";
import PlusIcon from "../../images/plus-sign.png";
import ExertionReportCard from "../exertionReportCard/ExertionReportCard";

export default function UserExertionReports({match}) {
  const { userDB, onBoarded } = useContext(Mongo);
  const [exertionReports, setExertionReports] = useState();
  const [userFirstName, setUserFirstName] = useState();
  // const [loading, setLoading] = useState(true);
  let history = useHistory();
  const { Desktop } = useContext(MediaSize);
  window.scroll({ top: 0, behavior: "smooth" });
  // const location = useLocation();
  // const userId = location && location.state && location.state.userId;
  const userId = match && match.params.userId

  const handleCheckOnboarding = () => {
    if (!onBoarded && userDB && userDB.onboardingComplete === false) {
      history.push({
        pathname: "/welcome",
        state: {
          userId: userId,
          update: true,
        },
      });
    }
  };

  useEffect(() => {
    userDB &&
      service.getUserFromDBWithId(userId).then((foundUser) => {
        if (foundUser.data.userDB.exertion.length > 0) {
          setExertionReports(
            foundUser.data.userDB.exertion.reverse()
          );
          setUserFirstName(foundUser.data.userDB.firstName);
          // setLoading(false);
        } else {
          // setLoading(false);
        }
      });

    // eslint-disable-next-line
  }, [onBoarded, userDB, userId]);

  return (
    <>
      {handleCheckOnboarding()}
      <div className={"home-main-container"}>
        {/* {!loading ? ( */}
        <>
          <Desktop>
            <div className={"home-side-menu-container"}>
              <SideMenu />
            </div>
          </Desktop>
          <div className={"home-main-content-container"}>
            <>
              <div className={"home-main-content-header-container"}>
                {userFirstName && userFirstName ? (
                  <h2>{userFirstName}'s Exertion Reports</h2>
                ) : (
                  <h2>Exertion Reports</h2>
                )}

                <div
                  className={"home-main-content-header-navigation-button"}
                  onClick={() => {
                    history.push({
                      pathname: `/new-exertion/${userId}`,
                      state: {
                        userId: userId,
                        userFirstName: userFirstName,
                        update: true,
                      },
                    });
                  }}
                >
                  <img src={PlusIcon} alt="new" />
                  <p>New Report</p>
                </div>
              </div>

              <div className={"home-main-reports-container"}>
                {exertionReports &&
                  exertionReports.map((report, i) => {
                    return <ExertionReportCard report={report} i={i} />;
                  })}
              </div>
            </>
          </div>
        </>
        {/* // ) : (
        //   <Loading />
        // )} */}
      </div>
    </>
  );
}
